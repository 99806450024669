import React from 'react'
import { Col, Card, ListGroup, ListGroupItem } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Icon from '@mdi/react'
import { mdiChevronRight } from '@mdi/js'

import { searchUrl } from '../utils'

import './DisciplineCard.scss'

export default function DisciplineCard(props) {
  const chevron = <Icon path={mdiChevronRight} size={1} />
  return (
    <Col className="mb-3">
      <Card className="discipline-card">
        <div className="discipline-card-header">
          <Card.Img variant="top" src={props.image} loading="lazy" />
          <Card.Title as="a" href={searchUrl(props.name)}>
            {props.name} {chevron}
          </Card.Title>
        </div>
        <Card.Body className="p-0">
          <ListGroup className="list-group-flush">
            {props.areas.map((area, index) => (
              <ListGroupItem key={index} as={Link} to={searchUrl(area)}>
                {area} {chevron}
              </ListGroupItem>
            ))}
          </ListGroup>
        </Card.Body>
        <Card.Footer as="a" href={searchUrl(props.name)}>
          See all&hellip; {chevron}
        </Card.Footer>
      </Card>
    </Col>
  )
}
