import React from 'react'
import PropTypes from 'prop-types'
import { Row, Col, Card } from 'react-bootstrap'
import classnames from 'classnames'

import ProfileMini from './ProfileMini'

import './PersonCards.scss'

export default function PersonCards(props) {
  if (!props.people.length) return

  const { people, className, showType, xs, sm, md, ...rest } = props

  return (
    <Row
      xs={xs}
      sm={sm}
      md={md}
      className={classnames('person-cards', className)}
      {...rest}
    >
      {people.map(person => (
        <Col key={person.link_id}>
          <Card>
            <Card.Body>
              <ProfileMini person={person} stretchedLink showType={showType} />
            </Card.Body>
          </Card>
        </Col>
      ))}
    </Row>
  )
}

PersonCards.defaultProps = {
  people: [],
  showType: true,
  xs: 1,
  sm: 2,
  lg: 3,
}

PersonCards.propTypes = {
  people: PropTypes.array,
  showType: PropTypes.bool,
}
