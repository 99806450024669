import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { Row, Col, Alert } from 'react-bootstrap'
import { Helmet } from 'react-helmet'
import {
  mdiBook,
  mdiBookOpen,
  mdiBookOpenVariant,
  mdiBookOpenPageVariant,
  mdiCalendar,
  mdiCalendarRange,
  mdiCity,
  mdiCreation,
  mdiFile,
  mdiFileCertificate,
  mdiLibrary,
  mdiMusic,
  mdiTextBoxOutline,
} from '@mdi/js'
import classnames from 'classnames'

import ConditionalWrapper from './ConditionalWrapper'
import EraAbbr from './EraAbbr'
import EraEligibleIcon from './EraEligibleIcon'
import IconLabel from './IconLabel'
import DataExplorer from './DataExplorer'
import OpenAccessIcon from './OpenAccessIcon'

import { useRegexSortType } from '../hooks'
import { associateUrl } from '../utils'
import config from '../config'

import './ProfileOutputs.scss'
import AltmetricLogo from '../img/icons/altmetric-colour.svg'

function toAltmetricDoi(doi) {
  // TODO remove trim() when moving to production database
  return doi.trim().toLowerCase()
}

function Author(props) {
  return (
    <>
      {props.author.person ? (
        <a
          className={classnames({
            'author-self':
              props.currentPerson.link_id === props.author.person.link_id,
          })}
          href={associateUrl(props.author.person)}
        >
          {props.author.name}
        </a>
      ) : (
        props.author.name
      )}
    </>
  )
}

function OutputClassifier({ className, children, ...props }) {
  return (
    <Col sm="auto" className={classnames('output-classifier', className)}>
      <IconLabel {...props}>{children}</IconLabel>
    </Col>
  )
}

OutputClassifier.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any.isRequired,
}

function OutputListItem({ person, showAltmetric, row: { original: output } }) {
  const [altmetricElement, setAltmetricElement] = useState(null)
  const altmetricRef = useCallback(node => {
    if (node !== null) {
      setAltmetricElement(node)
    }
  }, [])
  const altmetricEnabled = showAltmetric && output.doi

  let typeIcon
  switch (output.type) {
    case 'Book':
      typeIcon = mdiBookOpen
      break
    case 'Book Chapter':
      typeIcon = mdiBookOpenPageVariant
      break
    case 'Journal Article':
      typeIcon = mdiBook
      break
    case 'Conference Item':
      typeIcon = mdiCalendar
      break
    case 'Patent':
      typeIcon = mdiFileCertificate
      break
    case 'Other':
      typeIcon = mdiCreation
      break
    case 'Dataset':
      typeIcon = mdiFile
      break
    default:
      typeIcon = mdiBook
  }

  useEffect(() => {
    if (!altmetricEnabled || !altmetricElement || !window._altmetric_embed_init)
      return

    window._altmetric_embed_init(altmetricElement)

    return function cleanup() {
      // Cleanup leftover Altmetrics DOI script tag
      const altmetricScript = document.querySelector(
        `script[src*="api.altmetric.com"][src*="${toAltmetricDoi(
          output.doi
        )}?" i]`
      )
      if (altmetricScript) {
        altmetricScript.parentNode.removeChild(altmetricScript)
      }

      // Cleanup leftover Altmetrics popover
      // Altmetric uses an internal citation_id to link to a given
      // publication on its systems, so we use that to associate a given
      // embedded widget to its popover div
      // XXX Requires polyfill for `closest`
      const altmetricLink = altmetricElement.querySelector(
        '[href*="citation_id="]'
      )
      if (altmetricLink) {
        const citation_id = altmetricLink.href.match(/citation_id=.*/)[0]
        const altmetricPopoverLink = document.querySelector(
          `.altmetric-popover [href*="${citation_id}"]`
        )
        if (altmetricPopoverLink) {
          const altmetricPopover = altmetricPopoverLink.closest(
            '.altmetric-popover'
          )
          altmetricPopover.parentNode.removeChild(altmetricPopover)
        }
      }
    }
  }, [altmetricEnabled, output.doi, altmetricElement])

  return (
    <Row className="output">
      <Col>
        <div className="h4 mb-1">
          {output.url ? <a href={output.url}>{output.title}</a> : output.title}
        </div>
        <div className="mb-1 mb-sm-2 mb-lg-3">
          {output.authors.map((author, index) => (
            <React.Fragment key={index}>
              <Author author={author} currentPerson={person} />
              {index <= output.authors.length - 3 && ', '}
              {index === output.authors.length - 2 && ' and '}
            </React.Fragment>
          ))}
        </div>
        <Row className="output-classifiers">
          {(output.year || output.in_press) && (
            <OutputClassifier icon={config.icons.time} className="output-year">
              {output.year ? (
                <>
                  {output.year}
                  {output.in_press && ' (In Press)'}
                </>
              ) : (
                output.in_press && 'In Press'
              )}
            </OutputClassifier>
          )}
          <OutputClassifier icon={typeIcon} className="output-type">
            {output.type_label}
          </OutputClassifier>
          {(output.journal || output.book_title) && (
            <OutputClassifier icon={mdiBookOpenVariant}>
              {output.journal || output.book_title}
            </OutputClassifier>
          )}
          {output.event && (
            <OutputClassifier icon={mdiCalendarRange}>
              {output.event}
            </OutputClassifier>
          )}
          {output.composition_type && (
            <OutputClassifier icon={mdiMusic}>
              {output.composition_type}
            </OutputClassifier>
          )}
          {output.publisher && (
            <OutputClassifier icon={mdiLibrary}>
              {output.publisher}
            </OutputClassifier>
          )}
          {output.place_of_publication && (
            <OutputClassifier icon={mdiCity}>
              {output.place_of_publication}
            </OutputClassifier>
          )}
          {output.media && (
            <OutputClassifier icon={mdiTextBoxOutline}>
              {output.media}
            </OutputClassifier>
          )}
        </Row>
      </Col>
      <Col sm={3} lg={2} className="output-badges">
        <Row>
          {altmetricEnabled && (
            <>
              <Helmet>
                {/* Altmetric embed: https://api.altmetric.com/embeds.html */}
                <script
                  type="text/javascript"
                  src="https://d1bxh8uas1mnw7.cloudfront.net/assets/embed.js"
                ></script>
              </Helmet>
              {/* Wrapper div is required; Altmetric API only looks at child elements */}
              <Col
                ref={altmetricRef}
                xs={{ span: 12, order: 10 }}
                sm={{ order: 0 }}
              >
                <div
                  className="altmetric altmetric-embed"
                  data-badge-popover="left"
                  data-badge-type="donut"
                  data-doi={output.doi}
                  data-hide-no-mentions="true"
                  data-link-target="_blank"
                ></div>
              </Col>
            </>
          )}
          {output.is_era_eligible && (
            <Col xs={12} className="text-md-center">
              <IconLabel
                icon={<EraEligibleIcon className="output-icon" size="1.5em" />}
              >
                <EraAbbr /> Eligible
              </IconLabel>
            </Col>
          )}
          {output.is_open_access && (
            <Col xs={12} className="text-md-center">
              <ConditionalWrapper
                condition={!!output.url}
                as="a"
                className="link-implicit"
                href={output.url}
              >
                <IconLabel
                  icon={<OpenAccessIcon className="output-icon" size="1.5em" />}
                >
                  Open Access
                </IconLabel>
              </ConditionalWrapper>
            </Col>
          )}
        </Row>
      </Col>
    </Row>
  )
}

OutputListItem.propTypes = {
  person: PropTypes.object.isRequired,
  showAltmetric: PropTypes.bool.isRequired,
}

OutputListItem.defaultProps = {
  showAltmetric: true,
}

export default function ProfileOutputs({ person, className }) {
  const [altmetricAvailable, setAltmetricAvailable] = useState(true)
  return (
    <>
      {!altmetricAvailable && (
        <Alert variant="warning">
          <strong>Couldn't load Altemtric API!</strong> Sorting by Altmetric
          scores requires access to the Altmetric API and this appears not to
          have loaded correctly. Please check your browser settings and reload
          the page.
        </Alert>
      )}
      <DataExplorer
        id="outputs-data"
        className={className}
        data={person.outputs}
        type="research output"
        typePlural="research outputs"
        pageSizes={[10, 25, 50, 'all']}
        views={{
          list: {
            title: 'List',
            view: props => <div>{props.children}</div>,
            item: props => (
              <OutputListItem
                key={props.row.original.id}
                person={person}
                showAltmetric={!person.settings.hide_altmetric}
                {...props}
              />
            ),
          },
        }}
        columns={[
          {
            id: 'year',
            accessor: output => output.year || 99999,
            sortType: 'basic',
          },
          { accessor: 'title' },
          {
            accessor: 'type',
            sortType: useRegexSortType(
              [
                /^Book(?! Chapter)/,
                /^Book Chapter/,
                /^Journal Article/,
                /^Conference Item/,
                /^Patent/,
                /^Other/,
                /^Dataset/,
              ],
              'type'
            ),
          },
          { accessor: 'type_label' },
          {
            id: 'is_lead_author',
            accessor: output =>
              output.authors[0].person &&
              output.authors[0].person.link_id === person.link_id,
          },
          {
            id: 'is_in_last_5_years',
            accessor: output =>
              (output.year || 99999) >= new Date().getFullYear() - 5,
          },
          {
            id: 'is_open_access',
            accessor: output => output.is_open_access,
          },
          {
            id: 'is_era_eligible',
            accessor: output => output.is_era_eligible,
            sortType: 'basic',
          },
        ]}
        sort={[
          {
            title: 'Most recent',
            columns: [
              { id: 'year', desc: true },
              { id: 'is_era_eligible', desc: true },
              { id: 'type' },
              { id: 'title' },
            ],
          },
          {
            title: 'Type',
            columns: [
              { id: 'type_label' },
              { id: 'year', desc: true },
              { id: 'is_era_eligible', desc: true },
              { id: 'title' },
            ],
          },
          {
            title: 'Title',
            columns: [{ id: 'title' }],
          },
          !person.settings.hide_altmetric && {
            id: 'altmetric_score',
            title: (
              <>
                <IconLabel
                  icon={
                    <img
                      src={AltmetricLogo}
                      alt="Altmetric rainbow logo"
                      style={{ width: '1em' }}
                    />
                  }
                >
                  Altmetric score
                </IconLabel>
              </>
            ),
            customAction: async ({ setOrderedData, setIsLoading }) => {
              // Fire off all async requests, await the promises as one, yield scores score=\d+, then sort the original dataset + set manualSortBy
              // Switching away from this sort order will require an action on all other sort orders
              //
              // Here be smallish dragons:
              //  * requires the Altmetric Embed JS API to be loaded to get the API key
              //  * minor risk of badge URLs changing
              if (!!window._altmetric) {
                setIsLoading(true)

                const now = new Date()
                const data = await Promise.all(
                  person.outputs.map(output => {
                    if (!!output.doi) {
                      const altmetricApiUrl = `${window._altmetric.api_uri}/${
                        window._altmetric.api_version
                      }/doi/${toAltmetricDoi(
                        output.doi
                      )}?callback=callback=_altmetric.embed_callback&domain=${
                        document.domain
                      }&key=${
                        window._altmetric.api_key
                      }&cache_until=${now.getHours()}-${now.getDate()}`

                      return fetch(altmetricApiUrl)
                        .then(response => response.text())
                        .then(data => {
                          const score = data.match(/score=(\d+)/)
                          return {
                            output,
                            score: !!score ? score[1] : null,
                          }
                        })
                    } else {
                      return Promise.resolve({
                        output,
                        score: null,
                      })
                    }
                  })
                )

                // Sort outputs by Altmetric score, then set manual sort ordering
                setOrderedData(
                  data.sort((a, b) => b.score - a.score).map(obj => obj.output)
                )
                setIsLoading(false)
              } else {
                setAltmetricAvailable(false)
                window.location.hash = `#outputs`
              }
            },
          },
        ]}
        filters={[
          {
            id: 'is_lead_author',
            type: 'boolean',
            title: 'Lead Author',
          },
          {
            id: 'is_in_last_5_years',
            type: 'boolean',
            title: 'Last 5 Years',
          },
          {
            id: 'is_era_eligible',
            type: 'boolean',
            title: (
              <>
                <EraEligibleIcon size="1em" /> <EraAbbr /> Eligble
              </>
            ),
          },
          {
            id: 'is_open_access',
            type: 'boolean',
            title: (
              <>
                <OpenAccessIcon size="1em" /> Open Access
              </>
            ),
          },
        ]}
      />
    </>
  )
}
