import React from 'react'
import SimpleIcon from './SimpleIcon'
import config from '../config'

export default function OpenAccessIcon(props) {
  const { size, ...rest } = props
  return (
    <SimpleIcon
      icon={config.icons.open_access}
      colored
      titled
      style={{ width: size }}
      {...rest}
    />
  )
}
