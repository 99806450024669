import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import ConditionalWrapper from './ConditionalWrapper'

import './Showcase.scss'

function Item(props) {
  const {
    as,
    className,
    href,
    symbol,
    value,
    unit,
    unitPlural,
    children,
    ...rest
  } = props
  const Tag = as
  return (
    <Tag className={classnames('showcase-item', className)} {...rest}>
      {children || (
        <ConditionalWrapper
          condition={!!href}
          as="a"
          className="text-reset"
          href={href}
        >
          <div className="showcase-figure">
            {symbol && <span className="showcase-symbol">{symbol}</span>}
            {value}
          </div>
          <div className="showcase-unit">
            {unitPlural ? (value !== 1 ? unitPlural : unit) : unit}
          </div>
        </ConditionalWrapper>
      )}
    </Tag>
  )
}

Item.defaultProps = {
  as: 'div',
}

Item.propTypes = {
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.elementType]).isRequired,
  className: PropTypes.string,
  href: PropTypes.string,
  symbol: PropTypes.any,
  value: PropTypes.any,
  unit: PropTypes.any,
  unitPlural: PropTypes.any,
  children: PropTypes.any,
}

export default {
  Item,
}
