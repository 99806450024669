import React from 'react'
import PropTypes from 'prop-types'
import { Media } from 'react-bootstrap'
import Icon from '@mdi/react'
import classnames from 'classnames'

import config from '../config'

export default function IconLabel(props) {
  const { icon, iconMargin, align, children, className, ...rest } = props
  return (
    <Media className="d-inline-flex">
      <div
        className={classnames(
          `mr-${iconMargin}`,
          align && `align-self-${align}`
        )}
      >
        {typeof icon === 'string' ? (
          <Icon
            className={classnames('flex-shrink-0', className)}
            path={icon}
            {...rest}
          />
        ) : (
          props.icon
        )}
      </div>
      <Media.Body>{children}</Media.Body>
    </Media>
  )
}

IconLabel.defaultProps = {
  size: '1.5em',
  iconMargin: 1,
}

IconLabel.propTypes = {
  icon: PropTypes.any.isRequired,
  iconMargin: PropTypes.number.isRequired,
  size: PropTypes.string,
  align: PropTypes.string,
  children: PropTypes.any.isRequired,
}

function IconLabelAcademics(props) {
  return (
    <IconLabel icon={config.icons.academic} {...props}>
      Academics
    </IconLabel>
  )
}

function IconLabelStudents(props) {
  return (
    <IconLabel icon={config.icons.student} {...props}>
      <span className="d-none d-md-inline">Research </span>Students
    </IconLabel>
  )
}

IconLabel.Academics = IconLabelAcademics
IconLabel.Students = IconLabelStudents
