import React from 'react'
import PropTypes from 'prop-types'
import { Container, Row, Col, Button } from 'react-bootstrap'
import Icon from '@mdi/react'
import { mdiArrowRight } from '@mdi/js'
import classnames from 'classnames'

import Hero from './Hero'
import SearchInput from './SearchInput'
import { personUrl, personName } from '../utils'
import config from '../config'

import './Feature.scss'

export default function Feature(props) {
  const {
    className,
    title,
    inlayBackground,
    searchPlaceholder,
    aboutText,
    actionVerb,
    count,
    countNoun,
    breakpoint,
    identityText,
    identityPosition,
    ...rest
  } = props

  return (
    <>
      <Hero
        className={classnames('hero-feature', className)}
        jumbotronClassName="jumbotron-feature text-light"
        containerClassName="position-relative"
        {...rest}
      >
        {identityText && (
          <Row className={`justify-content-${identityPosition}`}>
            <Col xs="auto" className="feature-identity text-shadow">
              {identityText}
            </Col>
          </Row>
        )}
        <Row className={`feature-inner justify-content-${breakpoint}-center`}>
          <Col xl={10}>
            <div
              className="feature-inner-inlay rounded-sm"
              style={{ background: inlayBackground }}
            >
              <Row>
                <Col className="text-center">
                  {title && (
                    <h1 className="feature-title text-shadow">{title}</h1>
                  )}
                </Col>
              </Row>
              <Row className="justify-content-center row-search">
                <Col {...{ [breakpoint]: 11 }}>
                  <Row>
                    <Col {...{ [breakpoint]: 8 }}>
                      <SearchInput placeholder={searchPlaceholder} />
                    </Col>
                    <Col className={`d-none d-${breakpoint}-block`}>
                      <div className="bg-light">
                        <Button
                          variant="outline-primary"
                          size="lg"
                          block
                          className="px-1 text-compress-more"
                          href="/portfolio"
                        >
                          {actionVerb}
                          <span className="d-none d-xl-inline">
                            {' '}
                            {config.format.number(count)} {countNoun}{' '}
                            <Icon path={mdiArrowRight} size="1em" />
                          </span>
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
            {aboutText && (
              <Container fluid="lg" className={`d-none d-${breakpoint}-block`}>
                <Row className={`justify-content-${breakpoint}-center`}>
                  <Col
                    {...{ [breakpoint]: 10 }}
                    className="feature-byline text-shadow"
                  >
                    <p className="about-portfolio lead mb-1">{aboutText}</p>
                  </Col>
                </Row>
              </Container>
            )}
          </Col>
        </Row>
      </Hero>

      <Container fluid="lg" className={`d-${breakpoint}-none mb-3`}>
        <Row className="mb-3">
          <Col>
            <Button
              variant="outline-primary"
              size="lg"
              block
              className="border-3 text-compress-more"
              href="#"
            >
              {actionVerb} {config.format.number(count)} {countNoun}{' '}
              <Icon path={mdiArrowRight} size="1em" />
            </Button>
          </Col>
        </Row>
        {aboutText && (
          <Row>
            <Col>{aboutText}</Col>
          </Row>
        )}
      </Container>
    </>
  )
}

function PersonIdentityText(props) {
  return (
    <>
      <div className="feature-identity-name">
        <a className="stretched-link" href={personUrl(props.person)}>
          {props.person.title && (
            <div className="feature-identity-title">{props.person.title}</div>
          )}
          <div className="lead">{personName(props.person)}</div>
        </a>
      </div>
      {props.byline && (
        <div className="feature-identity-byline">
          {props.byline}
          &nbsp;
          <Icon path={mdiArrowRight} size="1.25em" />
        </div>
      )}
    </>
  )
}

Feature.propTypes = {
  title: PropTypes.string.isRequired,
  inlayBackground: PropTypes.string,
  searchPlaceholder: PropTypes.string.isRequired,
  aboutText: PropTypes.node,
  actionVerb: PropTypes.string,
  count: PropTypes.number,
  countNoun: PropTypes.string,
  breakpoint: PropTypes.string,
  identityText: PropTypes.any,
  identityPosition: PropTypes.string,
}

Feature.defaultProps = {
  breakpoint: 'md',
  actionVerb: 'Browse',
  identityPosition: 'end',
}

Feature.PersonIdentityText = PersonIdentityText
