import React, { useState } from 'react'
import { InputGroup, FormControl, Button } from 'react-bootstrap'
import Icon from '@mdi/react'
import { mdiMagnify } from '@mdi/js'

import { searchUrl } from '../utils'

export default function SearchInput(props) {
  const [searchTerm, setSearchTerm] = useState(null)

  return (
    <InputGroup size="lg" className={props.className}>
      <FormControl
        className="overflow-ellipsis"
        placeholder={props.placeholder}
        defaultValue={
          document.location.search.search('\\?query=') === 0
            ? decodeURIComponent(
                document.location.search.replace('?query=', '')
              )
            : undefined
        }
        onChange={event => setSearchTerm(event.target.value)}
        aria-label="JCU Research Portfolio search"
        aria-describedby="button-search"
      />
      <InputGroup.Append>
        <Button
          variant="primary"
          className="d-flex align-items-center px-1 py-0"
          id="button-search"
          aria-label="Search"
          href={searchUrl(searchTerm)}
        >
          <Icon path={mdiMagnify} size="2em" />
        </Button>
      </InputGroup.Append>
    </InputGroup>
  )
}
