import React from 'react'
import { Container, Row, Col, Card, Button } from 'react-bootstrap'

import { IconLabel, Title, Intro } from './components'
import config from './config'

import './ContactUs.scss'

import HeroImg from './img/townsville-campus-aerial.jpg'
import JcuConnectLogo from './img/jcu-connect-logo.svg'

export default function ContactUs() {
  return (
    <div className="contact-us">
      <Title page="Contact Us" />

      <Intro backgroundImage={HeroImg} heading="Contact Us">
        Got a question about research at JCU? We can help.
      </Intro>

      <Container fluid="lg" className="py-3">
        <Row xs={1} lg={3}>
          <Col>
            <Card>
              <Card.Header>
                <IconLabel icon={config.icons.academic}>
                  Researchers, academics &amp; industry
                </IconLabel>
              </Card.Header>
              <Card.Body>
                <Card.Text className="lead">
                  <a
                    className="d-block mb-3"
                    href={config.urls.jcuConnectContact}
                  >
                    <img
                      src={JcuConnectLogo}
                      className="img-fluid"
                      alt="JCU Connect logo"
                    />
                  </a>
                  <a href={config.urls.jcuConnectContact}>JCU Connect</a> opens
                  doors across our campuses in Australia and Singapore, making
                  it easier for you to connect with the right people, expertise,
                  facilities, resources, and advice.
                </Card.Text>
              </Card.Body>
              <Card.Footer className="p-0">
                <Button
                  href={config.urls.jcuConnectContact}
                  variant="success"
                  block
                  size="lg"
                >
                  Contact JCU Connect
                </Button>
              </Card.Footer>
            </Card>
          </Col>
          <Col>
            <Card>
              <Card.Header>
                <IconLabel icon={config.icons.student}>
                  Research students &amp; candidates
                </IconLabel>
              </Card.Header>
              <Card.Body>
                <Card.Text className="lead">
                  <a href={config.urls.grs}>JCU Graduate Research School</a> is
                  your primary point of contact for all research
                  candidate-related enquiries. Our staff can assist with finding
                  a topic or advisor, expressions of interest, scholarships and
                  more.
                </Card.Text>
              </Card.Body>
              <Card.Footer className="p-0">
                <Button href={config.urls.grsContact} block size="lg">
                  Contact the GRS
                </Button>
              </Card.Footer>
            </Card>
          </Col>
          <Col>
            <Card>
              <Card.Header>
                <IconLabel icon={config.icons.help}>
                  Help with this site
                </IconLabel>
              </Card.Header>
              <Card.Body>
                <Card.Text className="lead">
                  If you've got questions about your own profile page or the
                  content of the JCU Research Portfolio, we have a dedicated
                  page just for you.
                </Card.Text>
              </Card.Body>
              <Card.Footer className="p-0">
                <Button href="/help" variant="danger" block size="lg">
                  Get Research Portfolio help
                </Button>
              </Card.Footer>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  )
}
