import React from 'react'

import CallToAction from './CallToAction'

import CollaborationImg from '../img/researchers/zsuzsa.banhalmizakar.jpg'

export default function CallToActionCollaboration() {
  return (
    <CallToAction
      title="Want to collaborate with JCU’s researchers?"
      backgroundImage={CollaborationImg}
      backgroundPosition="center 24%"
      action="Find an Expert"
      actionLink="/researchers"
    />
  )
}
