import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { mdiChevronRight } from '@mdi/js'
import Icon from '@mdi/react'

import {
  CallToAction,
  DisciplineCards,
  Feature,
  FeaturedCategories,
  IconLabel,
  ProfileMini,
  ProjectCard,
  Title,
} from './components'
import { getLocalResearcher, personUrl } from './utils'
import config from './config'
import projectData from './data/projects'

import './FindAnAdvisor.scss'
import FeatureImg from './img/researchers/jamie.seymour.jpg'
import CallToActionImg from './img/orpheus-island.jpg'

export default function FindAnAdvisor() {
  const featuredResearcher = getLocalResearcher('jamie.seymour')
  return (
    <>
      <Title page="Find an Advisor" />

      <Feature
        title="Meet your JCU Research Advisors"
        aboutText={
          <>
            The Research Portfolio profiles topics, projects and academics at
            James Cook University, helping you find the right supervisor for
            your research.{' '}
            <Link className="ml-2" to="/find-an-advisor/howto">
              Learn&nbsp;how…
            </Link>
          </>
        }
        searchPlaceholder="Find by topic, research, name…"
        backgroundImage={FeatureImg}
        backgroundPosition="center 20%"
        actionVerb={
          <>
            <Icon path={config.icons.advisor} size="1.5em" /> Browse
          </>
        }
        count={587}
        countNoun="Advisors"
        identityText={
          <Feature.PersonIdentityText
            person={getLocalResearcher('jamie.seymour')}
            byline="World-renowned toxinologist"
          />
        }
      />

      <Container fluid="lg" className="mb-3 mb-lg-5">
        <Row>
          <Col lg={4} className="d-flex flex-column border-right">
            <h2 className="h3">
              <IconLabel icon={config.icons.advisor}>Have you met?</IconLabel>
            </h2>
            <ProfileMini className="mb-3" person={featuredResearcher} />
            <p className="lead flex-grow-1">
              {featuredResearcher.settings.byline}
            </p>
            <div className="text-right">
              <a href={personUrl(featuredResearcher)}>
                Read more
                <Icon path={mdiChevronRight} size={1} />
              </a>
            </div>
            {/* Testimonials / featured people
             * Profile of someone with 3 projects next to them
             * Connections to the new Course pages (MPhil, PhD)
             * */}
          </Col>
          <Col lg={8} className="d-flex flex-column">
            <h2 className="h3 text-center">Featured projects</h2>
            <Row xs={1} md={2} className="flex-grow-1">
              {projectData.slice(0, 2).map(project => (
                <ProjectCard project={project} />
              ))}
            </Row>
            <div className="text-right">
              <a href="/projects">
                See all projects
                <Icon path={mdiChevronRight} size={1} />
              </a>
            </div>
          </Col>
        </Row>
      </Container>

      <CallToAction
        title="Find your research topic and Advisors"
        action="Learn how"
        actionLink="/find-an-advisor/howto"
        className="mb-5"
      />

      <FeaturedCategories
        title="Explore all JCU Advisors"
        titleAs="h2"
        icon={config.icons.advisor}
        categories={{
          '/projects/advisors': 'By research topic',
          '/researchers/academy': 'By academic group',
          '/search': 'Search by keyword',
        }}
      />

      <Container fluid="lg" className="mb-3 mb-lg-5">
        <DisciplineCards title="Discover JCU research topics" />
      </Container>

      <CallToAction
        title="Find the perfect JCU research project"
        backgroundImage={CallToActionImg}
        action="Explore all projects"
        actionLink="/projects"
      />
    </>
  )
}
