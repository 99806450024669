import React from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap'

import { Title, CallToAction, SimpleIcon, Steps } from './components'
import ORCID from 'simple-icons/icons/orcid'

import './Login.scss'

import JcuShield from './img/icons/jcu-shield.svg'
import HeroImg from './img/collage.jpg'
import CallToActionImg from './img/advisor.jpg'

export default function Login() {
  return (
    <div className="login">
      <Title page="Login" />

      <CallToAction
        title={
          <>
            Login
            <span className="d-none d-md-inline">
              {' '}
              to your Research Portfolio
            </span>
          </>
        }
        backgroundImage={HeroImg}
        backgroundPosition="center 60%"
        className="mb-4"
      >
        <Row className="justify-content-center">
          <Col xs={12} md="auto" className="mb-2 mb-md-0">
            <Button size="lg" block href="/oicd/callback">
              <img
                className="mt-n1 mr-1"
                src={JcuShield}
                alt="JCU Shield"
                style={{ height: '2em' }}
              />{' '}
              Sign in with JCU
            </Button>
          </Col>
          <Col xs={12} md="auto">
            <Button variant="light" size="lg" block>
              <SimpleIcon
                icon={ORCID}
                colored
                titled
                className="mr-1"
                style={{ height: '1.75em' }}
              />{' '}
              Sign in with ORCID
            </Button>
          </Col>
        </Row>
      </CallToAction>

      <Container className="mb-3">
        <Row className="justify-content-center">
          <Col lg={11}>
            <p className="lead">
              The Research Portfolio is your digital academic presence at James
              Cook University. Tell your story by showcasing your research to
              the world — make your profile available to everyone, including
              collaborators, industry, future students and the media.
            </p>

            <Row className="justify-content-center">
              <Col md={5} className="login-profile-why">
                <h2>
                  <span>Why?</span>
                </h2>
              </Col>
            </Row>
            <Steps>
              <Steps.Step>
                <h3>Be found</h3>
                <p>
                  Whether you’re an academic or postgraduate researcher, being
                  visible online is critical to being successful. A Research
                  Portfolio page is optimised so you and your work can be found.
                  Share your page easily with your own{' '}
                  <a href="https://jcu.me">jcu.me</a> link.
                </p>
              </Steps.Step>
              <Steps.Step>
                <h3>Automatically updated and created</h3>
                <p>
                  Editing is fast. We draw content in from sources at JCU like
                  publications, grants, and contact information so it’s always
                  up-to-date. Something not quite right? We’ll help you get it
                  fixed anywhere at JCU.
                </p>
              </Steps.Step>
              <Steps.Step>
                <h3>Vital to academic success</h3>
                <p>
                  For academics, having a completed and updated Research
                  Portfolio page is central to the promotions process. For PhD
                  and Masters candidates, this is your ticket to raising your
                  profile, especially for the media and when reaching
                  completion.
                </p>
              </Steps.Step>
            </Steps>
          </Col>
        </Row>
      </Container>

      <CallToAction
        title="Want to become a JCU researcher?"
        backgroundImage={CallToActionImg}
        action="Join us today"
        actionLink="#"
      />
    </div>
  )
}
