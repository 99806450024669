import { useMemo } from 'react'

export function useRegexSortType(ordering, id) {
  // This takes an Array of ordered regex patterns, a value key and returns
  // a function that returns a react-table sortType-compatible value

  return useMemo(
    () => (rowA, rowB, desc) => {
      const orderIndex = row =>
        ordering.findIndex(re => !!row.values[id].match(re))
      const indexA = orderIndex(rowA)
      const indexB = orderIndex(rowB)
      let returnValue
      if (indexA > indexB) returnValue = -1
      else if (indexA < indexB) returnValue = 1

      // Flip the return value depending on sort order
      return !desc ? returnValue : -returnValue
    },
    [ordering, id]
  )
}
