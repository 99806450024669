import React from 'react'
import ORCID from 'simple-icons/icons/orcid'

import SimpleIcon from './SimpleIcon'

function OrcidIdLink(props) {
  const { orcidId, size, ...rest } = props
  return (
    <a href={`https://orcid.org/${orcidId}`} {...rest}>
      <SimpleIcon
        icon={ORCID}
        colored
        titled
        className="mr-1"
        style={{ width: props.size }}
      />
      <span className="d-none d-sm-inline">https://orcid.org/</span>
      {props.orcidId}
    </a>
  )
}

OrcidIdLink.defaultProps = {
  size: '1.5em',
}

export default OrcidIdLink
