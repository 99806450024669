import React from 'react'
import PropTypes from 'prop-types'

export default function Debug(props) {
  if (!props.forceDisplay && process.env.NODE_ENV !== 'development') {
    return null
  }

  return (
    <code style={{ display: 'block', whiteSpace: 'pre' }}>
      {JSON.stringify(props.object, null, 2)}
    </code>
  )
}

Debug.propTypes = {
  object: PropTypes.any,
  forceDisplay: PropTypes.bool,
}
