import React from 'react'
import PropTypes from 'prop-types'
import { Container, Breadcrumb } from 'react-bootstrap'
import Icon from '@mdi/react'
import { mdiHome, mdiChevronLeft } from '@mdi/js'

import './Breadcrumbs.scss'

export default function Breadcrumbs(props) {
  let parentItem = props.hierarchy.slice(-2, -1)
  const parent = parentItem.length ? parentItem[0] : { url: '/', title: 'Home' }

  return (
    <Container fluid="lg" className="breadcrumbs-wrapper bg-dark">
      <div className="breadcrumbs">
        {/* Simple breadcrumb for mobile */}
        <Breadcrumb className="d-block d-md-none">
          <Breadcrumb.Item href={parent.url}>
            <Icon path={mdiChevronLeft} size={1} />
            {parent.title}
          </Breadcrumb.Item>
        </Breadcrumb>
        {/* Full breadcrumb for desktop */}
        <Breadcrumb
          className="d-none d-md-flex"
          listProps={{ className: 'bg-transparent' }}
        >
          <Breadcrumb.Item className="breadcrumb-home" href="/">
            <Icon
              path={mdiHome}
              title="Home"
              size="1.25em"
              viewBox="2 2 21 21"
            />
          </Breadcrumb.Item>
          {props.hierarchy.length >= 2 &&
            props.hierarchy.slice(0, -1).map(page => (
              <Breadcrumb.Item key={page.url} href={page.url}>
                {page.title}
              </Breadcrumb.Item>
            ))}
          <Breadcrumb.Item active>
            {props.hierarchy.slice(-1)[0].title}
          </Breadcrumb.Item>
        </Breadcrumb>
      </div>
    </Container>
  )
}

Breadcrumbs.propTypes = {
  hierarchy: PropTypes.array.isRequired,
}
