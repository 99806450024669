import React from 'react'
import { Container, Row, Col, Alert, Button, Form } from 'react-bootstrap'

import { Title, Intro } from './components'

import HeroImg from './img/townsville-campus-aerial.jpg'

export default function Legal() {
  return (
    <div className="legal">
      <Title page="Legal" />

      <Intro backgroundImage={HeroImg} heading="Legal" />

      <Container fluid="lg" className="py-4">
        <Row>
          <Col>
            <h2 className="mb-3">
              Higher Degree by Research (HDR) Candidate Declaration
            </h2>

            <Alert variant="primary">
              <strong>
                Thank you for being part of the JCU Research Portfolio v2 pilot!
              </strong>{' '}
              Please read the following, enter your details below.
            </Alert>

            <p>
              By checking the <em>I declare</em> box below, I am authorising
              James Cook University (JCU) to publish the following personal
              information on JCU’s websites:
            </p>
            <ol>
              <li>My full name;</li>
              <li>My JCU email address;</li>
              <li>My research candidature;</li>
              <li>My thesis title;</li>
              <li>My JCU advisor(s); </li>
              <li>My JCU college affiliations; and</li>
              <li>
                Other personal details that I may submit for inclusion on my JCU
                Research Portfolio profile, which may include, but are not
                limited to, a personal photograph, keywords, experience,
                achievements, interests, academic profile URLs and social media
                profile details.
              </li>
            </ol>

            <p>
              The following publicly available information already held within
              JCU databases may also be linked to my webpage (if applicable):
            </p>
            <ol>
              <li>
                Any external funding in which I am a principal investigator
                (data from the RIMS – Research Information Management System);
              </li>
              <li>
                Any research outputs on which I am an author (data from
                ResearchOnline@JCU);
              </li>
              <li>
                Any datasets on which I am a linked creator (data from Research
                Data JCU); and
              </li>
              <li>
                Any subjects on which I am a coordinator, lecturer or tutor
                (data from Teaching Roles Database).
              </li>
            </ol>

            <p>
              Because of how the World Wide Web works, I acknowledge that by
              placing information on a website, this makes it accessible to the
              entire world. By publishing my personal information on JCU’s
              website, I acknowledge that my personal information will be
              transferred outside of Australia and I agree to this transfer.
            </p>

            <p>
              By publishing information about my research candidature on JCU’s
              website, I declare that:
            </p>

            <ol>
              <li>
                I have not breached any Intellectual Property sharing agreement
                that I may be subject to; and
              </li>
              <li>
                I have fulfilled all of my obligations that I may have in
                relation to acknowledging funding relating to my research
                candidature.
              </li>
            </ol>

            <p>
              Should I have any concerns I will discuss the matter with my
              advisor(s). I am aware that I may withdraw my authorisation at any
              time by advising my advisor(s) or the system administrator of the
              JCU Research Portfolio (
              <a href="mailto:research.portfolio@jcu.edu.au">
                research.portfolio@jcu.edu.au
              </a>
              ) in writing.
            </p>
            <Form>
              <Form.Group as={Row} controlId="agreementName">
                <Form.Label column md={3}>
                  Full name
                </Form.Label>
                <Col>
                  <Form.Control type="input" placeholder="Enter your name" />
                </Col>
              </Form.Group>

              <Form.Group as={Row} controlId="formBasicEmail">
                <Form.Label column md={3}>
                  Email address
                </Form.Label>
                <Col>
                  <Form.Control type="email" placeholder="Enter email" />
                </Col>
              </Form.Group>

              <Form.Group controlId="agreementCheckbox">
                <Form.Check
                  type="checkbox"
                  label="I declare that I have read the above, that I understand it and that I consent to JCU making the disclosures and publications on its website as outlined above."
                />
              </Form.Group>

              <Alert variant="warning">
                During this demo period, please take a screenshot of this page
                and return it by email to your Research Portfolio contact.
                Eventually, you and other candidates will agree to this
                agreement in the finished, live system.
              </Alert>

              <Button size="lg" disabled>
                Submit
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  )
}
