import React from 'react'

import config from './config'
import researchers from './data/researchers'
import skills from './data/skills'

export function getLocalResearcher(link_id) {
  return {
    ...researchers.find(p => p.link_id === link_id),
  }
}

export function getRandomItems(array, count) {
  let data = new Set()
  for (let i = 0; i < count; i++) {
    data.add(array[Math.floor(Math.random() * array.length)])
  }
  return Array.from(data)
}

export function getRandomSkills(count) {
  return getRandomItems(skills, count)
}

export function isAcademic(person) {
  // TODO Handle final data structure from server;
  // this handles the temporary setup of fake type and is_student from database
  return (
    person.type === 'academic' ||
    (typeof person.is_student === 'boolean' && !person.is_student)
  )
}

export function personName(person) {
  if (person.preferred_name && person.surname) {
    return `${person.preferred_name} ${person.surname}`
  } else {
    return person.preferred_name || person.surname || 'Unknown'
  }
}

export function personNameAlphabetic(person) {
  if (person.preferred_name && person.surname) {
    return `${person.surname}, ${person.preferred_name}`
  } else {
    return person.preferred_name || person.surname
  }
}

export function personTitledName(person) {
  const name = personName(person)
  return person.title ? `${person.title} ${name}` : name
}

export function personUrl(person) {
  // Handle advisor references without a person attached
  if (!person.link_id) {
    return null
  }

  return isAcademic(person)
    ? `/portfolio/${person.link_id}`
    : `/student/${person.link_id}`
}

export function personShortUrl(person) {
  return isAcademic(person)
    ? `https://jcu.me/${person.link_id}`
    : `https://jcu.me/s/${person.link_id}`
}

export function isLongPersonName(name) {
  return !!name.split(' ').filter(n => n.length > config.names.long).length
}

export function associateUrl(associate) {
  // Link to a SettingsCore object (eg just enough data to create a link)
  return isAcademic(associate)
    ? `/portfolio/${associate.link_id}`
    : `/student/${associate.link_id}`
}

export function searchUrl(term) {
  return !!term ? `/search?query=${encodeURIComponent(term)}` : '/search'
}

export function yearRange(startYear, endYear, allowOngoing) {
  // Return a stringified year range for the given object's start and end or
  // nothing if a range isn't allowed by `allowOngoing`
  if (startYear) {
    if (endYear) {
      if (endYear > startYear) {
        return (
          <>
            <strong>
              {startYear} <span className="small">to</span> {endYear}
            </strong>
            {' – '}
          </>
        )
      }
    } else if (allowOngoing) {
      return (
        <>
          <strong>
            {startYear} <span className="small">to</span> Present
          </strong>
          {' – '}
        </>
      )
    }
  }
}

export function arrayPopulated(arr) {
  return arr && !!arr.length
}

export function parseHash(hash) {
  return !!hash.length
    ? hash
        .slice(hash.indexOf('#') + 1)
        .split('&')
        .map(arg => arg.split('='))
        .reduce((acc, cur) => {
          acc[cur[0]] = cur[1] || null
          return acc
        }, {})
    : {}
}

export function pluralise(value, singular, plural) {
  return value === 1 ? singular : plural
}
