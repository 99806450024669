import {
  mdiBank,
  mdiSchool,
  mdiAccountSupervisor,
  mdiClockOutline,
  mdiCheckAll,
  mdiHelpCircleOutline,
} from '@mdi/js'
import OpenAccess from 'simple-icons/icons/openaccess'

import { version } from '../package.json'

import JCUCampusCairns from './img/jcu-campus-cairns.jpg'
import JCUCampusTownsville from './img/jcu-campus-townsville.jpg'
import JCUCampusSingapore from './img/jcu-campus-singapore.jpg'

export default {
  cdn: 'https://cdn.jcu.edu.au/cookbook/2.0',
  sitename: 'Research Portfolio',
  version,
  urls: {
    helpdesk: 'https://www.jcu.edu.au/helpdesk',
    contacts: 'https://secure.jcu.edu.au/app/contact/',
    studyfinder: 'https://secure.jcu.edu.au/app/studyfinder/?subject={subject}',
    studentCentre: 'https://www.jcu.edu.au/student-centre',
    thisIsUni: 'https://www.jcu.edu.au/this-is-uni',
    grs: 'https://www.jcu.edu.au/graduate-research-school',
    grsContact: 'https://www.jcu.edu.au/graduate-research-school/contacts',
    grsHowToApply:
      'https://www.jcu.edu.au/graduate-research-school/how-to-apply',
    mediaContact: 'https://www.jcu.edu.au/news/contact-our-media-team',
    jcuConnect: 'https://www.jcu.edu.au/jcu-connect',
    jcuConnectContact: 'https://www.jcu.edu.au/jcu-connect/about',
    eresearch: 'https://www.jcu.edu.au/eresearch',
    library: 'https://www.jcu.edu.au/library',
    media: 'https://www.jcu.edu.au/news',
  },
  photos: {
    width: 154,
    height: 175,
    ratio: 154 / 175,
  },
  icons: {
    academic: mdiBank,
    student: mdiSchool,
    advisor: mdiAccountSupervisor,
    time: mdiClockOutline,
    open_access: OpenAccess,
    era_eligible: mdiCheckAll,
    help: mdiHelpCircleOutline,
  },
  format: {
    number: new Intl.NumberFormat('en-AU').format,
    compactNumber: new Intl.NumberFormat('en-AU', {
      notation: 'compact',
      compactDisplay: 'short',
    }).format,
  },
  names: {
    long: 13,
  },
  campuses: {
    townsville: {
      name: 'Townsville',
      abbreviation: 'TSV',
      image: JCUCampusTownsville,
      heroPosition: 'center 33%',
      url: '/campus/townsville',
      websiteUrl: 'https://www.jcu.edu.au/about-jcu/campuses/townsville',
      lat: -19.3287,
      lng: 146.7583,
      mapUrl: 'https://maps.jcu.edu.au/campus/townsville/?location={location}',
      mapZIndex: 1001,
    },
    cairns: {
      name: 'Cairns',
      abbreviation: 'CNS',
      image: JCUCampusCairns,
      heroPosition: 'center 70%',
      url: '/campus/cairns',
      websiteUrl: 'https://www.jcu.edu.au/about-jcu/campuses/cairns',
      lat: -16.8178,
      lng: 145.6866,
      mapUrl: 'https://maps.jcu.edu.au/campus/cairns/?location={location}',
      mapZIndex: 1000,
    },
    singapore: {
      name: 'Singapore',
      abbreviation: 'SIN',
      image: JCUCampusSingapore,
      heroPosition: 'center 48%',
      url: '/campus/singapore',
      websiteUrl: 'https://www.jcu.edu.au/about-jcu/campuses/singapore',
      lat: 1.31573,
      lng: 103.87584,
      mapUrl: 'https://maps.jcu.edu.au/campus/singapore/',
      mapZIndex: 1000,
    },
  },
}
