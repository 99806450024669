import React from 'react'
import classnames from 'classnames'
import { NavDropdown } from 'react-bootstrap'

import './NavDropdownHover.scss'

export default function NavDropdownHover(props) {
  const { className, children, ...rest } = props
  return (
    <NavDropdown
      show
      className={classnames('dropdown-hover', className)}
      {...rest}
    >
      {children}
    </NavDropdown>
  )
}
