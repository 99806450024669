import React, { useState } from 'react'
import {
  Container,
  Nav,
  Navbar,
  NavDropdown,
  Form,
  FormControl,
  Button,
  Modal,
} from 'react-bootstrap'
import Icon from '@mdi/react'
import { mdiMagnify, mdiAccount } from '@mdi/js'

import config from '../config'
import Logo from './Logo'
import MenuToggle from './MenuToggle'
import SearchInput from './SearchInput'
import RouterNavLink from './RouterNavLink'

import './Header.scss'

function isPortfolioPage(match, location) {
  return !!match || location.pathname.startsWith('/student')
}

function Header() {
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const handleShow = () => setShow(true)

  const breakpoint = 'lg'
  return (
    <header className="header">
      <Navbar expand={breakpoint} collapseOnSelect className="flex-column p-0">
        <Container fluid={breakpoint} className="p-1 pb-sm-2">
          <Navbar.Brand>
            <Logo sitename={config.sitename} />
          </Navbar.Brand>
          <Form inline>
            <FormControl
              type="text"
              placeholder="Search"
              className={`d-none d-${breakpoint}-inline mr-2`}
            />
            <Modal show={show} onHide={handleClose}>
              <Modal.Body>
                <SearchInput />
                Results go here
              </Modal.Body>
            </Modal>
            <Button
              variant="link"
              className="text-dark p-0"
              onClick={handleShow}
            >
              <Icon path={mdiMagnify} title="Search" size={1.5} />
            </Button>
            <Navbar.Toggle as={MenuToggle} aria-controls="navbar" />
          </Form>
        </Container>
        <Navbar.Collapse className="navbar-menu w-100 bg-light" id="navbar">
          <Container className="px-lg-0" fluid={breakpoint}>
            <Nav className={`mr-${breakpoint}-auto`}>
              <RouterNavLink to="/" exact>
                Home
              </RouterNavLink>
              <RouterNavLink to="/portfolio" isActive={isPortfolioPage}>
                Researchers
              </RouterNavLink>
              <RouterNavLink to="/explore">Explore</RouterNavLink>
              <RouterNavLink to="/find-an-advisor">
                Find an Advisor
              </RouterNavLink>
              <RouterNavLink to="/projects">Research Projects</RouterNavLink>
              <RouterNavLink to="/help">Help</RouterNavLink>
            </Nav>
            <Nav>
              <RouterNavLink to="/login">Login</RouterNavLink>
              <NavDropdown
                className="d-none"
                title={
                  <>
                    <Icon path={mdiAccount} title="My Portfolio" size={1} /> My
                    Portfolio
                  </>
                }
                id="my-portfolio-dropdown"
              >
                <NavDropdown.Item href="#">My profile</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href="#">Login as&hellip;</NavDropdown.Item>
                <NavDropdown.Item href="#">Manage</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href="#">Logout</NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Container>
        </Navbar.Collapse>
      </Navbar>
    </header>
  )
}

export default Header
