import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'

import './Steps.scss'

export default function Steps(props) {
  return (
    <Container fluid className="steps">
      {props.children}
    </Container>
  )
}

function Step(props) {
  return (
    <Row className="mb-3">
      <Col xs={2} md={1} className="step-number"></Col>
      <Col xs={10} md={11}>
        {props.children}
      </Col>
    </Row>
  )
}

Steps.Step = Step
