import React from 'react'
import PropTypes from 'prop-types'

import JCULogo from './JCULogo'

import './Logo.scss'

export default function Logo(props) {
  const textClassName = props.variant.includes('-rev')
    ? 'text-light'
    : 'text-reset'
  return (
    <div className="brand-logo">
      <a href="https://www.jcu.edu.au">
        <JCULogo
          variant={props.variant}
          className="brand-logo-img"
          style={props.style}
        />
      </a>
      {props.sitename && (
        <a className={`brand-logo-sitename ${textClassName}`} href="/">
          {props.sitename}
        </a>
      )}
    </div>
  )
}

Logo.propTypes = {
  variant: PropTypes.string.isRequired,
  style: PropTypes.object,
  sitename: PropTypes.string,
}

Logo.defaultProps = {
  variant: 'horizontal',
}
