import React from 'react'
import PropTypes from 'prop-types'
import { Row, Col, Button } from 'react-bootstrap'
import classnames from 'classnames'

import Hero from './Hero'

import './CallToAction.scss'

import CallToActionImg from '../img/3mt.jpg'

export default function CallToAction(props) {
  const { className, title, action, actionLink, children, ...rest } = props
  return (
    <Hero className={classnames('hero-cta', className)} {...rest}>
      <Row className="justify-content-center">
        <Col lg="9">
          <div className="h1 hero-cta-title">{title}</div>
          {action && actionLink && (
            <Button
              block
              size="lg"
              className="btn-md hero-cta-action"
              href={actionLink}
            >
              {action}
            </Button>
          )}
          {children}
        </Col>
      </Row>
    </Hero>
  )
}

CallToAction.propTypes = {
  title: PropTypes.string.isRequired,
  action: PropTypes.string,
  actionLink: PropTypes.string,
  children: PropTypes.any,
}

CallToAction.General = function () {
  return (
    <CallToAction
      title="Discover world-class research"
      backgroundImage={CallToActionImg}
      backgroundPosition="center 20%"
      action="Search the JCU Research Portfolio"
      actionLink="/"
    />
  )
}
