import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { useMedia } from 'react-use'
import { Button } from 'react-bootstrap'
import {
  Map,
  TileLayer,
  LayersControl,
  FeatureGroup,
  Marker,
  Popup,
} from 'react-leaflet'
import L from 'leaflet'
import { mdiChevronRight } from '@mdi/js'
import Icon from '@mdi/react'
import faker from 'faker'

import CollaborationMapLegend from './CollaborationMapLegend'
import JCULogo from './JCULogo'
import { pluralise } from '../utils'

import config from '../config'

import 'leaflet/dist/leaflet.css'
import 'leaflet-fullscreen'
import 'leaflet-fullscreen/dist/leaflet.fullscreen.css'
import './CollaborationMap.scss'

import mapMarkerPublication from '../img/map-marker-publication.svg'
import mapMarkerFunding from '../img/map-marker-funding.svg'
import mapMarkerActivity from '../img/map-marker-activity.svg'
import mapMarkerJcu from '../img/map-marker-academic.svg'

function makeMarker(icon, className, scaleFactor) {
  let scale = scaleFactor || 0.75
  const markerWidth = 38 * scale
  const markerHeight = 54 * scale
  return new L.Icon({
    iconUrl: icon,
    iconRetinaUrl: icon,
    iconAnchor: [markerWidth * 0.5, markerHeight],
    popupAnchor: [1, -markerHeight * 0.7],
    iconSize: [markerWidth, markerHeight],
    className,
  })
}
const jcuIcon = makeMarker(mapMarkerJcu, 'leaflet-marker-icon-jcu', 0.95)
const publicationIcon = makeMarker(
  mapMarkerPublication,
  'leaflet-marker-icon-publication'
)
const fundingIcon = makeMarker(mapMarkerFunding, 'leaflet-marker-icon-funding')
const activityIcon = makeMarker(
  mapMarkerActivity,
  'leaflet-marker-icon-activity'
)

function fakeData(count, nameFn) {
  return Array(count)
    .fill(null)
    .map(() => ({
      latitude: faker.address.latitude(),
      longitude: faker.address.longitude(),
      name: nameFn(),
      id: faker.address.countryCode(),
      count: parseInt(faker.random.number().toString()[0], 10),
    }))
}

function CollaborationMap(props) {
  faker.seed(1)

  // Restructure person data with nested location property
  const coerceData = collaboration => ({
    ...collaboration,
    ...collaboration.location,
  })
  const layers = [
    {
      name: 'Outputs',
      singular: 'output',
      description:
        'Publications, data, media, presentations and other outputs arising from JCU research',
      icon: publicationIcon,
      data: props.data.filter(c => c.type === 'Output').map(coerceData),
    },
    {
      name: 'Funding',
      singular: 'funding',
      description:
        'Research grants, contracts, consultancies and other funding relationships associated with JCU research',
      icon: fundingIcon,
      data: props.data
        .filter(c => c.type === 'Funding')
        .map(coerceData)
        .map(c => {
          c.latitude *= 1.015
          c.longitude *= 1.005
          return c
        }),
    },
    {
      name: 'Activities',
      singular: 'activity',
      description: 'Other research activities associated with JCU',
      icon: activityIcon,
      data: fakeData(props.fakeActivities, faker.company.companyName),
    },
  ]

  if (props.showCampuses) {
    layers.push({
      name: 'JCU Campus',
      description: 'Location of a JCU campus engaged in research',
      icon: jcuIcon,
    })
  }

  // TODO Needs to be relative to the Map width itself to work on Profile pages
  const isMd = useMedia('(min-width: 768px)')
  const isXl = useMedia('(min-width: 1200px)')
  const state = {
    lat: 5.880869245,
    lng: 20.91796875,
    zoom: isXl ? 3 : isMd ? 2 : 1,
  }

  useEffect(() => {
    delete L.Icon.Default.prototype._getIconUrl

    L.Icon.Default.mergeOptions({
      iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
      iconUrl: require('leaflet/dist/images/marker-icon.png'),
      shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
    })
  }, [])

  const mapRef = useRef(null)
  const layerControlRef = useRef(null)
  const layerRefs = []
  const saveLayerRef = element => {
    layerRefs.push(element)
  }

  const handleLayerChange = event => {
    //if (mapRef.current && layerControlRef.current) {
    //const map = mapRef.current.leafletElement
    //const allLayers = L.featureGroup(layerControlRef.current.leafletElement._layers.map(l => l.layer))
    //map.fitBounds(allLayers.getBounds())
    //}
    if (mapRef.current && layerRefs.length) {
      const map = mapRef.current.leafletElement
      // TODO only get layers that are enabled in the LayersControl
      const bounds = L.featureGroup(
        layerRefs.map(l => l.leafletElement)
      ).getBounds()
      if (bounds.isValid()) {
        map.fitBounds(bounds.pad(-0.05))
      }
    }
  }

  // TODO legend with icons (toggleable)
  // See https://codesandbox.io/s/how-to-add-a-legend-to-the-map-using-react-leaflet-useleaflet-hook-75jnp?file=/src/Legend.jsx

  const collaborations = value =>
    pluralise(value, 'collaboration', 'collaborations')

  return (
    <Map
      className="map-collaboration"
      ref={mapRef}
      center={[state.lat, state.lng]}
      maxBounds={[
        [-89.5, -360],
        [89.5, 360],
      ]}
      zoom={state.zoom}
      minZoom={1}
      maxZoom={8}
      keyboard={false}
      attributionControl={false}
      scrollWheelZoom={false}
      fullscreenControl
      onOverlayAdd={handleLayerChange}
      onOverlayRemove={handleLayerChange}
    >
      <TileLayer
        attribution={null}
        url="https://tropicaldatahub.org/tiles/jcu-research-portfolio/{z}/{x}/{y}.png"
        detectRetina
      />
      <LayersControl ref={layerControlRef} position="topright">
        {layers.map(layer => (
          <LayersControl.Overlay key={layer.name} name={layer.name} checked>
            <FeatureGroup ref={saveLayerRef} onAdd={handleLayerChange}>
              {layer.data &&
                layer.data.map((collaboration, index) => (
                  <Marker
                    key={
                      layer.name !== 'Activities'
                        ? `${layer.name}-${collaboration.id}`
                        : index
                    }
                    position={[collaboration.latitude, collaboration.longitude]}
                    {...(layer.icon ? { icon: layer.icon } : {})}
                  >
                    <Popup>
                      {/* Location-centric collaborations */}
                      {collaboration.count && (
                        <>
                          <div className="h5 mb-1">{collaboration.name}</div>
                          <p>
                            {collaboration.count} JCU research {layer.singular}{' '}
                            {collaborations(collaboration.count)}
                          </p>
                          <a href={`/search/${collaboration.id}`}>
                            See all researchers
                          </a>
                        </>
                      )}

                      {/* Person-centric collaborations */}
                      {collaboration.institutions && (
                        <>
                          <div className="h5 mb-1">{collaboration.name}</div>
                          <p>
                            {collaboration.institutions.length} research{' '}
                            {layer.singular}{' '}
                            {collaborations(collaboration.institutions.length)}:
                          </p>
                          <ul>
                            {collaboration.institutions.map(institution => (
                              <li key={institution}>{institution}</li>
                            ))}
                          </ul>
                        </>
                      )}
                    </Popup>
                  </Marker>
                ))}
            </FeatureGroup>
          </LayersControl.Overlay>
        ))}
        {props.showCampuses && (
          <LayersControl.Overlay name="JCU Campuses" checked>
            <FeatureGroup ref={saveLayerRef} onAdd={handleLayerChange}>
              {Object.values(config.campuses).map(campus => (
                <Marker
                  key={campus.name}
                  icon={jcuIcon}
                  position={[campus.lat, campus.lng]}
                  zIndexOffset={campus.mapZIndex}
                >
                  <Popup>
                    <a
                      className="d-block h4 mb-2 text-reset"
                      href={campus.websiteUrl}
                    >
                      <JCULogo
                        variant="shield"
                        className="mr-2"
                        style={{ height: '3rem' }}
                      />
                      <span className="align-middle">{campus.name}</span>
                    </a>
                    {campus.image && (
                      <img
                        className="img-fluid mb-2"
                        src={campus.image}
                        alt={campus.name}
                        loading="lazy"
                      />
                    )}
                    <Button
                      block
                      size="sm"
                      className="d-flex justify-content-between rounded text-white"
                      href={campus.url}
                    >
                      See researchers <Icon path={mdiChevronRight} size={1} />
                    </Button>
                    <Button
                      variant="warning"
                      block
                      size="sm"
                      className="d-flex justify-content-between rounded text-black"
                      href={campus.websiteUrl}
                    >
                      Discover this campus
                      <Icon path={mdiChevronRight} size={1} />
                    </Button>
                  </Popup>
                </Marker>
              ))}
            </FeatureGroup>
          </LayersControl.Overlay>
        )}
      </LayersControl>
      <CollaborationMapLegend position="bottomleft" layers={layers} />
    </Map>
  )
}

CollaborationMap.defaultProps = {
  showCampuses: true,
  fakeActivities: 20,
}

CollaborationMap.propTypes = {
  data: PropTypes.array,
  showCampuses: PropTypes.bool,
  fakeActivities: PropTypes.number,
}

export default CollaborationMap
