import React from 'react'
import {
  Container,
  Row,
  Col,
  Form,
  DropdownButton,
  Dropdown,
} from 'react-bootstrap'
import { mdiFilter } from '@mdi/js'
import Icon from '@mdi/react'

import {
  Title,
  Intro,
  CallToAction,
  ProfileFeatured,
  SearchInput,
  IconLabel,
} from './components'
import config from './config'

import researchers from './data/researchers'

//import './About.scss'

import HeroImg from './img/dro-canopy-crane.jpg'
import CallToActionImg from './img/3mt.jpg'

export default function About() {
  return (
    <div>
      <Title page="Search" />

      <Intro
        backgroundImage={HeroImg}
        backgroundPosition="center 90%"
        heading="Search"
      >
        <SearchInput
          className="mb-2"
          placeholder="Find by research, topic, name..."
        />
        <Form.Check
          custom
          inline
          defaultChecked
          name="type"
          label={<IconLabel.Academics />}
          type="checkbox"
          id="search-academics"
        />
        <Form.Check
          custom
          inline
          defaultChecked
          name="type"
          label={<IconLabel.Students />}
          type="checkbox"
          id="search-students"
        />
      </Intro>

      <Container fluid="lg" className="py-3">
        <Row className="mb-4">
          <Col>
            <Form inline>
              <Form.Group controlId="resultCount" className="mr-auto">
                <Form.Label className="mr-1">Show</Form.Label>
                <Form.Control as="select" defaultValue={25}>
                  <option>5</option>
                  <option>10</option>
                  <option>25</option>
                  <option>50</option>
                  <option>all</option>
                </Form.Control>
              </Form.Group>

              <Icon path={mdiFilter} size="1.5rem" className="mr-2" />
              <DropdownButton
                className="mr-3"
                alignRight
                title="Researcher Type"
              >
                <Dropdown.Item>{<IconLabel.Academics />}</Dropdown.Item>
                <Dropdown.Item>{<IconLabel.Students />}</Dropdown.Item>
              </DropdownButton>

              <DropdownButton
                className="mr-3"
                alignRight
                title={
                  <IconLabel icon={config.icons.advisor}>
                    Advisor Status
                  </IconLabel>
                }
              >
                <Dropdown.Item>Advisor Mentor</Dropdown.Item>
                <Dropdown.Item>Primary</Dropdown.Item>
                <Dropdown.Item>Secondary</Dropdown.Item>
              </DropdownButton>

              <Form.Group controlId="campus">
                <Form.Label className="mr-1">Campus</Form.Label>
                <Form.Control as="select" defaultValue="All">
                  <option>All</option>
                  <option>Townsville</option>
                  <option>Cairns</option>
                  <option>Singapore</option>
                </Form.Control>
              </Form.Group>
            </Form>
          </Col>
        </Row>
        <Row xs={1}>
          {researchers
            .filter(person => person.type === 'academic')
            .map(person => (
              <Col key={person.link_id}>
                <ProfileFeatured person={person} />
              </Col>
            ))}
        </Row>
      </Container>

      <CallToAction
        title="Need help from the JCU team?"
        backgroundImage={CallToActionImg}
        action="Get in touch"
        actionLink="/contact-us"
      />
    </div>
  )
}
