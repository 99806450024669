import React, { useState } from 'react'
import {
  Container,
  Row,
  Col,
  Button,
  Dropdown,
  DropdownButton,
} from 'react-bootstrap'

import { Title, Intro, CallToAction } from './components'
import config from './config'

import HeroImg from './img/science-place-molecule-lights.jpg'

export default function Help() {
  const [section, setSection] = useState(null)

  const sections = {
    'getting-a-page': {
      title: 'Getting a Portfolio page',
      content: (
        <>
          <p>
            {' '}
            If you're considered an Active Researcher at JCU, you'll already
            have a profile page.
          </p>
          <p>
            If you don't need the above criteria for a Resaerch Portfolio page,
            you can <a href="/contact-us">Contact Us</a> for special
            consideration.
          </p>
        </>
      ),
    },
    editing: {
      title: 'Editing my profile',
      content: '',
    },
    'correcting-information': {
      title: 'Updating or correcting information',
    },
    'contact-researcher': {
      title: 'Contacting a researcher',
    },
    'finding-projects-and-advisors': {
      title: 'Finding a research project or Advisor',
    },
    'inappropriate-content': {
      title: 'Inappropriate page content',
      content: (
        <>
          <p className="lead">
            The JCU Research Portfolio is a work in progress and contains
            content that is sourced automatically from corporate databases as
            well as featuring some content that is self-contributed by our
            researchers.
          </p>
          <p>
            In the case of sensitive data being conveyed to the public, we
            encourage you to contact the researcher attributed to the content
            and let them know of your concerns. You may also wish to contact the
            researcher's Advisors if they are a research student. It may be a
            case of clarifying the context in which the data is being displayed
            or it may require a review of the source data stored in JCU's
            sytems.
          </p>
          <p>
            Some JCU Research Portfolio pages may contain content or topics that
            are offensive to readers. In the event you encounter such content,
            we encourage you to contact the researcher (or their Research
            Advisors, if the ) attributed ot the content and discuss it.
          </p>
          <p>
            Should your concerns be urgent, you can always{' '}
            <a href="/contact-us">Contact Us</a> and the JCU Research Portfolio
            team will investigate as soon as possible.
          </p>
        </>
      ),
    },
    other: {
      title: 'Something else',
    },
  }

  return (
    <>
      <Title page="Help" />

      <Intro
        backgroundImage={HeroImg}
        backgroundPosition="center 45%"
        heading="Help"
        headingLarge="Help and Support"
      >
        We’re here to help answer your Research Portfolio questions.
      </Intro>

      <Container fluid="lg" className="my-5">
        <Row>
          <Col>
            <div>
              <p className="h2">
                If you’re from JCU, login so we can help you better{' '}
                <Button size="lg" href="/login">
                  Login
                </Button>
              </p>
            </div>

            <div>
              <p className="h2">
                I need help with:{' '}
                <DropdownButton
                  variant="outline-secondary"
                  size="lg"
                  alignRight
                  className="d-inline"
                  title={section ? sections[section].title : 'Support topics'}
                  onSelect={eventKey => setSection(eventKey)}
                >
                  {Object.keys(sections).map(sectionId => (
                    <Dropdown.Item eventKey={sectionId}>
                      {sections[sectionId].title}
                    </Dropdown.Item>
                  ))}
                </DropdownButton>
              </p>
            </div>

            {section && (
              <div className="my-4 lead">
                <h2>{sections[section].title}</h2>
                {sections[section].content}
              </div>
            )}
          </Col>
        </Row>
      </Container>

      <CallToAction
        title="Didn't answer your question?"
        action="Ask the JCU Helpdesk"
        actionLink={config.urls.helpdesk}
      />
    </>
  )
}
