import React from 'react'
import PropTypes from 'prop-types'
import { Jumbotron, Container } from 'react-bootstrap'
import classNames from 'classnames'

import './Hero.scss'

export default function Hero(props) {
  const {
    backgroundImage,
    backgroundPosition,
    backgroundConstrain,
    backgroundColor,
    className,
    jumbotronClassName,
    containerClassName,
    ...rest
  } = props

  return (
    <div
      className={classNames(
        'hero',
        {
          'hero-has-bg': backgroundImage,
          'hero-bg-constrain': backgroundConstrain,
        },
        className
      )}
      style={{
        backgroundImage: backgroundImage && `url(${backgroundImage})`,
        backgroundPosition,
        backgroundColor,
      }}
      {...rest}
    >
      <Jumbotron fluid className={jumbotronClassName}>
        <Container fluid="lg" className={containerClassName}>
          {props.children}
        </Container>
      </Jumbotron>
    </div>
  )
}

Hero.propTypes = {
  backgroundImage: PropTypes.string,
  backgroundPosition: PropTypes.string,
  backgroundConstrain: PropTypes.bool,
  backgroundColor: PropTypes.string,
}
