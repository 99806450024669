import React, { useMemo, useContext } from 'react'
import PropTypes from 'prop-types'
import {
  Row,
  Col,
  Button,
  Accordion,
  useAccordionToggle,
  AccordionContext,
} from 'react-bootstrap'
import classnames from 'classnames'
import moment from 'moment'

import DataExplorer from './DataExplorer'
import ResponsiveValue from './ResponsiveValue'
import Showcase from './Showcase'
import { associateUrl } from '../utils'
import config from '../config'

import './ProfileFunding.scss'

function Investigator(props) {
  const nameDisplay = `${props.investigator.name} (${props.investigator.role})`
  return (
    <>
      {props.investigator.person ? (
        <a
          className={classnames({
            'author-self':
              props.currentPerson.link_id === props.investigator.person.link_id,
          })}
          href={associateUrl(props.investigator.person)}
        >
          {nameDisplay}
        </a>
      ) : (
        nameDisplay
      )}
    </>
  )
}

Investigator.propTypes = {
  investigator: PropTypes.object.isRequired,
  currentPerson: PropTypes.object.isRequired,
}

function FundingSection(props) {
  return (
    <Row className="funding-section">
      <Col md="3" className="mb-2 mb-md-0">
        <h4 className="mb-0 font-serif">{props.title}</h4>
      </Col>
      <Col className="align-self-center">{props.children}</Col>
    </Row>
  )
}

FundingSection.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.any,
}

function FundingAccordionToggle({ children, eventKey }) {
  const currentEventKey = useContext(AccordionContext)
  const handleToggle = useAccordionToggle(eventKey)
  const isCurrentEventKey = currentEventKey === eventKey
  return (
    <Button
      block
      variant="outline-primary"
      className="mt-2"
      onClick={handleToggle}
    >
      {!isCurrentEventKey ? '+ More detail' : '– Less detail'}
    </Button>
  )
}

function FundingListItem({ row: { original: funding }, person }) {
  const dateDisplay = useMemo(() => str => moment(str).format('MMM YYYY'), [])
  return (
    <Accordion key={funding.application_id} className="funding">
      <Row className="funding-details">
        <Col xs={12} sm className="mb-2">
          <h3 className="h4">{funding.project_title}</h3>
          {(funding.funder_name || funding.administrative_organisation) && (
            <>
              {funding.funder_name && (
                <div>
                  <strong>Funder:</strong> {funding.funder_name}
                </div>
              )}
              {funding.administrative_organisation && (
                <div>
                  <strong>Administered by:</strong>{' '}
                  {funding.administrative_organisation}
                </div>
              )}
            </>
          )}
        </Col>
        <Col xs={10} sm={4}>
          <Showcase.Item
            className="funding-outline"
            symbol="$"
            value={
              <ResponsiveValue.Number
                value={Math.round(funding.approved_amount)}
              />
            }
            unit={
              <>
                <span className="small">indicative funding from</span>
                <br />
                {dateDisplay(funding.start_date)}{' '}
                <span className="small">to</span>{' '}
                {dateDisplay(funding.end_date)}
              </>
            }
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <p className="lead mb-0">{funding.summary}</p>

          <Accordion.Collapse eventKey="0" className="mt-3">
            <>
              <FundingSection title="Indicative Funding">
                ${config.format.number(funding.approved_amount)} from{' '}
                {dateDisplay(funding.start_date)} to{' '}
                {dateDisplay(funding.end_date)}
              </FundingSection>
              {funding.investigators && !!funding.investigators.length && (
                <FundingSection title="Investigators">
                  {funding.investigators.map((investigator, index) => (
                    <React.Fragment key={index}>
                      <Investigator
                        investigator={investigator}
                        currentPerson={person}
                      />
                      {index <= funding.investigators.length - 3 && ', '}
                      {index === funding.investigators.length - 2 && ' and '}
                    </React.Fragment>
                  ))}
                </FundingSection>
              )}
              {funding.associates && (
                <FundingSection title="Associates">
                  {funding.associates}
                </FundingSection>
              )}
              {funding.partner && (
                <FundingSection title="Partners">
                  {funding.partner}
                </FundingSection>
              )}
              {funding.keywords && (
                <FundingSection title="Keywords">
                  {funding.keywords}
                </FundingSection>
              )}
            </>
          </Accordion.Collapse>

          <Row className="justify-content-end">
            <Col xs={12} sm="auto">
              <FundingAccordionToggle eventKey="0" />
            </Col>
          </Row>
        </Col>
      </Row>
    </Accordion>
  )
}

export default function ProfileFunding({ person }) {
  return (
    <DataExplorer
      id="funding-data"
      data={person.funding}
      type="current project"
      typePlural="current projects"
      defaultPageSize={5}
      views={{
        list: {
          title: 'List',
          view: props => <div>{props.children}</div>,
          item: props => FundingListItem({ person, ...props }),
        },
      }}
      columns={[
        { accessor: 'start_date' },
        { accessor: 'end_date' },
        { accessor: 'project_title' },
        { accessor: 'approved_amount' },
        { accessor: 'funder_name' },
        // Global search only
        { accessor: 'administrative_organisation' },
        {
          id: 'investigators',
          accessor: funding =>
            funding.investigators &&
            funding.investigators.map(i => i.name).join(', '),
        },
        { accessor: 'associates' },
        { accessor: 'partner' },
        { accessor: 'keywords' },
      ]}
      sort={[
        {
          title: 'Most recent',
          columns: [
            { id: 'start_date', desc: true },
            { id: 'end_date', desc: true },
            { id: 'approved_amount', desc: true },
          ],
        },
        {
          title: 'Funded amount',
          columns: [
            { id: 'approved_amount', desc: true },
            { id: 'start_date', desc: true },
            { id: 'end_date', desc: true },
          ],
        },
        {
          title: 'Title',
          columns: [{ id: 'project_title' }],
        },
        {
          title: 'Funder',
          columns: [{ id: 'funder_name' }],
        },
      ]}
    />
  )
}
