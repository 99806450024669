import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { personName, isAcademic } from '../utils'

import researchers from '../data/researchers'
import defaultPhoto from '../img/default-photo.svg'

function ProfilePhoto(props) {
  const { person, className, width, height, ...rest } = props
  const imgRef = useRef(null)
  let src, alt

  if (person) {
    if (person.photo) {
      // Inject photo for local profiles
      src = person.photo
    } else {
      // Inject photo for local profiles if data not already loaded
      const override = researchers.filter(p => p.link_id === person.link_id)
      if (override.length) {
        src = override[0].photo
      }

      if (!src && isAcademic(person)) {
        // Existing researchers
        src = `https://research.jcu.edu.au/portfolio/${person.link_id}/photo/${props.variant}/`
      }
    }
    alt = `Profile photograph of ${personName(person)}`
  }

  // Everyone else without a photo
  if (!src) {
    src = defaultPhoto
    alt = person
      ? `Generic person avatar representing ${personName(person)}`
      : `Generic person avatar`
  }

  return (
    <img
      ref={imgRef}
      onError={() => (imgRef.current.src = defaultPhoto)}
      className={className}
      src={src}
      alt={alt}
      {...rest}
    />
  )
}

ProfilePhoto.propTypes = {
  person: PropTypes.object,
  variant: PropTypes.string,
}

ProfilePhoto.defaultProps = {
  variant: 'profile',
}

export default ProfilePhoto
