import React, { useEffect } from 'react'
import ReactDOM from 'react-dom'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { useLeaflet } from 'react-leaflet'
import L from 'leaflet'
import classnames from 'classnames'

import TropicsPatternImg from '../img/map-pattern-tropics.svg'
import './CollaborationMapLegend.scss'

function CollaborationMapLegend(props) {
  const { map } = useLeaflet()

  useEffect(() => {
    const legend = L.control({ position: props.position })

    legend.onAdd = () => {
      const div = L.DomUtil.create(
        'div',
        classnames('map-legend', props.className)
      )
      const contents = (
        <>
          {props.layers.map(layer => (
            <OverlayTrigger
              key={layer.name}
              placement="right"
              overlay={<Tooltip>{layer.description}</Tooltip>}
              trigger={['hover', 'focus', 'click']}
              rootClose
            >
              <div className="map-legend-entry">
                <img
                  src={layer.icon.options.iconRetinaUrl}
                  alt={`Icon representing ${layer.name} on the collaboration map`}
                />
                {layer.name}
              </div>
            </OverlayTrigger>
          ))}
          <OverlayTrigger
            placement="right"
            overlay={
              <Tooltip>
                Also known as the Torrid zone. Read about the{' '}
                <em>State of the Tropics</em> report
              </Tooltip>
            }
          >
            <div className="map-legend-entry">
              <img
                src={TropicsPatternImg}
                alt="Green striped pattern indicating the Tropics"
              />
              <a
                className="tropics-marker-link"
                href="http://stateofthetropics.org/"
              >
                Tropics
              </a>
            </div>
          </OverlayTrigger>
        </>
      )
      ReactDOM.render(contents, div)
      return div
    }
    legend.addTo(map)

    return function cleanup() {
      legend.remove()
    }
  }, [map, props.layers, props.className, props.position])

  return null
}

CollaborationMapLegend.defaultProps = {
  position: 'bottomleft',
}

export default CollaborationMapLegend
