import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'

import { Title, Breadcrumbs, Intro, CallToAction } from './components'
import config from './config'

import HeroImg from './img/townsville-campus-aerial.jpg'
import DirectoryOfExpertiseLogo from './img/exptitle.gif'
import DavidFlorisScreenshot from './img/davidfloris.jpg'

const directoryUrl =
  'https://web.archive.org/web/19980204005334/http://comlegal.jcu.edu.au/exp.html'
const davidFlorisUrl =
  'https://web.archive.org/web/20130703160956/https://research.jcu.edu.au/portfolio/'

export default function About() {
  return (
    <div>
      <Title page="About" />

      <Breadcrumbs hierarchy={[{ title: 'About' }]} />

      <Intro
        backgroundImage={HeroImg}
        heading="About"
        headingLarge="About the Research Portfolio"
      >
        A dynamic profile of researchers, collaboration, projects, outputs and
        more.
      </Intro>

      <Container fluid="lg" className="py-3 py-lg-5">
        <Row className="justify-content-center">
          <Col>
            <p className="lead mb-4">
              The Research Portfolio profiles all areas of research, academics
              and postgraduate research students at JCU. Data is drawn
              automatically from JCU's{' '}
              <a href="https://docs.jcu.me">research and corporate databases</a>
              , processed into user-friendly formats and displayed in profile
              form.
            </p>
            <div className="mb-4">
              <h2>Background</h2>
              <p className="clearfix">
                <a
                  className="d-block float-sm-right ml-sm-2 mb-2"
                  href={directoryUrl}
                >
                  <img
                    src={DirectoryOfExpertiseLogo}
                    alt="Directory of Expertise logo"
                  />
                </a>
                The first version of this site launched all the way back in
                1997. Back then it was called the{' '}
                <em>Directory of Expertise</em> and can still be seen in its{' '}
                <a href={directoryUrl}>archived format</a>, complete with 6
                digit phone numbers and single password shared between
                researchers.
              </p>
              <p>
                <a
                  className="d-block float-sm-right ml-sm-2 mb-2"
                  href={davidFlorisUrl}
                >
                  <img
                    src={DavidFlorisScreenshot}
                    alt="Screenshot of 2012 version of the JCU Research Portfolio"
                    style={{ maxWidth: '200px' }}
                  />
                </a>
                Since then, a{' '}
                <a href={davidFlorisUrl}>modernised JCU Research Portfolio</a>{' '}
                launched in 2012, displaying over 1000 research staff and
                enabling creation and filling of profiles automatically based
                upon existing information in JCU systems. By displaying content
                in a clean, easy-to-understand fashion, the Portfolio revealed
                everything about JCU researchers to the University, the research
                community and even to researchers <em>themselves</em>. New
                Portfolio pages were added weekly and functionality was been
                added periodically to address new University needs.
              </p>
              <p>
                In 2020, the entire system was rebuilt from the ground up, with
                daily updates, ORCID integration and the addition of
                postgraduate research students receiving official Portfolio
                pages for the first time. The inclusion of research projects and
                the ability to interactively <a href="/explore">Explore</a>{' '}
                JCU’s research data have formed site you see now.
              </p>
              <p>
                Today, the JCU Research Portfolio continues its mission of
                improving and supporting research at James Cook University by
                presenting as much interesting information about our research
                and researchers to the world.
              </p>
            </div>

            <h2>Project Team</h2>
            <p>
              The JCU Research Portfolio is an active collaboration between the
              following groups:
            </p>
            <ul>
              <li>
                <a href={config.urls.jcuConnect}>JCU Connect</a>: data collation
                and processing, project coordination, operations and support
              </li>
              <li>
                <a href={config.urls.eresearch}>JCU eResearch Centre</a>:
                research and development, programming, project management,
                testing, operations and support
              </li>
              <li>
                <a href={config.urls.library}>JCU Library</a>: researcher
                support, testing
              </li>
              <li>
                <a href={config.urls.grs}>JCU Graduate Research School</a>:
                processes and insight, testing, researcher support
              </li>
            </ul>
            <p>
              For more information about this project or to send us feedback,{' '}
              <a href="/contact-us">contact us</a>.
            </p>
          </Col>
        </Row>
      </Container>

      <CallToAction.General />
    </div>
  )
}
