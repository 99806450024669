import React from 'react'
import { Spinner as BootstrapSpinner } from 'react-bootstrap'

export default function Spinner(props) {
  return (
    <BootstrapSpinner animation="border" role="status" {...props}>
      <span className="sr-only">Loading&hellip;</span>
    </BootstrapSpinner>
  )
}
