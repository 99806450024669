import React, { useState } from 'react'
import { Alert, CloseButton } from 'react-bootstrap'

import './DemoAlert.scss'

export default function DemoAlert(props) {
  const [show, setShow] = useState(true)

  return (
    <Alert variant="warning" show={show} className="alert-demo">
      <strong>v.2 Demo</strong> – we're looking for research photos &amp;
      feedback at{' '}
      <a href="mailto:research.portfolio@jcu.edu.au?subject=Research%20Portfolio%20v2%20Feedback">
        research.portfolio@jcu.edu.au
      </a>{' '}
      <span role="img" aria-label="Thank you!">
        🙌
      </span>{' '}
      <CloseButton
        className="float-none ml-2 ml-lg-4"
        label="Hide"
        onClick={() => setShow(false)}
      />
    </Alert>
  )
}
