import React from 'react'
import PropTypes from 'prop-types'

export default function ConditionalWrapper(props) {
  const { condition, as, children, ...rest } = props
  const Wrapper = as
  return condition ? <Wrapper {...rest}>{children}</Wrapper> : children
}

ConditionalWrapper.propTypes = {
  condition: PropTypes.any,
  as: PropTypes.oneOfType([PropTypes.string, PropTypes.elementType]).isRequired,
  children: PropTypes.any,
}
