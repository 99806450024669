import React from 'react'
import { Nav } from 'react-bootstrap'
import { LinkContainer } from 'react-router-bootstrap'

// This exists to workaround the 'active' class issue described at
// https://github.com/react-bootstrap/react-router-bootstrap/issues/242
export default function RouterNavLink({children, ...props}) {
  return (
    <LinkContainer {...props}>
      <Nav.Link active={false}>
        {children}
      </Nav.Link>
    </LinkContainer>
  )
}
