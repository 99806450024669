import React from 'react'
import { Row, Col } from 'react-bootstrap'

import DisciplineCard from './DisciplineCard'

import CardMediacalScienceImg from '../img/researchers/margaret.jordan-2.jpg'
import CardEducationImg from '../img/researchers/komla.tsey.jpg'
import CardMarineBiologyImg from '../img/researchers/sueann.watson-2.jpg'
import CardNursingImg from '../img/researchers/karen.yates.jpg'
import CardBusinessImg from '../img/researchers/chris.cunneen.jpg'
import CardMedicineImg from '../img/researchers/kate.domett.jpg'

export default function DisciplineCards(props) {
  return (
    <>
      <Row className="mb-3">
        <Col className="text-center">
          <h2>{props.title}</h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col lg={11}>
          <Row xs={1} sm={2} lg={3}>
            <DisciplineCard
              name="Medicine & Dentistry"
              areas={['Medicine', 'Dentistry', 'Pharmacy']}
              image={CardMedicineImg}
            />
            <DisciplineCard
              name="Science, Engineering & IT"
              areas={[
                'Marine Biology',
                'Environmental Management',
                'Engineering',
              ]}
              image={CardMarineBiologyImg}
            />
            <DisciplineCard
              name="Business & Law"
              areas={['Law', 'Business', 'Conflict Management']}
              image={CardBusinessImg}
            />
            <DisciplineCard
              name="Healthcare Sciences"
              areas={[
                'Occupational Therapy',
                'Nursing and Midwifery',
                'Physiotherapy',
              ]}
              image={CardNursingImg}
            />
            <DisciplineCard
              name="Medical Sciences"
              areas={['Biomedicine', 'Public Health', 'Veterinary Science']}
              image={CardMediacalScienceImg}
            />
            <DisciplineCard
              name="Arts, Society & Education"
              areas={[
                'Humanities and Creative Arts',
                'Social Work',
                'Education',
              ]}
              image={CardEducationImg}
            />
          </Row>
        </Col>
      </Row>
    </>
  )
}
