import moment from 'moment'

import AlanaGrech from '../img/researchers/alana.grech.jpg'
import ElsaAntunes from '../img/researchers/elsa.antunes1.jpg'
import CarlaEwels from '../img/researchers/carla.ewels.jpg'
import RonaldWhite from '../img/researchers/ronald.white.jpg'
import KearrinSims from '../img/researchers/kearrin.sims.jpg'
import LeoNankervis from '../img/researchers/leo.nankervis.jpg'
import JohnGinger from '../img/researchers/john.ginger.jpg'
import SaraBrice1 from '../img/researchers/sara.brice1.jpg'
import MarkHamann from '../img/researchers/mark.hamann.jpg'
import ScottSmithers from '../img/researchers/scott.smithers.jpg'
import JanHuizenga from '../img/researchers/jan.huizenga.jpg'
import TejasSingh from '../img/researchers/tejas.singh.jpg'
import StewartLockie from '../img/researchers/stewart.lockie.jpg'
import ClaireHansen3 from '../img/researchers/claire.hansen3.jpg'
import FlorenceBoulard from '../img/researchers/florence.boulard.jpg'
import NigelChang from '../img/researchers/nigel.chang.jpg'
import RositaHenry from '../img/researchers/rosita.henry.jpg'
import LidiaSuarez from '../img/researchers/lidia.suarez.jpg'
import RobertoDillon from '../img/researchers/roberto.dillon.jpg'
import CliveJones from '../img/researchers/clive.jones.jpg'
import KathelineHua from '../img/researchers/katheline.hua.jpg'
import LouisePhillips from '../img/researchers/louise.phillips.jpg'
import AlexandraAikhenvald from '../img/researchers/alexandra.aikhenvald.jpg'
import ElizabethForest from '../img/researchers/elizabeth.forest.jpg'
import ElizabethSmyth from '../img/researchers/elizabeth.smyth.jpg'

const researchers = [
  {
    type: 'academic',
    link_id: 'caryn.west',
    preferred_name: 'Caryn',
    surname: 'West',
    title: 'Professor',
    orcidId: '0000-0001-8778-3578',
    settings: {
      byline:
        'Newly appointed Dean of Research for JCU Singapore; charged with the establishment of the second pillar of research within the Tropical Futures Institute which focuses on the healthy aging and associated non-communicable diseases in Singapore',
    },
    updated: moment().subtract(27, 'minutes'),
    showcase: ['outputs', 'funding-total', 'external-ids'],
  },
  {
    type: 'academic',
    link_id: 'chris.cocklin',
    preferred_name: 'Chris',
    surname: 'Cocklin',
    title: 'Professor',
    settings: {
      byline:
        'Professor Cocklin is the Provost and also fulfils the requirements of the Deputy Vice Chancellor (Research and Innovation) at James Cook University',
    },
    updated: moment().subtract(27, 'minutes'),
    showcase: ['outputs', 'collaboration', 'external-ids'],
  },
  {
    type: 'academic',
    link_id: 'sean.ulm',
    preferred_name: 'Sean',
    surname: 'Ulm',
    title: 'Distinguished Professor',
    orcidId: '0000-0001-6653-9963',
    settings: {
      byline:
        'Focusing on persistent problems in the archaeology of northern Australia and the western Pacific where understanding the relationships between environmental change and cultural change',
    },
    updated: moment().subtract(4, 'hours'),
    showcase: ['outputs', 'funding', 'external-ids'],
  },
  {
    type: 'academic',
    link_id: 'peter.leggat',
    preferred_name: 'Peter',
    surname: 'Leggat',
    title: 'Professor',
    settings: {
      byline:
        'More than three decades of experience in medicine and higher education in Australia and Internationally',
      suffixes:
        '<strong>AM, ADC</strong>, MD <em>Qld</em>, PhD <em>SAust</em>, DrPH <em>JCU</em>, FAFPHM <em>RACP</em>, FFPH <em>RCP(UK)</em>, <a href="https://www.phaa.net.au/membership-info/life-members-and-fellows">FPHAA</a>, FACTM, FACAsM, FFTM FFEWM <em>ACTM</em>, FFTM <em>RCPSG</em>, FACRRM, <a href="http://www.istm.org/fellowsprogram">FISTM</a>, FAIHS, FACE, FAICD, FRAS, FRGS, Hon.FFPM <em>RCP(UK)</em>, Hon.FACTM, Hon.FFTM <em>ACTM</em>, <a href="https://worldsafety.org/wso-professional-certifications/">WSO-CSE/CSM/CSS(OSH)/CSSD</a>, ChOHSP, <a href="http://www.istm.org/cthdesignation">ISTM-CTH</a>',
    },
    updated: moment().subtract(1, 'minutes'),
    showcase: ['outputs', 'funding', 'external-ids'],
  },
  {
    type: 'academic',
    link_id: 'andrew.krockenberger',
    preferred_name: 'Andrew',
    surname: 'Krockenberger',
    title: 'Professor',
    orcidId: '0000-0003-2872-9939',
    settings: {
      byline:
        'Over 20 years experience of research into the ecology and physiology of vertebrates in the lab and field, specialising in the physiology and ecology of marsupials',
    },
    updated: moment().subtract(1, 'minutes'),
    showcase: ['outputs', 'funding', 'external-ids'],
  },
  {
    type: 'academic',
    link_id: 'helene.marsh',
    preferred_name: 'Helene',
    surname: 'Marsh',
    title: 'Professor',
    orcidId: '0000-0003-3492-4992',
  },
  {
    type: 'academic',
    link_id: 'yvette.everingham',
    preferred_name: 'Yvette',
    surname: 'Everingham',
    title: 'Professor',
  },
  {
    type: 'academic',
    link_id: 'chris.rudd',
    preferred_name: 'Chris',
    surname: 'Rudd',
    title: 'Professor',
    suffixes: 'OBE',
  },
  {
    type: 'academic',
    link_id: 'dianna.hardy',
    preferred_name: 'Dianna',
    surname: 'Hardy',
    title: 'Doctor',
    showcase: ['outputs', 'supervision', 'external-ids'],
  },
  {
    type: 'academic',
    link_id: 'lindsay.ward',
    preferred_name: 'Lindsay',
    surname: 'Ward',
    title: null,
    updated: moment().subtract(5, 'minutes'),
  },
  {
    type: 'academic',
    link_id: 'jamie.seymour',
    preferred_name: 'Jamie',
    surname: 'Seymour',
    title: 'Professor',
    settings: {
      byline:
        'The “Jelly Dude from Nemo land” has been researching and working with venomous and dangerous animals for over 20 yrs with his present interest being “Why do animals have venom?” Based in Cairns, in Northern Australia, an area that has an over abundance of venomous animals, he is uniquely placed to study the ecology and biology of Australia’s venomous species.',
    },
  },
  {
    type: 'academic',
    link_id: 'ross.alford',
    preferred_name: 'Ross',
    surname: 'Alford',
    title: 'Emeritus Professor',
  },
  {
    type: 'academic',
    link_id: 'meegan.kilcullen',
    preferred_name: 'Meegan',
    surname: 'Kilcullen',
    title: 'Doctor',
  },
  {
    type: 'academic',
    link_id: 'taha.chaiechi',
    preferred_name: 'Taha',
    surname: 'Chaiechi',
    title: 'Associate Professor',
  },
  {
    type: 'academic',
    link_id: 'wendy.li',
    preferred_name: 'Wendy',
    surname: 'Li',
    title: 'Associate Professor',
  },
  {
    type: 'academic',
    link_id: 'breda.mccarthy',
    preferred_name: 'Breda',
    surname: 'McCarthy',
    title: 'Doctor',
  },
  {
    type: 'academic',
    link_id: 'narayan.gopalkrishnan',
    preferred_name: 'Narayan',
    surname: 'Gopalkrishnan',
    title: 'Doctor',
  },
  {
    type: 'academic',
    link_id: 'tasmin.rymer',
    preferred_name: 'Tasmin',
    surname: 'Rymer',
    title: 'Doctor',
  },
  {
    type: 'academic',
    link_id: 'mohan.jacob',
    preferred_name: 'Mohan',
    surname: 'Jacob',
    title: 'Professor',
  },
  {
    type: 'academic',
    link_id: 'alana.grech',
    preferred_name: 'Alana',
    surname: 'Grech',
    title: 'Doctor',
    orcidId: '0000-0003-4117-3779',
    showcase: ['outputs', 'funding', 'external-ids'],
    feature: {
      img: AlanaGrech,
      position: '20% center',
      backgroundColor: '#ceb483',
      byline: 'Coral reef research expert',
    },
  },
  {
    type: 'academic',
    link_id: 'ian.atkinson',
    preferred_name: 'Ian',
    surname: 'Atkinson',
    title: 'Professor',
    showcase: [
      'outputs',
      'funding',
      'external-ids',
      'video',
      'logo',
      'gallery',
    ],
  },
  {
    type: 'academic',
    link_id: 'agnes.leport',
    preferred_name: 'Agnes',
    surname: 'Le Port',
    title: 'Doctor',
  },
  {
    type: 'academic',
    link_id: 'brendan.ebner',
    preferred_name: 'Brendan',
    surname: 'Ebner',
    title: 'Doctor',
  },
  {
    type: 'academic',
    link_id: 'andrew.chin',
    preferred_name: 'Andrew',
    surname: 'Chin',
    title: 'Doctor',
  },
  {
    type: 'academic',
    link_id: 'ben.menadue',
    preferred_name: 'Ben',
    surname: 'Menadue',
    title: null,
  },
  {
    type: 'academic',
    link_id: 'norelle.daly',
    preferred_name: 'Norelle',
    surname: 'Daly',
    title: 'Professor',
  },
  {
    type: 'academic',
    link_id: 'elsa.antunes1',
    preferred_name: 'Elsa',
    surname: 'Dos Santos Antunes',
    title: 'Doctor',
    feature: {
      img: ElsaAntunes,
      position: '35% 40%',
      byline: 'Smart materials expert',
    },
  },
  {
    type: 'academic',
    link_id: 'carla.ewels',
    preferred_name: 'Carla',
    surname: 'Ewels',
    title: 'Doctor',
    feature: {
      img: CarlaEwels,
      position: '65% 30%',
      byline: 'Statistics & data science expert',
      identityPosition: 'start',
    },
  },
  {
    type: 'academic',
    link_id: 'kearrin.sims',
    preferred_name: 'Kearrin',
    surname: 'Sims',
    title: 'Doctor',
    feature: {
      img: KearrinSims,
      position: '40% 50%',
      byline: 'Critical development scholar',
    },
  },
  {
    type: 'academic',
    link_id: 'leo.nankervis',
    preferred_name: 'Leo',
    surname: 'Nankervis',
    title: 'Doctor',
    feature: {
      img: LeoNankervis,
      position: '85% 30%',
      byline: 'Aquaculture feed & physiology expert',
      identityPosition: 'start',
    },
  },
  {
    type: 'academic',
    link_id: 'john.ginger',
    preferred_name: 'John',
    surname: 'Ginger',
    title: 'Professor',
    feature: {
      img: JohnGinger,
      position: 'center 20%',
      byline: 'Research Director, Cyclone Testing Station',
    },
  },
  {
    type: 'academic',
    link_id: 'ronald.white',
    preferred_name: 'Ron',
    surname: 'White',
    title: 'Professor',
    feature: {
      img: RonaldWhite,
      position: '20% 30%',
      byline:
        'Node Director & Chief Investigator, ARC Centre of Excellence for Antimatter-Matter Studies',
    },
  },
  {
    type: 'academic',
    link_id: 'sara.brice1',
    preferred_name: 'Sara',
    surname: 'Brice',
    title: 'Doctor',
    orcidId: '0000-0001-6766-4184',
    feature: {
      img: SaraBrice1,
      position: '42% 50%',
      byline: 'Human & Sports Biomechanics expert',
    },
  },
  {
    type: 'academic',
    link_id: 'mark.hamann',
    preferred_name: 'Mark',
    surname: 'Hamann',
    title: 'Associate Professor',
    feature: {
      img: MarkHamann,
      position: '59% 40%',
      byline: 'Marine wildlife biology & plastic pollution expert',
      identityPosition: 'start',
    },
  },
  {
    type: 'academic',
    link_id: 'scott.smithers',
    preferred_name: 'Scott',
    surname: 'Smithers',
    title: 'Associate Professor',
    feature: {
      img: ScottSmithers,
      position: '32% 29%',
      backgroundColor: '#ddecbb',
      byline: 'Geomorphologist & environmental management expert',
    },
  },
  {
    type: 'academic',
    link_id: 'jan.huizenga',
    preferred_name: 'Jan',
    surname: 'Huizenga',
    title: 'Doctor',
    feature: {
      img: JanHuizenga,
      position: '55% 30%',
      backgroundColor: '#989c8e',
      byline: 'Fluid-rock interaction geochemist',
    },
  },
  {
    type: 'academic',
    link_id: 'tejas.singh',
    preferred_name: 'Tejas',
    surname: 'Singh',
    title: 'Doctor',
    feature: {
      img: TejasSingh,
      position: '85% 20%',
      backgroundColor: '#e2e3dd',
      byline:
        'Research Fellow, Queensland Research Centre for Peripheral Vascular Disease (QRCPVD)',
      identityPosition: 'start',
    },
  },
  {
    type: 'academic',
    link_id: 'stewart.lockie',
    preferred_name: 'Stewart',
    surname: 'Lockie',
    title: 'Distinguished Professor',
    feature: {
      img: StewartLockie,
      position: '35% 50%',
      byline: 'Director, The Cairns Institute',
    },
  },
  {
    type: 'academic',
    link_id: 'claire.hansen3',
    preferred_name: 'Claire',
    surname: 'Hansen',
    title: 'Doctor',
    feature: {
      img: ClaireHansen3,
      position: '25% 50%',
      backgroundColor: '#843a15',
      byline: 'Lecturer, English and Writing',
    },
  },
  {
    type: 'academic',
    link_id: 'florence.boulard',
    preferred_name: 'Florence',
    surname: 'Boulard',
    title: 'Doctor',
    feature: {
      img: FlorenceBoulard,
      position: '65% 65%',
      backgroundColor: '#806436',
      byline: 'Associate Dean of Teaching and Learning',
      identityPosition: 'start',
    },
  },
  {
    type: 'academic',
    link_id: 'nigel.chang',
    preferred_name: 'Nigel',
    surname: 'Chang',
    title: 'Doctor',
    feature: {
      img: NigelChang,
      position: '83% 40%',
      byline: 'Prehistoric and historic archaeologist',
      identityPosition: 'start',
    },
  },
  {
    type: 'academic',
    link_id: 'rosita.henry',
    preferred_name: 'Rosita',
    surname: 'Henry',
    title: 'Professor',
    feature: {
      img: RositaHenry,
      position: '68% 55%',
      backgroundColor: '#213212',
      byline: 'Cultural & political economy anthropologist',
      identityPosition: 'start',
    },
  },
  {
    type: 'academic',
    link_id: 'lidia.suarez',
    preferred_name: 'Lidia',
    surname: 'Suárez',
    title: 'Doctor',
    feature: {
      img: LidiaSuarez,
      position: '0% 40%',
      backgroundColor: '#D9D6C5',
      byline: 'Senior Lecturer in Psychology, JCU Singapore',
    },
  },
  {
    type: 'academic',
    link_id: 'clive.jones',
    preferred_name: 'Clive',
    surname: 'Jones',
    title: 'Doctor',
    feature: {
      img: CliveJones,
      position: 'center',
      backgroundColor: '#E8E4E0',
      byline: 'Aquaculture expert & marine technologist',
    },
  },
  {
    type: 'academic',
    link_id: 'roberto.dillon',
    preferred_name: 'Roberto',
    surname: 'Dillon',
    title: 'Associate Professor',
    feature: {
      img: RobertoDillon,
      position: '25% 45%',
      byline: 'Academic Head, IT & Sciences',
    },
  },
  {
    type: 'academic',
    link_id: 'katheline.hua',
    preferred_name: 'Katheline',
    surname: 'Hua',
    title: 'Doctor',
    feature: {
      img: KathelineHua,
      position: '40% 70%',
      backgroundColor: '#CAD9DD',
      byline: 'Aquaculture nutrition expert',
    },
  },
  {
    type: 'academic',
    link_id: 'louise.phillips',
    preferred_name: 'Louise',
    surname: 'Phillips',
    title: 'Doctor',
    feature: {
      img: LouisePhillips,
      position: '75% center',
      identityPosition: 'start',
      byline:
        'Early childhood educator, storyteller, consultant & tertiary educator',
    },
  },
  {
    type: 'academic',
    link_id: 'alexandra.aikhenvald',
    preferred_name: 'Sasha',
    surname: 'Aikhenvald',
    title: 'Distinguished Professor',
    feature: {
      img: AlexandraAikhenvald,
      position: '27% 32%',
      byline: 'Human language, culture & linguistics expert',
    },
  },
  {
    type: 'student',
    link_id: 'elizabeth.forest',
    preferred_name: 'Elizabeth',
    surname: 'Forest',
    title: null,
    photo: ElizabethForest,
    settings: {
      byline:
        'I am a PhD Candidate in Machine Learning investigating whether clustering can be used to replicate expert decision making',
    },
    showcase: ['outputs', 'contact', 'download'],
  },
  {
    type: 'student',
    link_id: 'elizabeth.smyth',
    preferred_name: 'Elizabeth',
    surname: 'Smyth',
    title: null,
    orcidId: '0000-0002-7320-769X',
    photo: ElizabethSmyth,
    showcase: ['outputs', 'outputs-era', 'video'],
    settings: {
      byline: 'A writer for the wet tropics of north-eastern Australia',
      biography: `<p>Elizabeth Smyth is a writer, librarian and PhD candidate at James Cook University.</>
  <p>
    Her writing has been published in <em>Meanjin</em>, shortlisted for the Varuna Publisher Introduction Program, and longlisted in the prestigious ABR Elizabeth Jolley Short Story Prize. Elizabeth’s awards include a Varuna Residential Fellowship, Queensland Writers Centre Maher Fellowship, and numerous Regional Arts Development Fund grants.
  </p>
  <p>
    The focus of Elizabeth’s research is the conflict between environmental exploitation and nurturing on farms. Elizabeth aims to develop new insights into farming and farm life through creative-practice research. This research draws on eco-critical and new materialist perspectives to explore human relations with non-human nature and materials. By writing a compelling novel, Elizabeth aims to give urban readers a dramatic experience of the idyllic and non-idyllic aspects of contemporary farm life in the Australian sugar industry.
  </p>
      `,
    },
  },
]

export default researchers
