import React from 'react'
import PropTypes from 'prop-types'

import config from '../config'

export default function JCULogo({ variant, alt, ...rest }) {
  return (
    <img
      src={`${config.cdn}/img/logos/jcu-logo-${variant}.svg`}
      alt={alt}
      {...rest}
    />
  )
}

JCULogo.defaultProps = {
  alt: 'James Cook University (JCU) Australia logo',
}

JCULogo.propTypes = {
  variant: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
}
