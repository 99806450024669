import React from 'react'
import { Row, Col, Card } from 'react-bootstrap'
import Icon from '@mdi/react'
import { mdiChevronRight } from '@mdi/js'
import classnames from 'classnames'
import moment from 'moment'

import {
  personName,
  personTitledName,
  personUrl,
  isLongPersonName,
} from '../utils'

import ProfilePhoto from './ProfilePhoto.js'
import config from '../config'
import './ProfileFeatured.scss'

export default function ProfileFeatured(props) {
  const { person, className, ...rest } = props
  const name = personName(person)
  return (
    <Col className={classnames('profile-featured', className)} {...rest}>
      <Card>
        <Card.Body>
          <Row>
            <Col xs={3} sm={2} lg={3} className="pb-1 pr-1">
              <ProfilePhoto
                person={person}
                className="profile-featured-img img-fluid"
                width={config.photos.width}
                height={config.photos.height}
              />
            </Col>
            <Col xs="auto" md={5} lg="auto" className="col-fit pl-2">
              <div className="h6 mb-0">{person.title}</div>
              <div
                className={classnames('profile-featured-name', {
                  'profile-featured-name-long': isLongPersonName(name),
                })}
              >
                {name}
              </div>
              {person.updated && (
                <div className="small text-muted mb-2">
                  Updated {moment(person.updated).fromNow()}
                </div>
              )}
            </Col>
            {person.settings.byline && (
              <Col xs={12} md="auto" lg={12} className="col-fit mb-2">
                {person.settings.byline}
              </Col>
            )}
          </Row>
        </Card.Body>
        <Card.Footer>
          <a className="stretched-link" href={personUrl(person)}>
            See profile
            <Icon path={mdiChevronRight} size={1} />
            <span className="sr-only">for {personTitledName(person)}</span>
          </a>
        </Card.Footer>
      </Card>
    </Col>
  )
}
