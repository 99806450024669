import React, { useContext } from 'react'
import { Button } from 'react-bootstrap'
import NavbarContext from 'react-bootstrap/NavbarContext'
import Icon from '@mdi/react'
import { mdiClose, mdiMenu, mdiChevronUp, mdiChevronDown } from '@mdi/js'
import classnames from 'classnames'

import './MenuToggle.scss'

function makeCustomToggle(defaultMenuIcon, defaultCloseIcon) {
  function CustomToggle(props) {
    const { className, size, menuIcon, closeIcon, ...rest } = props
    const { expanded } = useContext(NavbarContext)
    return (
      <Button
        variant="link"
        className={classnames('menu-toggle', className)}
        {...rest}
      >
        <Icon path={!expanded ? menuIcon : closeIcon} size={size} />
      </Button>
    )
  }
  CustomToggle.defaultProps = {
    size: '2.5rem',
    menuIcon: defaultMenuIcon,
    closeIcon: defaultCloseIcon,
  }
  return CustomToggle
}

const MenuToggle = makeCustomToggle(mdiMenu, mdiClose)
MenuToggle.ChevronToggle = makeCustomToggle(mdiChevronDown, mdiChevronUp)

export default MenuToggle
