import React from 'react'
import PropTypes from 'prop-types'
import { Container, Row, Col, Button } from 'react-bootstrap'
import Icon from '@mdi/react'
import { mdiChevronRight } from '@mdi/js'

import IconLabel from './IconLabel'

import './FeaturedCategories.scss'

export default function FeaturedCategories(props) {
  const Title = props.titleAs
  return (
    <Container fluid={props.breakpoint} className="featured-categories">
      <Row>
        <Col>
          <Title className="featured-categories-title">
            <IconLabel icon={props.icon} iconMargin={2}>
              {props.title}
            </IconLabel>
          </Title>
        </Col>
      </Row>
      <Row
        className="featured-categories-items"
        xs={1}
        sm={3}
        {...{ [props.breakpoint]: 4 }}
      >
        {Object.keys(props.categories).map(link => (
          <Col key={link}>
            <Button variant="link" block size="lg" href={link}>
              {props.categories[link]}
              <Icon
                className={`d-${props.breakpoint}-none`}
                path={mdiChevronRight}
                size={1}
              />
            </Button>
          </Col>
        ))}
      </Row>
    </Container>
  )
}

FeaturedCategories.propTypes = {
  title: PropTypes.string.isRequired,
  titleAs: PropTypes.oneOfType([PropTypes.string, PropTypes.elementType])
    .isRequired,
  icon: PropTypes.string,
  categories: PropTypes.object.isRequired,
  breakpoint: PropTypes.string,
}

FeaturedCategories.defaultProps = {
  titleAs: 'h2',
  breakpoint: 'md',
}
