import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import { Helmet } from 'react-helmet'

import config from './config'

import './App.css'
import Home from './Home'
import Researchers from './Researchers'
import GroupListing from './GroupListing'
import Search from './Search'
import Explore from './Explore'
import FindAnAdvisor from './FindAnAdvisor'
import FindAnAdvisorHowTo from './FindAnAdvisorHowTo'
import Projects from './Projects'
import Profile from './Profile'
import Help from './Help'
import Login from './Login'
import About from './About'
import ContactUs from './ContactUs'
import Legal from './Legal'
import FaqUpdate from './FaqUpdate'
import NotFound from './NotFound'

import { DemoAlert, IconLabel, ScrollToTop, Header, Footer } from './components'

import researchers from './data/researchers'

import AcademicsHeroImg from './img/researchers/sueann.watson.jpg'
import StudentsHeroImg from './img/researchers/david.henderson.jpg'
import AcademyHeroImg from './img/researchers/bill.laurance.jpg'
import CentresHeroImg from './img/researchers/brendan.ebner.jpg'
import InstitutesHeroImg from './img/cairns-institute.jpg'

function App() {
  const academics = researchers.filter(person => person.type === 'academic')
  const students = researchers.filter(person => person.type === 'student')

  return (
    <Router>
      <Helmet
        titleTemplate={`%s - JCU ${config.sitename}`}
        defaultTitle={`JCU ${config.sitename}`}
      />
      <ScrollToTop />
      <div className="App">
        <DemoAlert />
        <Header />

        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route path="/search">
            <Search />
          </Route>
          <Route path="/explore">
            <Explore />
          </Route>
          <Route path="/find-an-advisor/howto">
            <FindAnAdvisorHowTo />
          </Route>
          <Route path="/find-an-advisor">
            <FindAnAdvisor />
          </Route>
          <Route path="/projects">
            <Projects />
          </Route>
          <Route path="/help">
            <Help />
          </Route>
          <Route path="/login">
            <Login />
          </Route>
          <Route path="/about">
            <About />
          </Route>
          <Route path="/contact-us">
            <ContactUs />
          </Route>
          <Route path="/legal">
            <Legal />
          </Route>
          <Route path="/faq/update">
            <FaqUpdate />
          </Route>
          <Route path="/academics">
            <GroupListing
              title="Academics"
              heading={<IconLabel.Academics size="1.25em" />}
              byline="JCU’s researchers are world-reknown leaders in their fields of expertise"
              heroImg={AcademicsHeroImg}
              heroPosition="center 25%"
              people={academics}
            />
          </Route>
          <Route path="/academy">
            <GroupListing
              title="Academy"
              byline="JCU’s academic areas are internationally recognised for their research contributions"
              heroImg={AcademyHeroImg}
              heroPosition="center 38%"
              people={academics}
            />
          </Route>
          <Route path="/institutes">
            <GroupListing
              title="Institutes"
              heading={
                <>
                  <span className="d-none d-md-inline">Research </span>
                  Institutes
                </>
              }
              byline="JCU’s institutes are at the forefront of research"
              heroImg={InstitutesHeroImg}
              heroPosition="center 55%"
              people={academics}
            />
          </Route>
          <Route path="/centres">
            <GroupListing
              title="Centres"
              heading={
                <>
                  <span className="d-none d-md-inline">Research </span>
                  Centres
                </>
              }
              byline="JCU’s research centres are leaders in engagement and professional development"
              heroImg={CentresHeroImg}
              heroPosition="center 15%"
              people={academics}
            />
          </Route>
          <Route
            path="/campus/:campus"
            render={({ match }) => {
              const campus = config.campuses[match.params.campus]
              return (
                <GroupListing
                  title={`${campus.name} Campus`}
                  byline={
                    <>
                      Researchers located on or associated with JCU’s{' '}
                      <a
                        className="link-light text-underline"
                        href={campus.websiteUrl}
                      >
                        {campus.name} campus
                      </a>
                    </>
                  }
                  heroImg={campus.image}
                  heroPosition={campus.heroPosition}
                  people={academics}
                />
              )
            }}
          />
          <Route exact path="/student">
            <GroupListing
              title="Students"
              heading={<IconLabel.Students size="1.25em" />}
              byline="Higher-Degree Research (HDR) Candidates studying at JCU"
              heroImg={StudentsHeroImg}
              heroPosition="center 40%"
              people={students}
            />
          </Route>
          <Route path="/student/:link_id">
            <Profile type="student" />
          </Route>
          <Route exact path="/portfolio">
            <Researchers />
          </Route>
          <Route path="/portfolio/:link_id">
            <Profile type="academic" />
          </Route>
          <Route component={NotFound} />
        </Switch>

        <Footer />
      </div>
    </Router>
  )
}

export default App
