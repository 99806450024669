import React, { useState, useEffect } from 'react'
import { Container, Row, Col, Button, Media, Card } from 'react-bootstrap'
import Icon from '@mdi/react'
import { mdiMapMarker } from '@mdi/js'
import RSSParser from 'rss-parser'
import 'tippy.js/dist/tippy.css'
import 'tippy.js/animations/scale.css'

import {
  CallToAction,
  CollaborationMap,
  ProfileMini,
  SearchInput,
  Spinner,
  Title,
  Wordcloud,
} from './components'
import { arrayPopulated, searchUrl } from './utils'

import './Explore.scss'
import Collage from './img/collage.jpg'
import ResearchOnlineImg from './img/researchonline.jpg'
import OrpheusIsland from './img/orpheus-island.jpg'
import DRO from './img/dro-canopy-crane.jpg'
import Fletcherview from './img/fletcherview-cattle-muster.jpg'
import ResearchCentres from './img/cstfa-reef-fisheries.jpg'
import mapMarkerFunding from './img/map-marker-funding.svg'

import researchers from './data/researchers'
import ResearchHighlightsFeed from './data/research-highlights.xml'

function ResearchEnvironment(props) {
  return (
    <Media as="li" className="position-relative align-items-center mb-3">
      <img
        className="rounded-circle mr-3"
        src={props.imageSrc}
        alt={props.imageAlt}
        loading="lazy"
      />
      <Media.Body>
        <a
          className="h2 font-weight-light mb-0 stretched-link"
          href={props.href}
        >
          {props.name}
        </a>
        <p className="lead">{props.description}</p>
      </Media.Body>
    </Media>
  )
}

async function fetchData(url, setterFn) {
  // TODO / XXX Handle error responses
  const response = await fetch(url)
  setterFn(await response.json())
}

export default function Explore() {
  const [mapData, setMapData] = useState([])
  const [newsItems, setNewsItems] = useState([])
  const [keywords, setKeywords] = useState([])
  useEffect(() => {
    fetchData('/api/collaborations.json', setMapData)
    fetchData('/api/keywords.json', setKeywords)

    async function fetchNewsData() {
      const parser = new RSSParser({
        customFields: {
          item: [
            'jcu:articleImage',
            'jcu:articleImageThumbnail',
            'jcu:expert_email',
          ],
        },
      })
      const feed = await parser.parseURL(ResearchHighlightsFeed)
      setNewsItems(feed.items)
    }
    fetchNewsData()
  }, [])

  return (
    <>
      <Title page="Explore" />
      <div className="explore-map">
        <div className="explore-map-header">
          <Container fluid="md">
            <Row>
              <Col md>
                <SearchInput placeholder="Find by topic, region, country…" />
              </Col>
              <Col md className="text-md-right">
                <h1 className="mb-0">Explore research at JCU</h1>
              </Col>
            </Row>
          </Container>
        </div>
        <CollaborationMap data={mapData} />
        <div className="explore-map-footer">
          <Container fluid="md">
            <Row>
              <Col className="d-none">
                <div className="h5">
                  <Icon
                    path={mdiMapMarker}
                    title="Selected location"
                    size="2rem"
                  />
                  Australia: 843 research collaborations
                </div>
              </Col>
              <Col className="text-md-right small">
                Locations are indicative of collaborations
              </Col>
            </Row>
            <Row className="d-none">
              <Col className="d-flex align-items-top justify-content-between">
                {researchers.slice(0, 3).map(person => (
                  <ProfileMini key={person.link_id} person={person} />
                ))}{' '}
                <Button
                  variant="outline-light"
                  className="rounded align-self-center"
                >
                  See more
                </Button>
              </Col>
            </Row>
          </Container>
        </div>
      </div>

      <Container fluid="md" className="mb-3">
        <Row>
          <Col className="text-center my-2">
            <p className="lead">
              JCU collaborates with institutions and Universities across the
              world. <br className="d-none d-md-inline" />
              Discover our research and the researchers at the forefront of
              their fields.
            </p>
          </Col>
        </Row>
      </Container>

      <CallToAction
        title="Want to collaborate with JCU’s researchers?"
        backgroundImage={Collage}
        action="Find an Expert"
        actionLink="/researchers"
        className="mb-5"
      />

      <Container fluid="md" className="mb-5">
        <Row className="mb-2">
          <Col className="text-center">
            <h2 className="display-4 mb-0">Research highlights</h2>
            <p className="lead">
              Latest stories behind JCU’s world-class research
            </p>
          </Col>
        </Row>
        <Row xs={1} lg={3}>
          {newsItems.length &&
            newsItems.slice(0, 6).map(item => {
              const person = researchers.find(
                p => p.link_id === item['jcu:expert_email'].split('@')[0]
              )
              return (
                <Col key={item.guid} className="mb-3">
                  <Card className="card-news">
                    <div className="card-news-header">
                      <Card.Img
                        variant="top"
                        src={item['jcu:articleImageThumbnail']}
                        loading="lazy"
                      />
                      <Card.Title
                        as="a"
                        className="h3 stretched-link"
                        href={item.link}
                      >
                        {item.title}
                      </Card.Title>
                    </div>
                    <Card.Footer>
                      {person ? (
                        <ProfileMini
                          person={person}
                          showOrcidId={false}
                          stretchedLink
                        />
                      ) : (
                        'Read story'
                      )}
                    </Card.Footer>
                  </Card>
                </Col>
              )
            })}
        </Row>
        <Row className="justify-content-center">
          <Col xs="auto">
            <Button variant="outline-primary" className="rounded mb-1">
              Read more at This is Uni…
            </Button>
          </Col>
        </Row>
      </Container>

      <Container fluid="md" className="mb-5">
        <Row className="mb-2">
          <Col className="text-center">
            <h2 className="display-4 mb-0">Explore research areas</h2>
            <p className="lead">
              Discover expertise in different topics and dive into JCU’s top
              research fields
            </p>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col className="text-center">
            {arrayPopulated(keywords) ? (
              <Wordcloud
                className="explore-wordcloud"
                style={null}
                words={keywords.map(keyword => ({
                  text: keyword.keyword,
                  value: keyword.total,
                }))}
                options={{
                  deterministic: true,
                  randomSeed: 'floris',
                  enableTooltip: true,
                  tooltipOptions: {
                    allowHTML: true,
                    duration: 100,
                    popperOptions: {
                      modifiers: [
                        { name: 'offset', options: { offset: [0, 2] } },
                      ],
                    },
                  },
                  enableOptimizations: true,
                  rotations: 1,
                  rotationAngles: [0, 0],
                  transitionDuration: 0,
                  padding: 3,
                  fontFamily: 'Open Sans, sans-serif',
                  fontWeight: '400',
                  fontSizes: [14, 64],
                  colors: [
                    'rgb(0, 102, 179)',
                    '#008a00',
                    '#947200',
                    'rgb(218, 41, 28)',
                    '#53565a',
                    'rgb(153, 30, 102)',
                  ],
                }}
                callbacks={{
                  onWordClick: word => (window.location = searchUrl(word.text)),
                  getWordTooltip: word =>
                    `<div class="text-center">Find researchers associated with<br><strong>${word.text}</strong></div>`,
                }}
              />
            ) : (
              <Spinner />
            )}
          </Col>
        </Row>
      </Container>

      <Container fluid="md" className="mb-5">
        <Row className="mb-2">
          <Col className="text-center">
            <h2 className="display-4 mb-0">Go further</h2>
            <p className="lead">
              Explore James Cook University’s research environments
            </p>
          </Col>
        </Row>
        <Row className="explore-go-further justify-content-center">
          <Col xs="auto">
            <ul className="list-unstyled">
              <ResearchEnvironment
                name="Research Centres and Institutes"
                description="Discover the groups behind JCU’s world-class research"
                href="https://www.jcu.edu.au/research/centres-and-institutes"
                imageSrc={ResearchCentres}
                imageAlt="Aerial view of Pacific Reef Fisheries at Alva Beach, Queensland"
              />
              <ResearchEnvironment
                name="Research Data at JCU"
                description="Cataloguing all data associated with research at James Cook University"
                href="https://researchdata.ands.org.au/search/#!/rows=15/sort=list_title_sort%20asc/class=collection/q=/group=James%20Cook%20University/p=1/advanced=true/"
                imageSrc={Collage}
                imageAlt="Collage of researcher faces representing data at JCU"
              />
              <ResearchEnvironment
                name="ResearchOnline@JCU"
                description="Growing institutional collection of publications and creative works"
                href="https://researchonline.jcu.edu.au"
                imageSrc={ResearchOnlineImg}
                imageAlt="ResearchOnline colourful banner"
              />
              <ResearchEnvironment
                name="Recent Research Funding"
                description="Recently funded projects by month and funding source"
                href="https://www.jcu.edu.au/research/i-want-to/grants/recent-research-funding"
                imageSrc={mapMarkerFunding}
                imageAlt="Map marker with dollar  sign"
              />
              <ResearchEnvironment
                name="Orpheus Island Research Station"
                description="Ready access to the Great Barrier Reef from a pristine island"
                href="https://www.jcu.edu.au/orpheus-island"
                imageSrc={OrpheusIsland}
                alt="Aerial view of Orpheus Island"
              />
              <ResearchEnvironment
                name="Daintree Rainforest Observatory"
                description="Eco-monitoring site in World Heritage-listed Queensland rainforest"
                href="https://www.jcu.edu.au/daintree"
                imageSrc={DRO}
                imageAlt="Daintree Rainforest Observatory canopy crane"
              />
              <ResearchEnvironment
                name="Fletcherview Research Station"
                description="Working cattle property near Townsville, Queensland"
                href="https://www.jcu.edu.au/fletcherview"
                imageSrc={Fletcherview}
                imageAlt="Cattle mustering at Fletcherview Research Station"
              />
            </ul>
          </Col>
        </Row>
      </Container>
    </>
  )
}
