import React from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap'

import { Title, Intro, Breadcrumbs, Steps, CallToAction } from './components'
import config from './config'

import HeroImg from './img/researchers/margaret.jordan.jpg'
import CallToActionImg from './img/advisor.jpg'

export default function About() {
  return (
    <div>
      <Title page="How to find an Advisor" />

      <Breadcrumbs
        hierarchy={[
          { url: '/find-an-advisor', title: 'Find an Advisor' },
          { title: 'How to' },
        ]}
      />
      <Intro
        backgroundImage={HeroImg}
        backgroundPosition="center 35%"
        heading="How to find an Advisor"
      >
        To apply for Higher Degree by Research (HDR) at JCU, you’ll need a
        topic, project and Advisory Panel. This is how to find them.
      </Intro>

      <Container fluid="lg" className="py-3 py-lg-4">
        <Row className="justify-content-center">
          <Col>
            <p className="lead mb-4">
              We’re here to help you find a research supervisor. At any point
              you can{' '}
              <a href={config.urls.grsContact}>
                contact the JCU Graduate Research School
              </a>{' '}
              for advice.
            </p>
            <Steps>
              <Steps.Step>
                <h2>Find a topic and project</h2>
                <p>
                  JCU offers diverse, cutting-edge research projects available
                  to prospective HDR candidates. Here on the JCU Research
                  Portfolio, you can explore the{' '}
                  <a href="/projects">available Research Projects</a> and browse
                  the topics that <a href="/student">research students</a> are
                  working on. You can also <a href="/search">search</a> on
                  keywords such as topic, research discipline and interests,
                  researcher names and more.
                </p>
                <p>
                  You can also browse JCU researchers by{' '}
                  <a href="/portfolio/academy">Academic Group</a>,{' '}
                  <a href="/portfolio/centres">Centre</a> and{' '}
                  <a href="/portfolio/institutes">Institute</a> to find the
                  right research supervisor and team for you.
                </p>
              </Steps.Step>
              <Steps.Step>
                <h2>Make contact</h2>
                <p>
                  Make contact with your preferred Advisors to confirm their
                  availability and discuss them taking on the role. You can{' '}
                  <a href="/search">search</a> for someone to find their
                  details. The JCU Research Portfolio provides a{' '}
                  <Button
                    variant="outline-primary"
                    size="sm"
                    href="#"
                    className="rounded mx-1"
                  >
                    Contact
                  </Button>{' '}
                  button on all Advisor profiles to get email addresses, phone
                  numbers and see other contact points.
                </p>
              </Steps.Step>
              <Steps.Step>
                <h2>Apply to JCU</h2>
                <p>
                  Follow the application process through the Graduate Research
                  School’s{' '}
                  <a href={config.urls.grsHowToApply}>How to Apply page</a>.
                  Welcome to JCU – you’re about to become our newest researcher!
                </p>
                <p>
                  The great news is that once you’ve been accepted as a JCU
                  research student, you’ll get your very own JCU Research
                  Portfolio page, just like these{' '}
                  <a href="/student">current research candidates</a>.
                </p>
              </Steps.Step>
            </Steps>
          </Col>
        </Row>
      </Container>

      <CallToAction
        title="Need more help finding an Advisor?"
        action="Talk to JCU"
        actionLink={config.urls.grsContact}
        backgroundImage={CallToActionImg}
      />
    </div>
  )
}
