import React from 'react'
import { Container, Row, Col, Nav, Navbar, NavDropdown } from 'react-bootstrap'

import {
  CallToActionCollaboration,
  IconLabel,
  Intro,
  TitledToggle,
  NavDropdownHover,
  PersonCards,
  RouterNavLink,
  Title,
} from './components'

import config from './config'

import HeroImg from './img/researchers/martin.nakata.jpg'

import researchers from './data/researchers'

export default function Researchers() {
  const types = [
    ['academic', 'Researchers'],
    ['student', 'Higher-Degree Research Students'],
  ]
  return (
    <>
      <Title page="Researchers" />

      <Intro
        backgroundImage={HeroImg}
        backgroundPosition="center 30%"
        heading="Researchers"
        headingLarge="Researchers at JCU"
      >
        Expertise in all areas, including marine biology, medicine and
        indigenous studies
      </Intro>

      <Navbar expand="sm" className="navbar-secondary bg-light p-0">
        <Container fluid="lg" className="px-sm-0">
          <Navbar.Toggle
            as={TitledToggle}
            title="Browse researchers"
            aria-controls="navbar"
          />
          <Navbar.Collapse className="px-2 px-sm-0">
            <Nav className="align-items-sm-center text-sm-center">
              <RouterNavLink to="/portfolio/" exact>
                All researchers
              </RouterNavLink>
              <RouterNavLink to="/academics">
                <IconLabel.Academics />
              </RouterNavLink>
              <RouterNavLink to="/student">
                <IconLabel.Students />
              </RouterNavLink>
              <NavDropdownHover title="By group" id="by-group-dropdown">
                <NavDropdown.Item href="/academy">
                  Academic Group
                </NavDropdown.Item>
                <NavDropdown.Item href="/centres">
                  Research Centres
                </NavDropdown.Item>
                <NavDropdown.Item href="/institutes">
                  Research Institutes
                </NavDropdown.Item>
              </NavDropdownHover>
              <NavDropdownHover title="By location" id="by-group-dropdown">
                {Object.keys(config.campuses).map(campusId => (
                  <NavDropdown.Item key={campusId} href={`/campus/${campusId}`}>
                    {config.campuses[campusId].name}
                  </NavDropdown.Item>
                ))}
              </NavDropdownHover>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      {types.map(type => (
        <Container key={type[0]} fluid="lg" className="my-4">
          <Row>
            <Col>
              <h2>Featured {type[1]}</h2>
            </Col>
          </Row>
          <PersonCards
            people={researchers.filter(person => person.type === type[0])}
          />
        </Container>
      ))}

      <CallToActionCollaboration />
    </>
  )
}
