import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'

import { Title, Intro, CallToAction, PersonCards } from './components'
import config from './config'

import researchers from './data/researchers'

import HeroImg from './img/townsville-campus-aerial.jpg'

export default function NotFound() {
  return (
    <div>
      <Title page="Page Not Found" />

      <Intro backgroundImage={HeroImg} heading="Page not found – 404">
        Sorry, we couldn't find the page you were looking for.
      </Intro>

      <Container fluid="lg" className="py-3 py-lg-5">
        <Row className="justify-content-center">
          <Col>
            <p className="lead mb-4">
              You should check the URL you have come to in case of typing
              mistakes. You can try{' '}
              <a href="/search">searching the Research Portfolio</a> to find
              someone.
            </p>
            <div className="mb-4">
              <p>
                If you are sure a profile or page should exist at this URL,
                there may be a reason why it isn't here:
              </p>
              <ul>
                <li>
                  The person isn't an active researcher. You can try searching
                  the <a href={config.urls.contacts}>contacts listing</a> to
                  search for all staff,
                </li>
                <li>
                  The researcher is no longer at James Cook University and we
                  don't have another URL or ORCID iD to link you to,
                </li>
                <li>The profile was removed for another reason, or</li>
                <li>
                  The page was previously part of an older version of the
                  Research Portfolio or Research@JCU.
                </li>
              </ul>
              <p>
                If you have further questions, please{' '}
                <a href="/contact-us">contact us</a>.
              </p>
            </div>
            <div className="h3">Have you met these researchers?</div>
            <Container fluid="lg">
              <PersonCards people={researchers.slice(0, 9)} />
            </Container>
          </Col>
        </Row>
      </Container>

      <CallToAction.General />
    </div>
  )
}
