import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'

import config from '../config'
import Logo from './Logo'
import BackToTopLink from './BackToTopLink'

import './Footer.scss'

// Implement a similar footer to that of JCU's 2020 Homepage
// Key differences are larger text (readability), larger partner logos
// (readability, interaction), blue links (WCAG 2.0 AA, see
// https://webaim.org/blog/wcag-2-0-and-link-colors/) and a contextualised
// set of relevant links to this site's content.

export default function Footer() {
  const backToTopLink = <BackToTopLink className="link-light" />
  return (
    <footer>
      <div className="py-3 bg-dark" id="footer-app">
        <Container fluid="md">
          <Row
            noGutters
            className="align-items-center justify-content-between pb-3 border-bottom mb-3"
          >
            <Col xs="auto">
              <Logo
                variant="horizontal-mono-rev"
                style={{ height: '3rem' }}
                sitename={config.sitename}
              />
            </Col>
            <Col xs="auto" className="text-light">
              v{config.version}
            </Col>
            <Col xs="auto" className="d-none d-sm-block">
              {backToTopLink}
            </Col>
          </Row>
          <Row xs={1} sm={3} lg={4} className="footer-cols">
            <Col>
              <ul className="list-unstyled">
                <li>
                  <a className="link-light" href="/about">
                    About{' '}
                    <span className="d-none d-md-inline">the Portfolio</span>
                  </a>
                </li>
                <li>
                  <a className="link-light" href="/portfolio">
                    Researchers
                  </a>
                </li>
                <li>
                  <a className="link-light" href="/explore">
                    Explore
                  </a>
                </li>
                <li>
                  <a className="link-light" href="/find-an-advisor">
                    Find an Advisor
                  </a>
                </li>
              </ul>
            </Col>
            <Col>
              <ul className="list-unstyled">
                <li>
                  <a className="link-light" href="/projects">
                    Join a Project
                  </a>
                </li>
                <li>
                  <a className="link-light" href="/projects">
                    Industry Partnerships
                  </a>
                </li>
                <li>
                  <a className="link-light" href={config.urls.media}>
                    Media Enquiries
                  </a>
                </li>
                <li>
                  <a className="link-light" href="/contact-us">
                    Contact Us
                  </a>
                </li>
              </ul>
            </Col>
            <Col>
              <ul className="list-unstyled">
                <li>
                  <a className="link-light" href="/legal">
                    Legal
                  </a>
                </li>
                <li>
                  <a className="link-light" href="/help">
                    Help &amp; Support
                  </a>
                </li>
                <li>
                  <a className="link-light" href="/faq/update">
                    FAQs
                  </a>
                </li>
                <li>
                  <a className="link-light" href="/login">
                    Login
                  </a>
                </li>
              </ul>
            </Col>
            {/* Hidden on small devices & above; "moves" to right of logo */}
            <Col className="d-block d-sm-none">
              <ul className="list-unstyled mb-0">
                <li>{backToTopLink}</li>
              </ul>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="py-3 bg-light text-dark" id="footer-global">
        <Container fluid="md">
          <Row xs={1} lg={2} noGutters className="footer-partners mx-0 mb-3">
            <Col>
              <Row xs={2} sm={4} className="align-items-center mb-4 mb-sm-3">
                {/* Restrict maximum logo size for Australia Future Unlimited only */}
                <Col md={2} className="mb-1">
                  <a href="https://www.australiaunlimited.com">
                    <img
                      className="img-fluid"
                      src={`${config.cdn}/img/partners/australia-future-unlimited.svg`}
                      alt="Australia Unlimited logo"
                      loading="lazy"
                    />
                  </a>
                </Col>
                <Col className="mb-1">
                  <a href="https://www.iru.edu.au">
                    <img
                      className="img-fluid"
                      src={`${config.cdn}/img/partners/innovative-research-universities.svg`}
                      alt="Innovative Research Universities logo"
                      loading="lazy"
                    />
                  </a>
                </Col>
                <Col className="mb-1">
                  <a href="https://www.universitiesaustralia.edu.au">
                    <img
                      className="img-fluid"
                      src={`${config.cdn}/img/partners/universities-australia-2018-blue.svg`}
                      alt="Universities Australia logo"
                      loading="lazy"
                    />
                  </a>
                </Col>
                <Col className="mb-1">
                  <a href="https://stateofthetropics.org">
                    <img
                      className="img-fluid"
                      src={`${config.cdn}/img/partners/state-of-the-tropics.svg`}
                      alt="State of the Tropics logo"
                      loading="lazy"
                    />
                  </a>
                </Col>
              </Row>
            </Col>
            <Col>
              <Row className="align-items-top mb-3">
                <Col xs={12} sm="auto" className="mb-2 mb-sm-0">
                  <img
                    className="img-flag"
                    src={`${config.cdn}/img/artwork/australian-aboriginal-flag.svg`}
                    alt="Australian Aboriginal flag"
                    loading="lazy"
                  />{' '}
                  <img
                    className="img-flag"
                    src={`${config.cdn}/img/artwork/torres-strait-islanders-flag.svg`}
                    alt="Torres Strait Islander flag"
                    loading="lazy"
                  />
                </Col>
                <Col className="pl-sm-0 small">
                  We acknowledge Australian Aboriginal People and Torres Strait
                  Islander People as the first inhabitants of the nation, and
                  acknowledge Traditional Owners of the lands where our staff
                  and students live, learn and work.
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className="justify-content-center text-center small">
            <Col xs={12} lg="auto" className="mr-lg-auto">
              <ul className="list-unstyled list-business-info">
                <li>
                  <abbr title="Copyright">©</abbr> 1995 to{' '}
                  {new Date().getFullYear()} James Cook University
                </li>
                <li>
                  <a href="https://www.jcu.edu.au/cricos">
                    <abbr title="Commonwealth Register of Institutions and Courses for Overseas Students">
                      CRICOS
                    </abbr>{' '}
                    Provider Code 00117J
                  </a>
                </li>
                <li>
                  <abbr title="Australian Business Number">ABN</abbr>{' '}
                  46253211955
                </li>
              </ul>
            </Col>
            <Col xs={12} lg="auto">
              <ul className="list-inline list-inline-bordered">
                <li className="list-inline-item">
                  <a href="https://www.jcu.edu.au/terms-of-use">Terms of use</a>
                </li>
                <li className="list-inline-item">
                  <a href="https://www.jcu.edu.au/privacy">Privacy</a>
                </li>
                <li className="list-inline-item">
                  <a href="https://www.jcu.edu.au/disclaimer">Disclaimer</a>
                </li>
              </ul>
            </Col>
          </Row>
        </Container>
      </div>
    </footer>
  )
}
