import React from 'react'
import { Col, Card, ListGroup, ListGroupItem } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Icon from '@mdi/react'
import { mdiCheckBold } from '@mdi/js'

import ProfileMini from './ProfileMini'

import { getLocalResearcher } from '../utils'

import './ProjectCard.scss'

export default function ProjectCard(props) {
  const project = props.project
  const checkIcon = <Icon path={mdiCheckBold} size={1} />
  return (
    <Col className="mb-3">
      <Card className="project-card">
        <div className="project-card-header">
          <Card.Img variant="top" src={project.image} />
          <Card.Title as="a" href={project.url}>
            {project.title}
          </Card.Title>
        </div>
        <Card.Body>
          <ul className="list-unstyled">
            {project.audience.map(audience => (
              <li key={audience}>
                {checkIcon} {audience}
              </li>
            ))}
          </ul>
        </Card.Body>
        <Card.Footer className="bg-white">
          <ProfileMini
            person={getLocalResearcher(project.researcher.link_id)}
          />
        </Card.Footer>
        <Card.Footer as="a" href={project.url}>
          Learn more
        </Card.Footer>
      </Card>
    </Col>
  )
}
