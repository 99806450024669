import React from 'react'
import PropTypes from 'prop-types'
import { Media, OverlayTrigger, Tooltip } from 'react-bootstrap'
import { mdiEmail, mdiMapMarker, mdiPhone, mdiLink } from '@mdi/js'
import Icon from '@mdi/react'
import classnames from 'classnames'

import { personShortUrl, arrayPopulated } from '../utils'

import config from '../config'

import './ContactCard.scss'

export default function ContactCard(props) {
  const { person, className, children, ...rest } = props

  return (
    <div className={classnames('contact-card', className)} {...rest}>
      <ContactCard.Email email={person.email} />
      <ContactCard.Phone phoneNumbers={person.telephone} />
      {arrayPopulated(person.locations) ? (
        <ContactCard.Location locations={person.locations} />
      ) : (
        person.campus && (
          <ContactCard.Location>{person.campus} campus</ContactCard.Location>
        )
      )}
      {children}
    </div>
  )
}

ContactCard.propTypes = {
  person: PropTypes.object.isRequired,
  className: PropTypes.string,
  children: PropTypes.any,
}

function ContactCardEntry(props) {
  return (
    <Media
      className={classnames(
        'contact-card-entry',
        'align-items-center',
        props.className
      )}
    >
      <Icon
        path={props.icon}
        className="contact-card-entry-icon"
        title={props.title}
      />
      <Media.Body
        className={classnames({
          'text-truncate': props.truncate,
          'text-wrap': props.wrap,
        })}
      >
        {props.children}
      </Media.Body>
    </Media>
  )
}
ContactCard.Entry = ContactCardEntry

ContactCardEntry.propTypes = {
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
  children: PropTypes.any.isRequired,
  truncate: PropTypes.bool,
}

function ContactCardEmail(props) {
  // Add U+200B Zero-Width Space to dot or @ symbol for wrapping
  return (
    <ContactCardEntry icon={mdiEmail} title="Email" truncate>
      <a href={`mailto:${props.email}`}>
        {props.email.replace(/([.@])/g, '​$1')}
      </a>
    </ContactCardEntry>
  )
}
ContactCard.Email = ContactCardEmail

ContactCardEmail.propTypes = {
  email: PropTypes.string.isRequired,
}

function PhoneNumber(props) {
  const phone = props.phone
  const url = `tel:${phone}`
  let formatted, international, tooltip

  if (phone.startsWith('+61747')) {
    tooltip = 'Townsville, Australia'
    formatted = phone.replace('+6174781', '(07) 4781 ')
    international = phone.replace('+6174781', '+61 7 4781 ')
  } else if (phone.startsWith('+6174232')) {
    tooltip = 'Cairns, Australia'
    formatted = phone.replace('+6174232', '(07) 4232 ')
    international = phone.replace('+6174232', '+61 7 4232 ')
  } else if (phone.startsWith('+65')) {
    tooltip = 'Singapore'
    formatted = ['+65', phone.slice(2, 6), phone.slice(6, 10)].join(' ')
  } else if (phone.startsWith('+614')) {
    tooltip = 'Mobile phone'
    formatted = [
      '+61',
      phone.slice(1, 4),
      phone.slice(4, 7),
      phone.slice(7, 10),
    ].join(' ')
  }

  return (
    <>
      <OverlayTrigger placement="bottom" overlay={<Tooltip>{tooltip}</Tooltip>}>
        <a className="text-nowrap" href={url}>
          <span className="sr-only">Local call </span>
          {formatted}
        </a>
      </OverlayTrigger>
      {international && (
        <>
          {' '}
          <span className="ml-1 small">
            (
            <a className="text-nowrap" href={url}>
              <span className="sr-only">Call </span>
              {international}
            </a>
            )
          </span>
        </>
      )}
    </>
  )
}

PhoneNumber.propTypes = {
  phone: PropTypes.string.isRequired,
}

function ContactCardPhone(props) {
  // Hide if we have nothing to show
  if (!props.children && !arrayPopulated(props.phoneNumbers)) return null

  return (
    <ContactCardEntry icon={mdiPhone} title="Telephone" truncate wrap>
      {props.children ||
        (props.phoneNumbers.length === 1 && (
          <PhoneNumber phone={props.phoneNumbers[0]} />
        )) || (
          <ul className="list-unstyled mb-0">
            {props.phoneNumbers.map(phone => (
              <li key={phone}>
                <PhoneNumber phone={phone} />
              </li>
            ))}
          </ul>
        )}
    </ContactCardEntry>
  )
}
ContactCard.Phone = ContactCardPhone

ContactCardPhone.propTypes = {
  phoneNumbers: PropTypes.array,
  children: PropTypes.any,
}

function Location(props) {
  const location = props.location
  return (
    <>
      <a
        href={config.campuses[
          location.building.campus.toLowerCase()
        ].mapUrl.replace('{location}', location.id)}
      >
        {location.id}
        <span className="text-body">
          {location.building.name !== location.building.code && (
            <>
              {' '}
              – <em>{location.building.name}</em>
            </>
          )}{' '}
          ({location.building.campus} campus)
        </span>
      </a>
    </>
  )
}

Location.propTypes = {
  location: PropTypes.object.isRequired,
}

function ContactCardLocation(props) {
  // Hide if we have nothing to show
  if (!props.children && !arrayPopulated(props.locations)) return null

  return (
    <ContactCardEntry icon={mdiMapMarker} title="Location">
      {props.children ||
        (props.locations.length === 1 && (
          <Location location={props.locations[0]} />
        )) || (
          <ul className="list-unstyled mb-0">
            {props.locations.map(location => (
              <li key={location.id}>
                <Location location={location} />
              </li>
            ))}
          </ul>
        )}
    </ContactCardEntry>
  )
}
ContactCard.Location = ContactCardLocation

ContactCardLocation.propTypes = {
  locations: PropTypes.array,
  children: PropTypes.any,
}

function ContactCardUrl(props) {
  return (
    <ContactCardEntry icon={mdiLink} title="URL Link" truncate wrap>
      <a href={props.href}>{props.children}</a>
    </ContactCardEntry>
  )
}
ContactCard.Url = ContactCardUrl

ContactCardUrl.propTypes = {
  href: PropTypes.string.isRequired,
  children: PropTypes.any.isRequired,
}

function ContactCardPersonUrl(props) {
  // Add U+200B Zero-Width Space to dot or slash for wrapping
  return (
    <ContactCardUrl href={personShortUrl(props.person)}>
      {personShortUrl(props.person)
        .replace('https://', '')
        .replace(/([./])/g, '​$1')}
    </ContactCardUrl>
  )
}
ContactCard.PersonUrl = ContactCardPersonUrl

ContactCardPersonUrl.propTypes = {
  person: PropTypes.object.isRequired,
}
