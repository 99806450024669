import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useMedia } from 'react-use'
import classnames from 'classnames'

import './ReadMore.scss'

export default function ReadMore(props) {
  const { id, className, lines, linesMd, children, ...rest } = props
  const isMd = useMedia('(min-width: 768px)')
  const [heightLimit, setHeightLimit] = useState(null)
  const readMoreContentRef = useRef(null)

  useEffect(() => {
    if (readMoreContentRef && readMoreContentRef.current) {
      const lineCount = isMd ? linesMd : lines
      const limit =
        lineCount *
        parseFloat(getComputedStyle(readMoreContentRef.current).lineHeight)
      if (readMoreContentRef.current.clientHeight > limit) {
        setHeightLimit(limit)
      }
    }
  }, [lines, linesMd, isMd])

  return (
    <div className={classnames('read-more', className)} {...rest}>
      {heightLimit && (
        <input
          type="checkbox"
          className="read-more-checkbox"
          id={`readMore-${id}`}
        />
      )}
      <div
        ref={readMoreContentRef}
        className="read-more-content"
        style={{ maxHeight: heightLimit }}
        dangerouslySetInnerHTML={{
          __html: children,
        }}
      ></div>
      {heightLimit && (
        <label className="read-more-label" htmlFor={`readMore-${id}`}></label>
      )}
    </div>
  )
}

ReadMore.propTypes = {
  height: PropTypes.string,
  children: PropTypes.any.isRequired,
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  style: PropTypes.object,
}

// TODO https://caniuse.com/#feat=mdn-css_types_length_lh when available
ReadMore.defaultProps = {
  lines: 20,
  linesMd: 10,
}
