import React from 'react'
import PropTypes from 'prop-types'

import config from '../config'

export default function ResponsiveValue(props) {
  // Display full value on xs and above endBreakpoint screens, and compacted value otherwise
  return (
    <>
      <span
        className={`d-none d-${props.startBreakpoint}-inline d-${props.endBreakpoint}-none`}
      >
        {props.short}
      </span>
      <span
        className={`d-${props.startBreakpoint}-none d-${props.endBreakpoint}-inline`}
      >
        {props.long}
      </span>
    </>
  )
}

ResponsiveValue.defaultProps = {
  startBreakpoint: 'sm',
  endBreakpoint: 'lg',
}

ResponsiveValue.propTypes = {
  short: PropTypes.any.isRequired,
  long: PropTypes.any.isRequired,
  startBreakpoint: PropTypes.oneOf(['sm', 'md', 'lg']).isRequired,
  endBreakpoint: PropTypes.oneOf(['sm', 'md', 'lg', 'xl']).isRequired,
}

function ResponsiveNumber(props) {
  return (
    <ResponsiveValue
      short={config.format.compactNumber(props.value)}
      long={config.format.number(props.value)}
    />
  )
}

ResponsiveNumber.propTypes = {
  value: PropTypes.number.isRequired,
}

ResponsiveValue.Number = ResponsiveNumber
