import React, { useState, useEffect, useRef } from 'react'
import { Link, useRouteMatch } from 'react-router-dom'
import {
  Container,
  Row,
  Col,
  Button,
  Navbar,
  Nav,
  Dropdown,
  Media,
} from 'react-bootstrap'
import Scrollspy from 'react-scrollspy'
import Icon from '@mdi/react'
import {
  mdiAccountCircle,
  mdiAccountSupervisor,
  mdiForum,
  mdiBookOpenVariant,
  mdiEarth,
  mdiLightbulbOn,
  mdiClipboardText,
  mdiStarCircle,
  mdiCertificate,
  mdiBookVariant,
  mdiCurrencyUsdCircleOutline,
  mdiDotsHorizontal,
} from '@mdi/js'
import classnames from 'classnames'

import * as utils from './utils'

import {
  CollaborationMap,
  ContactCard,
  Debug,
  IconLabel,
  MediaEnquiriesModal,
  PersonCards,
  ProfileContactModal,
  ProfileIdentifier,
  ProfileFunding,
  ProfileMini,
  ProfileOutputs,
  ProfilePhoto,
  ProfileSectionHeading,
  ProfileShowcase,
  ProfileSupervision,
  ProfileTeaching,
  ProfileTypes,
  ReadMore,
  Spinner,
  Timeline,
  Title,
} from './components'

import './Profile.scss'

function sortAwards(a, b) {
  // Sort by start year, desc then by end_year desc (nulls first)
  const a_end_year = a.end_year || null
  const b_end_year = b.end_year || null

  if (a.start_year > b.start_year) return -1
  if (a.start_year < b.start_year) return 1
  if (a.start_year === b.start_year) {
    if (a_end_year > b_end_year) return -1
    if (a_end_year < b_end_year) return 1
  }

  return 0
}

function ProfileSection(props) {
  return (
    <div
      id={props.id}
      className={classnames('profile-section', props.className)}
    >
      <Row>
        <Col>
          <ProfileSectionHeading
            heading={props.name}
            description={props.description}
            icon={props.icon}
          />
        </Col>
      </Row>
      <Row>
        <Col>{props.children}</Col>
      </Row>
    </div>
  )
}

function ProfileSubsection(props) {
  return (
    <div
      id={props.id}
      className={classnames('profile-subsection', props.className)}
    >
      <Row>
        <Col lg={3} xl={2}>
          <h3 className="section-subheading">{props.name}</h3>
        </Col>
        <Col className="profile-subsection-content">{props.children}</Col>
      </Row>
    </div>
  )
}

function Profile(props) {
  const [isLoaded, setIsLoaded] = useState(false)
  const [person, setPerson] = useState({})
  const [showContactModal, setShowContactModal] = useState(false)
  const [showMediaEnquiriesModal, setShowMediaEnquiriesModal] = useState(false)
  const [viewingProfileContent, setViewingProfileContent] = useState(false)
  const [viewingProfileContact, setViewingProfileContact] = useState(false)
  const navbarProfileCollapseRef = useRef(null)
  const sectionScrollspy = useRef(null)
  const match = useRouteMatch()

  const sections = [{ id: 'overview', name: 'Overview' }].concat(
    [
      { id: 'projects', name: 'Projects' },
      { id: 'experience', name: 'Experience' },
      { id: 'achievements', name: 'Achievements' },
      { id: 'outputs', name: 'Outputs' },
      { id: 'funding', name: 'Funding' },
      { id: 'collaboration', name: 'Collaboration' },
      { id: 'supervision', name: 'Supervision' },
      { id: 'teaching', name: 'Teaching' },
    ].filter(section => utils.arrayPopulated(person[section.id]))
  )

  useEffect(() => {
    async function fetchData() {
      // XXX Need to handle errors etc
      const response = await fetch(
        `${utils.personUrl({
          type: props.type,
          link_id: match.params.link_id,
        })}/json`
      )
      let databasePerson
      try {
        databasePerson = await response.json()
      } catch (error) {
        databasePerson = {
          outputs: [],
          email: 'demo@jcu.edu.au',
        }
      }
      const localPerson = utils.getLocalResearcher(match.params.link_id)
      const settings = { ...databasePerson.settings, ...localPerson.settings }
      delete databasePerson.settings
      delete localPerson.settings
      const person = {
        ...databasePerson,
        ...localPerson,
        settings,
        ...{ skills: utils.getRandomSkills(12) },
        //...{
        //preferred_name: 'Shaymaviswanathan',
        //surname: 'Phumrattanaprapin',
        //email:
        //'fakhitahalbahrienahbinteanwar.fakhitahalbahrienahbinteanwar@my.jcu.edu.au',
        //},
      }
      setPerson(person)
      setIsLoaded(true)
      // TODO Backport and map old hash links from old site

      // Force browser to scroll to newly loaded fragment from URL
      if (window.location.hash) window.location.hash = window.location.hash //eslint-disable-line no-self-assign
    }

    fetchData()
  }, [props.type, match.params.link_id])

  if (!isLoaded) {
    return (
      <Container>
        <Row className="my-5">
          <Col className="py-5 text-center">
            <Spinner />
          </Col>
        </Row>
      </Container>
    )
  }

  const handleNavigatorScrollUpdate = newElement => {
    // Expand navbar to full width mid-profile (eg when no element visible OR the end is visible)
    setViewingProfileContent(!newElement || newElement.id === 'contact')

    // Hide ProfileMini to avoid duplicating data on screen (eg when only contact is visible)
    setViewingProfileContact(newElement && newElement.id === 'contact')
  }
  const handleProfileSectionScrollUpdate = newElement => {
    // TODO handle scroll update on viewport resize
    if (
      newElement &&
      navbarProfileCollapseRef &&
      navbarProfileCollapseRef.current
    ) {
      const collapse = navbarProfileCollapseRef.current
      const activeLink = document.querySelector(`[href="#${newElement.id}"]`)
      // TODO Polyfill for older browsers and IE
      collapse.scroll({
        left:
          activeLink.offsetLeft +
          activeLink.offsetWidth / 2 -
          collapse.offsetWidth / 2,
        behavior: 'smooth',
      })
    }
  }

  let advisoryPanelRole
  switch (person.advisor_level) {
    case 'Advisor Mentor':
    case 'Primary Advisor':
      advisoryPanelRole = 'Primary or Secondary Advisor'
      break
    case 'Secondary Advisor':
      advisoryPanelRole = 'Secondary Advisor'
      break
    default:
      break
  }

  const personName = utils.personName(person)
  const personFullName = (
    <>
      {person.title && (
        <>
          <span className="profile-title">{person.title}</span>{' '}
        </>
      )}
      <span
        className={classnames('profile-name', {
          'profile-name-long': utils.isLongPersonName(personName),
        })}
      >
        {personName}
      </span>
    </>
  )

  return (
    <div className="profile">
      <Title page={utils.personTitledName(person)} />
      <Container fluid="lg">
        <Row>
          <Col className="pt-2 pt-lg-3">
            <Row className="profile-details" id="profile-details">
              <Col xs={6} sm={4} md={3} className="mb-1">
                <ProfilePhoto
                  person={person}
                  variant="profile-x2"
                  className="img-fluid"
                />
              </Col>
              <Col xs="auto" md className="pl-lg-3">
                <div className="profile-identity">
                  <h1 className="profile-fullname">{personFullName}</h1>
                  {person.settings.suffixes && (
                    <div
                      className="profile-suffixes"
                      dangerouslySetInnerHTML={{ __html: person.suffixes }}
                    ></div>
                  )}
                  <ProfileTypes person={person} iconSize="1.5em" />
                </div>

                {person.settings.byline && (
                  <p className="profile-byline lead hyphens-auto">
                    {person.settings.byline}
                  </p>
                )}

                <Row>
                  {(utils.arrayPopulated(person.orgus_academic) ||
                    utils.arrayPopulated(person.orgus_student) ||
                    utils.arrayPopulated(person.centres)) && (
                    <Col>
                      <ul className="list-unstyled">
                        {person.orgus_academic.map(assoc => (
                          <li
                            key={assoc.job_number}
                            className="profile-association"
                          >
                            <strong>{assoc.position}</strong> –{' '}
                            <a href={assoc.orgu.url}>{assoc.orgu.name}</a>
                          </li>
                        ))}
                        {!!person.orgus_student.filter(
                          assoc => assoc.responsibility === 'Teaching'
                        ).length && (
                          <li className="profile-association">
                            <strong>{person.orgus_student[0].type}</strong> in{' '}
                            {person.orgus_student
                              .filter(
                                assoc => assoc.responsibility === 'Teaching'
                              )
                              .map((assoc, index, array) => (
                                <React.Fragment key={assoc.orgu.id}>
                                  <a
                                    className="profile-association-group"
                                    href={assoc.orgu.url}
                                  >
                                    {assoc.orgu.name}
                                  </a>
                                  {index !== array.length - 1 && '; '}
                                  {index === array.length - 2 && 'and '}
                                </React.Fragment>
                              ))}
                          </li>
                        )}
                        {person.orgus_student
                          .filter(assoc => assoc.responsibility === 'Owner')
                          .map(assoc => (
                            <li
                              key={assoc.orgu.id}
                              className="profile-association"
                            >
                              <strong>With</strong>{' '}
                              <a href={assoc.orgu.url}>{assoc.orgu.name}</a>
                            </li>
                          ))}
                        {person.centres.map(centre => (
                          <li key={centre.id}>
                            <strong>Member</strong> –{' '}
                            <a href={centre.url}>{centre.name}</a>
                          </li>
                        ))}
                      </ul>
                    </Col>
                  )}
                  <Col sm="auto" md={4} xl={3}>
                    <Button block className="rounded" href="#contact">
                      <IconLabel icon={mdiForum} size="1.75em">
                        Contact me
                      </IconLabel>
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>

      <Scrollspy
        items={['profile-details', 'contact']}
        onUpdate={handleNavigatorScrollUpdate}
        componentTag="div"
        className={classnames(
          'profile-navigator sticky-top',
          viewingProfileContent && 'in-profile',
          viewingProfileContact && 'in-contact'
        )}
      >
        <Container fluid="lg" className="profile-details-mini">
          <ProfileMini person={person} />
          <Button
            variant="outline-primary"
            className="rounded flex-shrink-0"
            href="#contact"
          >
            Contact<span className="d-none d-md-inline"> me</span>
          </Button>
        </Container>
        <Navbar
          className="navbar-secondary navbar-profile"
          expand
          onSelect={() => {}}
        >
          <Container fluid="lg">
            <div className="sr-only">
              This page contains the following content:
            </div>
            <Navbar.Collapse ref={navbarProfileCollapseRef}>
              <Scrollspy
                componentTag={Nav}
                items={sections.map(s => s.id)}
                currentClassName="active"
                scrolledPastClassName="scroll-passed"
                offset={-150}
                ref={sectionScrollspy}
                onUpdate={handleProfileSectionScrollUpdate}
              >
                {sections.map(
                  s =>
                    !s.hidden && (
                      <Nav.Link active={false} key={s.id} href={`#${s.id}`}>
                        {s.name}
                      </Nav.Link>
                    )
                )}
              </Scrollspy>
            </Navbar.Collapse>
            <Dropdown alignRight className="navbar-profile-all d-lg-none">
              <Dropdown.Toggle
                variant="light"
                bsPrefix=" "
                className="nav-link navbar-profile-all-toggle"
              >
                <Icon
                  path={mdiDotsHorizontal}
                  style={{ width: '2em' }}
                  viewBox="0 6 24 12"
                />
                <span className="sr-only">All sections&hellip;</span>
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {sections.map(
                  s =>
                    !s.hidden && (
                      <Dropdown.Item key={s.id} href={`#${s.id}`}>
                        {s.name}
                      </Dropdown.Item>
                    )
                )}
                <Dropdown.Item href="#contact">Contact Details</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Container>
        </Navbar>
      </Scrollspy>

      <Container fluid="lg">
        <Row>
          <Col className="pb-lg-3">
            <div className="profile-overview mb-4" id="overview">
              <h2 className="sr-only">Overview</h2>
              {person.is_student && (
                <>
                  <div className="mb-3">
                    <h3 className="profile-overview-subheading mb-1">
                      Higher Degree Research Project
                    </h3>
                    <p className="lead">
                      {person.thesis_url ? (
                        <a href={person.thesis_url}>{person.thesis_title}</a>
                      ) : (
                        person.thesis_title
                      )}
                    </p>
                  </div>

                  {utils.arrayPopulated(person.advisors) && (
                    <div className="mb-2 mb-lg-0">
                      <h3 className="profile-overview-subheading">
                        JCU Research Advisors
                      </h3>
                      <PersonCards
                        people={person.advisors.map(supervision => ({
                          ...supervision.advisor,
                          role: supervision.supervision_type,
                        }))}
                        showType={false}
                      />
                    </div>
                  )}
                </>
              )}

              {person.is_student && <hr className="d-none d-lg-block mb-4" />}
              <h3 className="sr-only">Showcase</h3>
              <ProfileShowcase person={person} />

              {person.settings.biography && (
                <div className="mb-3">
                  <h3 className="profile-overview-subheading">Biography</h3>
                  <ReadMore
                    className="profile-biography hyphens-auto"
                    id="biography"
                  >
                    {person.settings.biography}
                  </ReadMore>
                </div>
              )}

              {utils.arrayPopulated(person.for_codes) && (
                <Row className="justify-content-lg-center align-items-center mb-3">
                  <Col xs={12} md="auto">
                    <h3 className="profile-overview-subheading">
                      My research areas
                    </h3>
                  </Col>
                  <Col xs={12} md="auto" className="btn-group-flex">
                    {person.for_codes.map(for_code => (
                      <Button
                        key={for_code.for_code.for_code}
                        variant="outline-secondary"
                        className="d-block d-sm-inline-block rounded mr-2"
                      >
                        {for_code.for_code.description}
                      </Button>
                    ))}
                  </Col>
                </Row>
              )}

              {utils.arrayPopulated(person.interests) && (
                <ProfileSection
                  id="interests"
                  name="Interests"
                  icon={mdiLightbulbOn}
                >
                  {person.interests.map(group => (
                    <ProfileSubsection key={group.type} name={group.type}>
                      <ul style={{ lineHeight: 2 }}>
                        {group.items.map(item => (
                          <li key={item.id}>{item.interest}</li>
                        ))}
                      </ul>
                    </ProfileSubsection>
                  ))}
                </ProfileSection>
              )}
            </div>

            {utils.arrayPopulated(person.projects) && (
              <ProfileSection
                id="projects"
                name="Projects"
                icon={mdiClipboardText}
              >
                Projects go here
              </ProfileSection>
            )}

            {(utils.arrayPopulated(person.experience) ||
              utils.arrayPopulated(person.skills)) && (
              <ProfileSection
                id="experience"
                name="Experience"
                icon={mdiStarCircle}
                description="Skills are randomly added for this demo; you'll be able to add your own"
              >
                {utils.arrayPopulated(person.skills) && (
                  <ProfileSubsection name="Skills">
                    <div className="btn-group-flex">
                      {person.skills.map((skill, index) => (
                        <Button
                          key={index}
                          variant="outline-secondary"
                          className="rounded"
                        >
                          {skill}
                        </Button>
                      ))}
                    </div>
                  </ProfileSubsection>
                )}

                {utils.arrayPopulated(person.experience) && (
                  <ProfileSubsection name="Timeline">
                    <Timeline
                      className="mr-3"
                      data={person.experience}
                      renderer={(exp, index) => (
                        <React.Fragment key={index}>
                          {exp.position}
                          {exp.institution && (
                            <>
                              , <em>{exp.institution}</em>
                            </>
                          )}
                          {exp.location && <> ({exp.location})</>}
                        </React.Fragment>
                      )}
                    />
                  </ProfileSubsection>
                )}
              </ProfileSection>
            )}

            {utils.arrayPopulated(person.achievements) && (
              <ProfileSection
                id="achievements"
                name="Achievements"
                icon={mdiCertificate}
                className=""
              >
                {person.achievements.map(group => (
                  <ProfileSubsection
                    key={group.type}
                    name={
                      group.type === 'Other' ? group.type : `${group.type}s`
                    }
                  >
                    <Timeline
                      className="mr-3"
                      data={
                        group.type !== 'Award'
                          ? group.items
                          : group.items.sort(sortAwards)
                      }
                      allowOngoing={group.type !== 'Award'}
                      renderer={item => item.title}
                    />
                  </ProfileSubsection>
                ))}
              </ProfileSection>
            )}

            {utils.arrayPopulated(person.outputs) && (
              <ProfileSection
                id="outputs"
                name="Research Outputs"
                icon={mdiBookVariant}
                description="From ResearchOnline@JCU, Research Data JCU & more"
              >
                <ProfileOutputs person={person} className="mb-3" />

                {person.eprints_id && (
                  <div>
                    <h3>Explore further</h3>
                    <Button
                      variant="outline-primary"
                      className="btn-md rounded"
                      href={`https://researchonline.jcu.edu.au/view/all/${person.eprints_id}.html`}
                    >
                      See all outputs on ResearchOnline@JCU
                    </Button>{' '}
                    <Button
                      variant="outline-secondary"
                      className="btn-md rounded"
                      href={`https://researchonline.jcu.edu.au/cgi/exportview/all/${person.eprints_id}/HTML/${person.eprints_id}.html`}
                    >
                      Get citations from ResearchOnline@JCU
                    </Button>{' '}
                    <Button
                      variant="outline-primary"
                      className="btn-md rounded"
                      href={`https://research.jcu.edu.au/researchdata/default/search?query=${person.eprints_id}`}
                    >
                      See datasets on Research Data JCU
                    </Button>
                  </div>
                )}
              </ProfileSection>
            )}

            {utils.arrayPopulated(person.funding) && (
              <ProfileSection
                id="funding"
                name="Funding"
                icon={mdiCurrencyUsdCircleOutline}
                description="Current research funding to JCU shown by source & project"
              >
                <ProfileFunding person={person} />
              </ProfileSection>
            )}

            {utils.arrayPopulated(person.collaboration) && (
              <ProfileSection
                id="collaboration"
                name="Collaboration"
                icon={mdiEarth}
                description="Locations are self-contributed or indicate institutions from outputs & funding"
              >
                <div className="mb-4">
                  <CollaborationMap
                    data={person.collaboration}
                    showCampuses={false}
                    fakeActivities={5}
                  />
                </div>
                {!person.is_student && (
                  <div className="mb-3">
                    <h3 className="h5 text-uppercase">
                      JCU Collaborations <small>(examples)</small>
                    </h3>
                    <PersonCards
                      people={[
                        utils.getLocalResearcher('peter.leggat'),
                        utils.getLocalResearcher('andrew.krockenberger'),
                        utils.getLocalResearcher('chris.cocklin'),
                      ]}
                    />
                  </div>
                )}
              </ProfileSection>
            )}
            {utils.arrayPopulated(person.supervision) && (
              <ProfileSection
                id="supervision"
                name="Supervision"
                icon={mdiAccountSupervisor}
                description="Graduate research projects from current or completed students at JCU"
              >
                <ProfileSupervision person={person} />
              </ProfileSection>
            )}

            {utils.arrayPopulated(person.teaching) && (
              <ProfileSection
                id="teaching"
                name="Teaching"
                icon={mdiBookOpenVariant}
                description="Current teaching roles at JCU"
              >
                <ProfileTeaching person={person} />
              </ProfileSection>
            )}

            <ProfileSection
              id="contact"
              className="profile-section-contact"
              name="Contact details"
              icon={mdiAccountCircle}
            >
              <Row className="align-items-stretch mb-2 mb-lg-3">
                <Col className="mb-2 mb-lg-3">
                  <Row>
                    <Col xs={5} sm={3} className="mb-2">
                      <ProfilePhoto
                        person={person}
                        variant="profile-x2"
                        className="img-fluid"
                      />
                    </Col>
                    <Col xs="auto" className="col-fit">
                      <div className="profile-identity">
                        <div className="profile-fullname">{personFullName}</div>
                        <ProfileTypes
                          person={person}
                          iconSize="1.5em"
                          showAdvisorStatus={false}
                        />
                      </div>
                      {person.settings.byline && (
                        <p className="profile-byline lead hyphens-auto">
                          {person.settings.byline}
                        </p>
                      )}
                    </Col>
                  </Row>
                </Col>
                <Col
                  xs={12}
                  lg={5}
                  className="d-flex flex-column justify-content-center pt-2 px-md-3 px-lg-4 border-top border-top-md-none border-left-md"
                >
                  <ContactCard person={person}>
                    <ContactCard.PersonUrl person={person} />
                  </ContactCard>

                  <Button
                    block
                    className="mb-1"
                    onClick={() => setShowContactModal(true)}
                  >
                    Send a message
                  </Button>
                  <ProfileContactModal
                    person={person}
                    show={showContactModal}
                    onHide={() => setShowContactModal(false)}
                  />

                  <div className="text-center">
                    <Button
                      variant="link"
                      className="text-muted text-underline"
                      href="#"
                      onClick={() => setShowMediaEnquiriesModal(true)}
                    >
                      Media enquiries
                    </Button>
                    <MediaEnquiriesModal
                      person={person}
                      size="lg"
                      show={showMediaEnquiriesModal}
                      onHide={() => setShowMediaEnquiriesModal(false)}
                    />
                  </div>
                </Col>
              </Row>

              {utils.arrayPopulated(person.externalidentifiers) && (
                <Row className="justify-content-center align-items-center mb-2 mb-lg-3 py-2 py-lg-3 border-top border-bottom">
                  <Col sm={12} lg="auto">
                    <h3 className="mb-0">Find me on</h3>
                  </Col>
                  <Col lg="auto" className="mw-lg-50 text-lg-center">
                    {person.externalidentifiers.map(id => (
                      <ProfileIdentifier
                        key={`${id.provider}-${id.external_id}`}
                        provider={id.provider}
                        identifier={id.external_id}
                      />
                    ))}
                  </Col>
                </Row>
              )}

              {person.advisor_level && (
                <Row className="align-items-stretch">
                  <Col>
                    <Media as="h3" className="align-items-center">
                      <Icon
                        className="mr-2"
                        path={mdiAccountSupervisor}
                        size="2em"
                      />
                      <Media.Body as="span">I’m a Research Advisor</Media.Body>
                    </Media>
                    <p className="lead">
                      My status is <em>{person.advisor_level}</em> – for
                      higher-degree research projects, I can be on your advisory
                      panel as a {advisoryPanelRole}, depending on my
                      availability.
                    </p>
                  </Col>
                  <Col
                    md={5}
                    className="d-flex justify-content-center align-items-center border-left-md py-3 px-lg-5"
                  >
                    <Link
                      to="/find-an-advisor"
                      className="text-muted text-underline"
                    >
                      See all JCU Research Advisors
                    </Link>
                  </Col>
                </Row>
              )}
            </ProfileSection>

            <Debug object={person} />
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Profile
