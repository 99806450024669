import React from 'react'
import { Link } from 'react-router-dom'
import { Row, Col, OverlayTrigger, Tooltip } from 'react-bootstrap'

import { personName, personUrl } from '../utils'

import ProfilePhoto from './ProfilePhoto'
import './Collage.scss'

export default function Collage(props) {
  return (
    <Row noGutters className="collage">
      {props.people.map(person => {
        const url = personUrl(person)
        return (
          <Col key={url} className="collage-avatar">
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip id={person.link_id} className="collage-avatar-tooltip">
                  {person.title && (
                    <>
                      <span className="small">{person.title}</span>
                      <br />
                    </>
                  )}
                  {personName(person)}
                </Tooltip>
              }
            >
              <Link to={personUrl(person)}>
                <ProfilePhoto person={person} className="collage-avatar-img" />
              </Link>
            </OverlayTrigger>
          </Col>
        )
      })}
    </Row>
  )
}
