import React from 'react'
import PropTypes from 'prop-types'
import { Row, Col, Card, Media, Button } from 'react-bootstrap'
import Icon from '@mdi/react'
import { mdiBook, mdiViewGrid, mdiViewList } from '@mdi/js'

import DataExplorer from './DataExplorer'
import IconLabel from './IconLabel'
import ProfilePhoto from './ProfilePhoto'
import { useRegexSortType } from '../hooks'
import { personName, personUrl, personNameAlphabetic } from '../utils'
import config from '../config'

import './ProfileSupervision.scss'

function SupervisionStatus({ supervision }) {
  const isCurrent = !supervision.year_completed
  return (
    <>
      <div className="supervision-card-completion">
        <IconLabel
          icon={isCurrent ? config.icons.time : config.icons.student}
          title={
            isCurrent
              ? 'Current graduate researcher'
              : 'Graduate research completed'
          }
        >
          {isCurrent ? 'Current' : supervision.year_completed}
        </IconLabel>
      </div>
      <div className="supervision-card-status">
        <IconLabel icon={config.icons.advisor} title="Advisor status">
          {supervision.supervision_type}
        </IconLabel>
      </div>
    </>
  )
}

SupervisionStatus.propTypes = {
  supervision: PropTypes.object.isRequired,
}

function SupervisionIdentity({ supervision }) {
  return (
    <Media>
      <ProfilePhoto
        person={supervision.student}
        className="supervision-card-photo mini-photo"
      />
      <Media.Body>
        <div className="supervision-card-identity">
          {supervision.student ? (
            <>
              <div className="small">{supervision.student.title}</div>
              <div className="supervision-card-name">
                <a
                  href={personUrl(supervision.student)}
                  className="stretched-link"
                >
                  {personName(supervision.student)}
                </a>
              </div>
              <div className="supervision-card-type">
                {supervision.hdr_type} Candidate
              </div>
            </>
          ) : (
            <div className="supervision-card-name">
              {supervision.hdr_type} Candidate
            </div>
          )}
        </div>
        <div>{supervision.thesis_title}</div>
      </Media.Body>
    </Media>
  )
}

SupervisionIdentity.propTypes = {
  supervision: PropTypes.object.isRequired,
}

function SupervisionGridItem({ row: { original: supervision } }) {
  return (
    <Col key={supervision.id} className="supervision-grid-item">
      <Card className="supervision-card">
        <Card.Header className="pb-0">
          <SupervisionStatus supervision={supervision} />
        </Card.Header>
        <Card.Body className="position-relative">
          <SupervisionIdentity supervision={supervision} />
        </Card.Body>
        {(supervision.student || supervision.thesis_url) && (
          <Card.Footer className="pt-0">
            {supervision.student && (
              <Card.Link href={personUrl(supervision.student)}>
                See research profile
              </Card.Link>
            )}
            {supervision.thesis_url && (
              <Card.Link
                as="a"
                className="stretched-link"
                href={supervision.thesis_url}
              >
                See thesis
              </Card.Link>
            )}
          </Card.Footer>
        )}
      </Card>
    </Col>
  )
}

SupervisionGridItem.propTypes = {
  supervision: PropTypes.object.isRequired,
}

function SupervisionListItem({ row: { original: supervision } }) {
  return (
    <Row
      key={supervision.id}
      className="supervision-list-item align-items-center"
    >
      <Col sm={9} md={7} className="mb-2 mb-sm-0">
        <SupervisionIdentity supervision={supervision} />
      </Col>
      <Col sm={3} md={3}>
        <SupervisionStatus supervision={supervision} />
        {supervision.thesis_url && (
          <a className="d-inline-block d-md-none" href={supervision.thesis_url}>
            <IconLabel icon={mdiBook} title="Thesis">
              View thesis
            </IconLabel>
          </a>
        )}
      </Col>
      {(supervision.student || supervision.thesis_url) && (
        <Col className="d-none d-md-block">
          {supervision.student && (
            <Button
              variant="outline-primary"
              block
              className="rounded"
              href={personUrl(supervision.student)}
            >
              See profile
            </Button>
          )}
          {supervision.thesis_url && (
            <Button
              variant="outline-primary"
              block
              className="rounded"
              href={supervision.thesis_url}
            >
              <Icon path={mdiBook} size="2rem" />
              <br />
              View thesis
            </Button>
          )}
        </Col>
      )}
    </Row>
  )
}

SupervisionListItem.propTypes = {
  supervision: PropTypes.object.isRequired,
}

export default function ProfileSupervision(props) {
  return (
    <DataExplorer
      id="supervision-data"
      data={props.person.supervision}
      type="research candidate"
      typePlural="research candidates"
      defaultPageSize={6}
      pageSizes={[6, 10, 20, 'all']}
      views={{
        list: {
          title: 'List',
          icon: mdiViewList,
          view: props => <div>{props.children}</div>,
          item: SupervisionListItem,
        },
        grid: {
          title: 'Grid',
          icon: mdiViewGrid,
          default: true,
          view: props => (
            <Row xs={1} md={2}>
              {props.children}
            </Row>
          ),
          item: SupervisionGridItem,
        },
      }}
      columns={[
        {
          id: 'year_completed',
          accessor: supervision => supervision.year_completed || 99999,
          disableGlobalFilter: true,
          sortType: 'basic',
        },
        {
          id: 'year_completed_filterable',
          accessor: supervision => supervision.year_completed || 'Current',
        },
        {
          id: 'is_completed',
          accessor: supervision => !!supervision.year_completed,
        },
        {
          id: 'role',
          accessor: 'supervision_type',
          sortType: useRegexSortType(
            [/Primary/, /Secondary/, /Mentor/, /External/, /Associate/],
            'role'
          ),
        },
        {
          accessor: 'hdr_type',
          sortType: useRegexSortType([/PhD/, /Masters/], 'hdr_type'),
          disableGlobalFilter: true,
        },
        {
          id: 'hdr_type_filterable',
          accessor: supervision => `${supervision.hdr_type} candidate`,
        },
        {
          accessor: 'thesis_title',
        },
        {
          id: 'has_thesis_url',
          accessor: supervision => !!supervision.thesis_url,
        },
        {
          id: 'student_name',
          accessor: supervision =>
            supervision.student
              ? personNameAlphabetic(supervision.student)
              : '~',
        },
      ]}
      sort={[
        {
          title: 'Most recent',
          columns: [
            { id: 'year_completed', desc: true },
            { id: 'role' },
            { id: 'hdr_type' },
            { id: 'student_name' },
          ],
        },
        {
          title: 'Advisor role',
          columns: [
            { id: 'role' },
            { id: 'hdr_type' },
            { id: 'year_completed', desc: true },
            { id: 'student_name' },
          ],
        },
        {
          title: 'Candidate type',
          columns: [
            { id: 'hdr_type' },
            { id: 'role' },
            { id: 'year_completed', desc: true },
            { id: 'student_name' },
          ],
        },
        { title: 'Thesis title', columns: [{ id: 'thesis_title' }] },
        {
          title: 'Name',
          columns: [
            { id: 'student_name' },
            { id: 'year_completed', desc: true },
            { id: 'role' },
            { id: 'hdr_type' },
          ],
        },
      ]}
      filters={[
        { id: 'is_completed', type: 'boolean', title: 'Completed' },
        { id: 'has_thesis_url', type: 'boolean', title: 'Published Thesis' },
      ]}
    />
  )
}
