import React from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from 'react-bootstrap'
import { mdiAccount, mdiMapMarker, mdiBookVariant } from '@mdi/js'

import DataExplorer from './DataExplorer'
import IconLabel from './IconLabel'
import config from '../config'

import './ProfileTeaching.scss'

function TeachingListItem({ row: { original: subject } }) {
  return (
    <Row key={subject.code} className="teaching-subject lh-short">
      <Col
        xs={12}
        md={6}
        className="d-flex align-items-center mb-2 mb-md-0 lead"
      >
        <a
          className="teaching-subject-code"
          href={config.urls.studyfinder.replace('{subject}', subject.code)}
        >
          {subject.code}
        </a>{' '}
        <a
          className="ml-2 pl-2 border-left text-reset"
          href={config.urls.studyfinder.replace('{subject}', subject.code)}
        >
          {subject.title}
        </a>
      </Col>
      <Col sm md="auto">
        <IconLabel icon={mdiBookVariant} align="center" size="1em">
          Level {subject.level}
        </IconLabel>
      </Col>
      <Col sm>
        <IconLabel icon={mdiAccount} align="center" size="1em">
          {subject.role}
        </IconLabel>
      </Col>
      <Col sm>
        <IconLabel icon={mdiMapMarker} align="center" size="1em">
          {subject.campus.join(', ')}
        </IconLabel>
      </Col>
    </Row>
  )
}

TeachingListItem.propTypes = {
  subject: PropTypes.object.isRequired,
}

export default function ProfileTeaching(props) {
  return (
    <DataExplorer
      id="teaching-data"
      data={props.person.teaching}
      type="current subject"
      typePlural="current subjects"
      views={{
        list: {
          title: 'List',
          view: props => <div>{props.children}</div>,
          item: TeachingListItem,
        },
      }}
      columns={[
        { accessor: 'code' },
        { accessor: 'title' },
        { accessor: 'level', disableGlobalFilter: true },
        {
          id: 'level_filterable',
          accessor: subject => `Level ${subject.level}`,
        },
        { accessor: 'role' },
        { id: 'location', accessor: subject => subject.campus.join(',') },
      ]}
      sort={[
        {
          title: 'Subject code',
          columns: [{ id: 'code' }],
        },
        {
          title: 'Title',
          columns: [{ id: 'title' }],
        },
        {
          title: 'Level (year)',
          columns: [{ id: 'level' }],
        },
        {
          title: 'Role',
          columns: [{ id: 'role' }],
        },
        {
          title: 'Location',
          columns: [{ id: 'location' }],
        },
      ]}
    />
  )
}
