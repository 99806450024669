import React from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import Icon from '@mdi/react'
import classnames from 'classnames'

import config from '../config'

export default function ProfileSectionHeading(props) {
  return (
    <div
      className={classnames(
        'section-heading',
        props.id && `section-${props.id}`,
        props.className
      )}
      id={props.id}
    >
      <h2 className="h3">
        <Icon
          className="section-heading-icon mr-1 mr-sm-2"
          path={props.icon}
          size="1.75em"
        />
        {props.heading}
      </h2>
      {props.description && (
        <div className="section-heading-desc">
          <span className="d-none d-md-inline small text-muted">
            {props.description}
          </span>
          <span className="d-md-none">
            <OverlayTrigger
              placement="left"
              overlay={<Tooltip>{props.description}</Tooltip>}
            >
              <Icon
                path={config.icons.help}
                size="1.5em"
                title={props.description}
              />
            </OverlayTrigger>
          </span>
        </div>
      )}
    </div>
  )
}
