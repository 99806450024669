import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'

import { Title, Intro, Breadcrumbs, CallToAction } from './components'

import HeroImg from './img/jcu-campus-townsville.jpg'

export default function FaqUpdate() {
  return (
    <div>
      <Title page="Update FAQ" />

      <Breadcrumbs hierarchy={[{ title: 'Update FAQ' }]} />

      <Intro
        backgroundImage={HeroImg}
        backgroundPosition="center bottom"
        heading="FAQs"
      >
        Frequently asked questions about the JCU Research Portfolio v.2
      </Intro>

      <Container fluid="lg" className="py-3 py-lg-4">
        <Row className="justify-content-center">
          <Col>
            <div className="mb-4">
              <h2>What's changed?</h2>
              <p>
                A lot! Here's the key changes from the original 2012 system:
              </p>
              <ul>
                <li>Brand new, modern look and feel across the whole site</li>
                <li>
                  Introduction of{' '}
                  <a href="/student">research (HDR) student profiles</a>,
                  alongside academic profiles
                </li>
                <li>
                  Revamped <a href="/find-an-advisor">Find an Advisor</a>{' '}
                  experience
                </li>
                <li>
                  Inclusion of <a href="/projects">Research Projects</a> on
                  profile pages and the site as a whole
                </li>
                <li>
                  Featuring ORCID iDs, requiring ORCID iD linking to edit pages
                </li>
                <li>Daily updates of Portfolio data, up from weekly</li>
                <li>
                  Profiles now only display of academic honorifics, and display
                  then in full
                </li>
              </ul>
            </div>

            <div className="mb-4">
              <h2>Where has my data gone?</h2>
              <p>
                We've restructured some data in a way that's designed to be
                friendlier to the people visiting Profile pages. There's a lot
                of types of people that might be looking at your page so we've
                optimised for as many as we can. This might be a potential
                collaborator, funder, or HDR student so we want to explain you
                and your research in the best way possible. We're always looking
                for feedback and we'd love to talk with you about any ideas on
                improving the page experince.{' '}
                <a href="/contact-us">Drop us a line</a> if we can help.
              </p>
            </div>

            <div className="mb-4">
              <h2>Why is most of my biography hidden?</h2>
              <p>
                We display the most recent and relevant info so it's visible to
                a visitor when they first load a Portfolio profile page without
                having to scroll. The original Research Portfolio was built on
                the cusp of the advent of mobile technology (back in 2012) so
                we've adapted our site to help visitors see the most interesting
                details about you at a glance.
              </p>
              <p>
                We highly encourage you to use the new Showcase feature to
                highlight key details about you on your Profile page and you can
                find out more about to do this on the new interactive{' '}
                <a href="/help">Help and Support</a> page.
              </p>
            </div>

            <div className="mb-4">
              <h2>Where did all the tabs go?</h2>
              <p>
                From a web and user interface point of view, having tabs was a
                poor choice as users tend to miss them and the original Research
                Portfolio was no exception. One key bit of feedback we had about
                the old designs is that once you had moved down the page, you
                had to go back up to look at another tab. This meant that really
                interesting aspects of research work frequently got missed, such
                as Funding tab and the Collaboration map tab.
              </p>
              <p>
                We want to help you promote yourself and your research so we've
                changed our layout to match modern concepts, modelled on
                successful sites like Wikipedia. It makes it easier for a reader
                to find something they want to know about you, boosts your
                visibility in search engines SEO and we've provided new ways for
                you to feature key research statistics about you in a
                visitor-friendly way.
              </p>
            </div>

            <div className="mb-4">
              <h2>I'd like to provide feedback, how do I do this?</h2>
              <p>Thanks for your input – we really appreciate it.</p>
              <p>
                You can get in touch via <a href="/contact-us">Contact Us</a>{' '}
                and share your thoughts. If you've got a little more time to
                spare, we can also set up a quick interactive session (via the
                phone, Zoom or in person) so we can learn more about your
                thoughts and ideas.
              </p>
            </div>
          </Col>
        </Row>
      </Container>

      <CallToAction.General />
    </div>
  )
}
