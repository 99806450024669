import React from 'react'
import Icon from '@mdi/react'
import { mdiChevronUp } from '@mdi/js'
import classnames from 'classnames'

import './BackToTopLink.css'

export default function BackToTopLink(props) {
  const { className, ...rest } = props
  return (
    <a className={classnames('back-to-top', className)} href="#top" {...rest}>
      <Icon path={mdiChevronUp} size={1} />
      Back to top
    </a>
  )
}
