import React from 'react'
import { Container, Row } from 'react-bootstrap'
import Icon from '@mdi/react'
import { mdiPuzzle, mdiArrowRight } from '@mdi/js'

import {
  Title,
  Feature,
  FeaturedCategories,
  ProjectCard,
  CallToAction,
} from './components'
import config from './config'
import data from './data/projects'

import FeatureImg from './img/orpheus-island.jpg'
import CallToActionImg from './img/advisor.jpg'

export default function Projects() {
  return (
    <>
      <Title page="Research Projects" />
      <Feature
        title="Make a difference with a JCU project"
        aboutText="Discover research projects, industry partnerships, volunteering and more"
        searchPlaceholder="Find by topic, researcher, opportunity…"
        backgroundImage={FeatureImg}
        identityText={
          <>
            <a
              className="feature-identity-name stretched-link lead"
              href="/projects"
            >
              Orpheus Island Research Station
            </a>
            <div>
              Help protect the reef &nbsp;{' '}
              <Icon path={mdiArrowRight} size="1.25em" />
            </div>
          </>
        }
        actionVerb="Explore"
        count={150}
        countNoun="projects"
        background=""
      />
      <Container fluid="md" className="mb-3">
        <Row xs={1} sm={2} lg={3}>
          {data.map(project => (
            <ProjectCard project={project} />
          ))}
        </Row>
      </Container>
      <FeaturedCategories
        title="Explore all JCU projects"
        icon={mdiPuzzle}
        categories={{
          '/projects/academy': 'By academic group',
          '/projects/centres': 'By Research Centre and Institute',
          '/projects/search': 'PhD or Masters projects',
        }}
      />
      <CallToAction
        title={
          <>
            Want a research project that's uniquely <em>you</em>?
          </>
        }
        action="Get advice from JCU"
        actionLink={config.urls.grsContact}
      />
      <Container fluid="md" className="py-5"></Container>

      <CallToAction
        title="Find the JCU Advisor you need"
        backgroundImage={CallToActionImg}
        action="Browse all Research Advisors"
        actionLink="/find-an-advisor"
      />
    </>
  )
}
