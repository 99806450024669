import React from 'react'
import PropTypes from 'prop-types'
import { Row, Col, ResponsiveEmbed } from 'react-bootstrap'
import { useHash } from 'react-use'

import ConditionalWrapper from './ConditionalWrapper'
import ContactCard from './ContactCard'
import EraAbbr from './EraAbbr'
import EraEligibleIcon from './EraEligibleIcon'
import FileIcon from './FileIcon'
import OpenAccessIcon from './OpenAccessIcon'
import ProfileIdentifier from './ProfileIdentifier'
import ResponsiveValue from './ResponsiveValue'
import Showcase from './Showcase'

import { personTitledName, arrayPopulated } from '../utils'

import config from '../config'

import './ProfileShowcase.scss'

import RandomImg from '../img/dro-canopy-crane.jpg'
import RandomLogo from '../img/jcu-connect-logo.svg'

function OpenAccessUnit(props) {
  return (
    <>
      <OpenAccessIcon size="1.5em" /> Open Access{' '}
      <br className="d-none d-sm-inline" /> research output{props.plural && 's'}
    </>
  )
}

function EraEligibleUnit(props) {
  return (
    <>
      <EraEligibleIcon size="1.5em" /> <EraAbbr style={{ fontSize: '90%' }} />
      -eligible research output{props.plural && 's'}
    </>
  )
}

export default function ProfileShowcase(props) {
  const [hash] = useHash()
  const displayAll = hash.search('showcase') >= 0

  const person = props.person
  let items = person.showcase || ['outputs', 'collaboration', 'contact']
  if (displayAll) {
    items = [
      'outputs',
      'outputs-era',
      'outputs-openaccess',
      'hindex',
      'skills',
      'collaboration',
      'funding',
      'funding-total',
      'image',
      'logo',
      'video',
      'gallery',
      'download',
      'supervision',
      'supervision-current',
      'external-ids',
      'contact',
      'campus',
    ]
  }

  const totalFunding =
    arrayPopulated(person.funding) &&
    Math.round(
      person.funding.reduce(
        (total, funding) => total + funding.approved_amount,
        0
      )
    )

  return (
    <Row className="profile-showcase" xs={1} md={3}>
      {items.map(item => {
        switch (item) {
          case 'outputs':
            return (
              <Showcase.Item
                key={item}
                as={Col}
                href="#outputs"
                value={person.outputs.length}
                unit="research output"
                unitPlural="research outputs"
              />
            )
          case 'outputs-era':
            return (
              <Showcase.Item
                key={item}
                as={Col}
                href="#outputs"
                value={person.outputs.filter(o => o.is_era_eligible).length}
                unit={<EraEligibleUnit />}
                unitPlural={<EraEligibleUnit plural />}
              />
            )
          case 'outputs-openaccess':
            return (
              <Showcase.Item
                key={item}
                as={Col}
                href="#outputs"
                value={person.outputs.filter(o => o.is_open_access).length}
                unit={<OpenAccessUnit />}
                unitPlural={<OpenAccessUnit plural />}
              />
            )
          case 'hindex':
            return (
              <Showcase.Item
                key={item}
                as={Col}
                href="#outputs"
                value={21}
                unit={
                  <>
                    <em>h</em>-index
                  </>
                }
              />
            )
          case 'skills':
            return (
              <Showcase.Item
                key={item}
                as={Col}
                href="#skills"
                value={person.skills.length}
                unit="skill"
                unitPlural="skills"
              />
            )
          case 'collaboration':
            return (
              <Showcase.Item
                key={item}
                as={Col}
                href="#collaboration"
                value={person.collaboration.length}
                unit="global collaboration"
                unitPlural="global collaborations"
              />
            )
          case 'funding':
            return (
              <Showcase.Item
                key={item}
                as={Col}
                href="#funding"
                value={person.funding.length}
                unit="funded project"
                unitPlural="funded projects"
              />
            )
          case 'funding-total':
            return (
              <Showcase.Item
                key={item}
                as={Col}
                href="#funding"
                symbol="$"
                value={
                  <ResponsiveValue.Number
                    value={totalFunding}
                    startBreakpoint="md"
                  />
                }
                unit="funding"
              />
            )
          //Multimedia
          case 'image':
            return (
              <Showcase.Item key={item} as={Col}>
                <img
                  className="img-fluid mw-75"
                  alt={'This needs to be set'}
                  src={RandomImg}
                />
              </Showcase.Item>
            )
          case 'logo':
            return (
              <Showcase.Item key={item} as={Col}>
                <ConditionalWrapper
                  condition={true}
                  as="a"
                  href={config.urls.jcuConnect}
                >
                  <img
                    className="img-fluid mw-75"
                    alt={'This needs to be set'}
                    src={RandomLogo}
                  />
                </ConditionalWrapper>
              </Showcase.Item>
            )
          case 'video':
            return (
              <Showcase.Item key={item} as={Col}>
                {/* TODO Config: URL, aspect ratio,  able, start time? */}
                {/* TODO URL configurable */}
                <ResponsiveEmbed aspectRatio="16by9">
                  <iframe
                    className="embed-responsive-item"
                    src="https://www.youtube-nocookie.com/embed/C8z_cwxAwjw?modestbranding=1&rel=0"
                    allowFullScreen
                    title={`Showcase video for ${personTitledName(person)}`}
                  ></iframe>
                </ResponsiveEmbed>
              </Showcase.Item>
            )
          case 'gallery':
            return (
              <Showcase.Item key={item} as={Col}>
                {/* TODO Aspect ratio configurable */}
                <ResponsiveEmbed aspectRatio="16by9">
                  <iframe
                    className="embed-responsive-item"
                    src="https://www.flickr.com/photos/jcu-eresearch/player"
                    allowFullScreen
                    title={`Showcase image gallery for ${personTitledName(
                      person
                    )}`}
                  ></iframe>
                </ResponsiveEmbed>
              </Showcase.Item>
            )

          case 'download':
            return (
              <Showcase.Item key={item} as={Col}>
                <a className="text-reset" href="#download">
                  <FileIcon filename="resume.pdf" size="5rem" />
                  <p className="lead">My resume</p>
                </a>
              </Showcase.Item>
            )

          //Supervision
          case 'supervision':
            return (
              <Showcase.Item
                key={item}
                as={Col}
                href="#supervision"
                value={person.supervision.length}
                unit="research supervision"
                unitPlural="research supervisions"
              />
            )
          case 'supervision-current':
            return (
              <Showcase.Item
                key={item}
                as={Col}
                href="#supervision"
                value={
                  person.supervision.filter(s => s.status === 'Current').length
                }
                unit="active research supervision"
                unitPlural="active research supervisions"
              />
            )

          //Contact
          case 'external-ids':
            return (
              <Showcase.Item key={item} as={Col}>
                <p className="sr-only lead">Find me on&hellip;</p>
                <div>
                  {person.externalidentifiers.slice(0, 4).map(id => (
                    <ProfileIdentifier
                      key={id.provider}
                      provider={id.provider}
                      identifier={id.external_id}
                      size="4rem"
                    />
                  ))}
                </div>
              </Showcase.Item>
            )
          case 'contact':
            return (
              <Showcase.Item key={item} as={Col}>
                <ContactCard person={person} className="mx-4 text-left" />
              </Showcase.Item>
            )
          case 'campus':
            return (
              <Showcase.Item
                key={item}
                as={Col}
                href="#contact"
                value={
                  <ResponsiveValue
                    long={person.campus}
                    short={config.campuses[person.campus]}
                    startBreakpoint="md"
                  />
                }
                unit="campus"
              />
            )
          default:
            return null
        }
      })}
    </Row>
  )
}

ProfileShowcase.propTypes = {
  person: PropTypes.object.isRequired,
}
