import React, { useState } from 'react'
import { useInterval, useBoolean } from 'react-use'
import { Row, Col, Alert, Button } from 'react-bootstrap'
import ReactWordcloud from 'react-wordcloud'

import 'tippy.js/dist/tippy.css'
import 'tippy.js/animations/scale.css'

function isCanvasAllowed() {
  return (
    typeof document !== 'undefined' &&
    document
      .createElement('canvas')
      .getContext('2d')
      .getImageData(0, 0, 1, 1)
      .data.every(v => v === 0)
  )
}

export default function Wordcloud(props) {
  const [canvasAllowed, setCanvasAllowed] = useState(isCanvasAllowed())
  const [isRunning, setIsRunning] = useBoolean(false)

  useInterval(
    () => {
      if (isCanvasAllowed()) {
        setIsRunning(false)
        setCanvasAllowed(true)
      }
    },
    isRunning ? 1000 : null
  )

  if (!canvasAllowed) {
    return (
      <Row className="justify-content-center">
        <Col md={10} lg={6}>
          <Alert variant="warning">
            <p>
              This word cloud requires access to your browser's canvas image
              data. Please allow access in your browser before continuing.
            </p>
            <Button
              onClick={() => {
                isCanvasAllowed() // Prompt for canvas access
                setIsRunning(true)
              }}
            >
              Allow data access
            </Button>
          </Alert>
        </Col>
      </Row>
    )
  }

  return <ReactWordcloud {...props} />
}
