import React from 'react'
import PropTypes from 'prop-types'
import { Container } from 'react-bootstrap'

import {
  Breadcrumbs,
  CallToActionCollaboration,
  Intro,
  PersonCards,
  Title,
} from './components'

import HeroImg from './img/researchers/brendan.ebner.jpg'

export default function GroupListing(props) {
  return (
    <>
      <Title page={props.title} />

      <Breadcrumbs
        hierarchy={[
          { url: '/portfolio', title: 'Researchers' },
          { title: props.title },
        ]}
      />

      <Intro
        backgroundImage={props.heroImg}
        backgroundPosition={props.heroPosition}
        heading={props.heading || props.title}
      >
        {props.byline}
      </Intro>

      <Container fluid="lg" className="my-4">
        <PersonCards people={props.people} />
        {props.children}
      </Container>

      <CallToActionCollaboration />
    </>
  )
}

GroupListing.defaultProps = {
  heroImg: HeroImg,
  heroPosition: 'center 20%',
  people: [],
}

GroupListing.propTypes = {
  heroImg: PropTypes.string.isRequired,
  heroPosition: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  heading: PropTypes.any,
  byline: PropTypes.any,
  people: PropTypes.array.isRequired,
}
