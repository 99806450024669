import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { yearRange } from '../utils'

import './Timeline.scss'

function Timeline(props) {
  return (
    <ol className={classnames('timeline', props.className)}>
      {props.data.map(entry => (
        <li key={entry.id} className="timeline-entry">
          <div
            className={classnames('timeline-badge', {
              'timeline-badge-now': props.allowOngoing && !entry.end_year,
            })}
          >
            {/* Display end year or 'Now' if end year doesn't exist, falling back to start year */}
            {entry.end_year ||
              (props.allowOngoing && 'Now') ||
              entry.start_year}
          </div>
          <div className="timeline-panel">
            {yearRange(entry.start_year, entry.end_year, props.allowOngoing)}
            {props.renderer(entry)}
          </div>
        </li>
      ))}
    </ol>
  )
}

Timeline.propTypes = {
  className: PropTypes.string,
  data: PropTypes.array.isRequired,
  allowOngoing: PropTypes.bool,
  renderer: PropTypes.oneOfType([PropTypes.func, PropTypes.elementType])
    .isRequired,
}

Timeline.defaultProps = {
  allowOngoing: true,
}

export default Timeline
