import React from 'react'
import { Container, Navbar } from 'react-bootstrap'
import classnames from 'classnames'

import MenuToggle from './MenuToggle'

export default function TitledToggle(props) {
  const { title, className, ...rest } = props
  return (
    <Container
      className={classnames('position-relative py-2 border-0', className)}
      {...rest}
    >
      <Navbar.Brand>{title}</Navbar.Brand>
      <MenuToggle.ChevronToggle className="stretched-link" />
    </Container>
  )
}
