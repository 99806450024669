import React from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

import SimpleIcon from './SimpleIcon'

import Academia from 'simple-icons/icons/academia'
import Facebook from 'simple-icons/icons/facebook'
import GoogleScholar from 'simple-icons/icons/googlescholar'
import LinkedIn from 'simple-icons/icons/linkedin'
import Mendeley from 'simple-icons/icons/mendeley'
import MicrosoftAcademic from 'simple-icons/icons/microsoftacademic'
import ORCID from 'simple-icons/icons/orcid'
import Publons from 'simple-icons/icons/publons'
import ResearchGate from 'simple-icons/icons/researchgate'
import SSRN from 'simple-icons/icons/ssrn'
import Scopus from 'simple-icons/icons/scopus'
import SlideShare from 'simple-icons/icons/slideshare'
import TheConversation from 'simple-icons/icons/theconversation'
import Trove from 'simple-icons/icons/trove'
import Twitter from 'simple-icons/icons/twitter'
import Vimeo from 'simple-icons/icons/vimeo'
import Wikipedia from 'simple-icons/icons/wikipedia'
import YouTube from 'simple-icons/icons/youtube'
import arXiv from 'simple-icons/icons/arxiv'

// Too small for Simple Icons
import { ReactComponent as Impactstory } from '../img/icons/impactstory.svg'
// No icon + no longer exists; now Publons
import { ReactComponent as ResearcherID } from '../img/icons/researcherid.svg'

function extractPath(svg) {
  const div = document.createElement('div')
  ReactDOM.render(React.createElement(svg), div)
  return div.innerHTML.match(/d="(.*?)"/)[1]
}

const externalIdentifiers = {
  Academia_edu: {
    icon: Academia,
    unique: true,
    url: '{id}',
    regex: 'academia.edu',
    example: 'https://jamescook.academia.edu/MarkHamann',
    display_form: 'Academia.edu (URL)',
    display: 'Academia.edu profile',
  },
  arXiv: {
    icon: arXiv,
    unique: true,
    url: '{id}',
    regex: 'arxiv.org',
    example: 'https://arxiv.org/find/astro-ph/1/au:+Engle_S/0/1/0/all/0/1',
    display_form: 'arXiv.org (URL)',
    display: 'arXiv.org listing',
  },
  Blog: {
    unique: false,
    url: '{id}',
    regex: '://',
    example: 'https://blog.example.org',
    display_form: 'Blog (URL)',
    display: 'online blog',
  },
  Facebook: {
    icon: Facebook,
    unique: false,
    url: '{id}',
    regex: '://',
    example: 'https://www.facebook.com/jamescookuniversity',
    display_form: 'Facebook (Full URL)',
    display: 'Facebook profile page',
  },
  Google_Scholar: {
    icon: GoogleScholar,
    unique: true,
    url: 'https://scholar.google.com/citations?user={id}&hl=en',
    regex: '^.{12}$',
    example: '9RvJm00AAAAJ',
    display_form: 'Google Scholar (ID)',
    display: 'Google Scholar profile',
  },
  Homepage: {
    unique: false,
    url: '{id}',
    regex: '://',
    example: 'https://example.org/',
    display_form: 'External homepage (URL)',
    display: 'external homepage',
  },
  Impactstory: {
    icon: {
      path: extractPath(Impactstory),
      // Brand primary colour + matched by https://profiles.impactstory.org/static/img/impactstory-symbol.png
      hex: 'FF4000',
      title: 'Impactstory',
    },
    unique: true,
    url: 'https://impactstory.org/u/{id}',
    regex: /^\d{4}-\d{4}-\d{4}-\d{3}[0-9X]$/,
    example: '0000-0001-6653-9963',
    display_form: 'Impactstory (ORCID iD)',
    display: 'Impactstory profile',
  },
  LinkedIn: {
    icon: LinkedIn,
    unique: false,
    url: '{id}',
    regex: '://',
    example: 'https://www.linkedin.com/in/jjvanderwal',
    display_form: 'LinkedIn (Public URL)',
    display: 'LinkedIn profile page',
  },
  Mendeley: {
    icon: Mendeley,
    unique: true,
    url: 'https://www.mendeley.com/profiles/{id}',
    regex: null,
    example: 'thomas-blenkinsop',
    display_form: 'Mendeley (URL ID)',
    display: 'Mendeley public profile',
  },
  Microsoft_Academic: {
    icon: MicrosoftAcademic,
    unique: true,
    url: 'https://academic.microsoft.com/#/detail/{id}',
    regex: /^\d+$/,
    example: '733110476',
    display_form: 'Microsoft Academic (ID)',
    display: 'Microsoft Academic author profile',
  },
  NLA_Party_ID: {
    icon: Trove,
    unique: true,
    url: '{id}',
    regex: '^http://nla.gov.au/.*$',
    example: 'http://nla.gov.au/nla.party-642603',
    display_form: 'NLA (Persistent Identifier)',
    display: 'NLA Trove People record',
  },
  ORCID: {
    icon: ORCID,
    unique: true,
    url: 'https://orcid.org/{id}',
    regex: /^\d{4}-\d{4}-\d{4}-\d{3}[0-9X]$/,
    example: '0000-0001-6653-9963',
    display_form: 'ORCID iD',
    display: 'ORCID profile',
  },
  Publons: {
    icon: Publons,
    unique: true,
    url: 'https://publons.com/a/{id}',
    regex: /^\d+$/,
    example: '930065',
    display_form: 'Publons (Author Profile ID)',
    display: 'Publons profile',
  },
  ResearcherID: {
    icon: {
      path: extractPath(ResearcherID),
      hex: 'F6AC33',
      title: 'ResearcherID',
    },
    unique: true,
    url: 'https://www.researcherid.com/rid/{id}',
    regex: /^[A-Z]-\d{4}-\d{4}$/,
    example: 'H-1806-2011',
    display_form: 'ResearcherID',
    display: 'ResearcherID page',
  },
  ResearchGate: {
    icon: ResearchGate,
    unique: true,
    url: 'https://www.researchgate.net/profile/{id}',
    regex: null,
    example: 'Jane_Mills3',
    display_form: 'ResearchGate (Profile ID)',
    display: 'ResearchGate profile',
  },
  Scopus_AuthorID: {
    icon: Scopus,
    unique: true,
    url: 'https://www.scopus.com/authid/detail.url?authorId={id}',
    regex: /^\d+$/,
    example: '24588327400',
    display_form: 'Scopus (Author ID)',
    display: 'Scopus Author page',
  },
  SlideShare: {
    icon: SlideShare,
    unique: true,
    url: 'https://www.slideshare.net/{id}',
    regex: null,
    example: 'Kate_JRG',
    display_form: 'SlideShare (Profile ID)',
    display: 'SlideShare profile page',
  },
  SSRN: {
    icon: SSRN,
    unique: true,
    url: 'https://papers.ssrn.com/author_id={id}',
    regex: /^\d+$/,
    example: '1441077',
    display_form: 'Social Science Research Network (SSRN Author ID)',
    display: 'Social Science Research Network (SSRN) author page',
  },
  The_Conversation: {
    icon: TheConversation,
    unique: true,
    url: 'https://theconversation.edu.au/profiles/{id}',
    regex: null,
    example: 'peter-leggat-921',
    display_form: 'TheConversation (URL ID)',
    display: 'TheConversation author profile',
  },
  Twitter: {
    icon: Twitter,
    unique: false,
    url: 'https://twitter.com/{id}',
    regex: null,
    example: 'davidjb_',
    display_form: 'Twitter (Username)',
    display: 'Twitter profile page',
  },
  Vimeo: {
    icon: Vimeo,
    unique: false,
    url: 'https://vimeo.com/{id}',
    regex: null,
    example: 'soca',
    display_form: 'Vimeo (User ID)',
    display: 'Vimeo page',
  },
  Wikipedia: {
    icon: Wikipedia,
    unique: false,
    url: '{id}',
    regex: 'wikipedia.org',
    example: 'https://en.wikipedia.org/wiki/William_F._Laurance',
    display_form: 'Wikipedia (URL)',
    display: 'Wikipedia article',
  },
  YouTube: {
    icon: YouTube,
    unique: false,
    url: 'https://www.youtube.com/user/{id}',
    regex: null,
    example: 'jamescookuniversity',
    display_form: 'YouTube (User ID)',
    display: 'YouTube user profile',
  },
  'YouTube-other': {
    icon: YouTube,
    unique: false,
    url: '{id}',
    regex: 'youtube.com',
    example: 'https://www.youtube.com/channel/UCsQjXIogmPLe9ddOcAkv3zg',
    display_form: 'YouTube (Other URL)',
    display: 'YouTube content',
  },
}

export default function ProfileIdentifier(props) {
  const externalId = externalIdentifiers[props.provider]
  // XXX If we don't know what this is, abort
  if (!externalId || !externalId.icon) return null

  return (
    <OverlayTrigger
      placement="bottom"
      overlay={
        <Tooltip>See my {externalId.display || props.provider} </Tooltip>
      }
    >
      <a
        className="d-inline-block my-1 mx-1 mx-sm-2"
        href={externalId.url.replace('{id}', props.identifier)}
      >
        <SimpleIcon
          icon={externalId.icon}
          colored
          titled
          style={{ width: '3em' }}
        />
      </a>
    </OverlayTrigger>
  )
}

ProfileIdentifier.propTypes = {
  provider: PropTypes.string.isRequired,
  identifier: PropTypes.string.isRequired,
}
