import React from 'react'
import Icon from '@mdi/react'

export default function SimpleIcon(props) {
  const { icon, colored, titled, ...rest } = props
  return (
    <Icon
      path={props.icon.path}
      color={colored ? `#${props.icon.hex}` : undefined}
      title={titled ? `${props.icon.title} icon` : undefined}
      {...rest}
    />
  )
}
