import React from 'react'
import PropTypes from 'prop-types'

import { isAcademic } from '../utils'
import ConditionalWrapper from './ConditionalWrapper'
import IconLabel from './IconLabel'
import OrcidIdLink from './OrcidIdLink'
import config from '../config'

import './ProfileTypes.scss'

export default function ProfileTypes(props) {
  // TODO Handle person descriptions correctly once we have type column from RIMS
  // if type.find('Graduate') --> horizontal prop on icon
  const person = props.person
  return (
    <ul className="list-inline profile-types">
      {props.showType && (
        <li className="list-inline-item profile-types-type">
          <IconLabel
            icon={
              isAcademic(person) ? config.icons.academic : config.icons.student
            }
            size={props.iconSize}
          >
            {isAcademic(person) ? 'Academic' : 'PhD Candidate'}
          </IconLabel>
        </li>
      )}
      {props.showRole && person.role && (
        <li className="list-inline-item profile-types-role">{person.role}</li>
      )}
      {props.showAdvisorStatus && person.advisor_level && (
        <li className="list-inline-item profile-types-advisor">
          <ConditionalWrapper
            condition={!!props.linked}
            as="a"
            className="link-implicit"
            href="#contact"
          >
            <IconLabel icon={config.icons.advisor} size={props.iconSize}>
              Research Advisor
            </IconLabel>
          </ConditionalWrapper>
        </li>
      )}
      {props.showOrcidId && person.orcidId && (
        <li className="list-inline-item profile-types-orcidid">
          <OrcidIdLink orcidId={person.orcidId} size={props.iconSize} />
        </li>
      )}
    </ul>
  )
}

ProfileTypes.defaultProps = {
  iconSize: '1em',
  linked: true,
  showType: true,
  showRole: true,
  showOrcidId: true,
  showAdvisorStatus: true,
}

ProfileTypes.propTypes = {
  iconSize: PropTypes.string,
  linked: PropTypes.bool,
  showType: PropTypes.bool,
  showRole: PropTypes.bool,
  showOrcidId: PropTypes.bool,
  showAdvisorStatus: PropTypes.bool,
}
