import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Modal, Form, Button } from 'react-bootstrap'
import { mdiEmail } from '@mdi/js'
import classnames from 'classnames'

import ProfileSectionHeading from './ProfileSectionHeading'
import ProfileMini from './ProfileMini'

import config from '../config'

export default function ProfileContactModal(props) {
  const [sent, setSent] = useState(false)
  const { person, className, ...rest } = props

  return (
    <Modal
      className={classnames('modal-contact', className)}
      {...rest}
      aria-labelledby="modal-contact"
    >
      <Modal.Header className="pb-0 border-0" closeButton>
        <Modal.Title className="flex-fill" id="modal-contact">
          <ProfileSectionHeading
            heading="Contact a Researcher"
            icon={mdiEmail}
            className="mb-0"
          />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="mb-2">
          <div className="h4">You’re sending a message to:</div>
          <ProfileMini person={person} linked={false} />
        </div>
        {!sent ? (
          <Form onSubmit={() => setSent(true)}>
            <Form.Group controlId="email">
              <Form.Label>Email address</Form.Label>
              <Form.Control type="email" placeholder="Enter email" required />
              <Form.Text className="text-muted">
                Your email will only be used to send this message to this
                researcher.
              </Form.Text>
            </Form.Group>

            <Form.Group controlId="message">
              <Form.Label>Message</Form.Label>
              <Form.Control as="textarea" rows="3" required />
            </Form.Group>

            <Button variant="primary" type="submit">
              Send
            </Button>
          </Form>
        ) : (
          <>
            <div className="h5">Your message was sent.</div>
            <p className="lead">
              If you need help with research or a project, you can also reach
              out to <a href={config.urls.jcuConnectContact}>JCU Connect</a> or
              the <a href={config.urls.grsContact}>Graduate Research School</a>.
            </p>
          </>
        )}
      </Modal.Body>
    </Modal>
  )
}

ProfileContactModal.propTypes = {
  person: PropTypes.object.isRequired,
}
