import React from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from 'react-bootstrap'
import classnames from 'classnames'

import Hero from './Hero'

import './Intro.scss'

export default function Intro(props) {
  const {
    className,
    heading,
    headingLarge,
    headingType,
    children,
    ...rest
  } = props
  const Heading = props.headingType
  return (
    <Hero
      className={classnames('hero-intro', className)}
      jumbotronClassName={className}
      {...rest}
    >
      <Row>
        <Col lg={10}>
          <Heading className="hero-intro-heading">
            {heading && headingLarge ? (
              <>
                <span className="d-lg-none">{heading}</span>
                <span className="d-none d-lg-inline">{headingLarge}</span>
              </>
            ) : (
              heading
            )}
          </Heading>
          <div className="lead mb-2">{children}</div>
        </Col>
      </Row>
    </Hero>
  )
}

Intro.defaultProps = {
  headingType: 'h1',
}

Intro.propTypes = {
  heading: PropTypes.any.isRequired,
  headingLarge: PropTypes.any,
  headingType: PropTypes.oneOfType([PropTypes.string, PropTypes.elementType])
    .isRequired,
  children: PropTypes.any,
}
