import React from 'react'
import PropTypes from 'prop-types'
import { Media } from 'react-bootstrap'
import classnames from 'classnames'

import { personName, personUrl, isLongPersonName } from '../utils'

import config from '../config'
import ConditionalWrapper from './ConditionalWrapper.js'
import ProfilePhoto from './ProfilePhoto.js'
import ProfileTypes from './ProfileTypes'

import './ProfileMini.scss'

function ProfileMini(props) {
  const person = props.person
  if (!person) {
    return null
  }

  const name = personName(person)
  const fullName = (
    <>
      {person.title && <div className="small">{person.title}</div>}
      <div
        className={classnames('profile-mini-name', {
          'profile-mini-name-long': isLongPersonName(name),
        })}
      >
        {personName(person)}
      </div>
    </>
  )

  return (
    <Media className={classnames('profile-mini', props.className)}>
      <ProfilePhoto
        person={person}
        width={config.photos.width / 4}
        height={config.photos.height / 4}
        className="profile-mini-photo mini-photo"
      />
      <Media.Body>
        <ConditionalWrapper
          condition={!!props.linked && personUrl(person)}
          as="a"
          className={classnames('profile-mini-identity', {
            'stretched-link': props.stretchedLink,
          })}
          href={props.href || personUrl(person)}
        >
          {fullName}
        </ConditionalWrapper>
        <ProfileTypes
          person={person}
          showType={props.showType}
          showOrcidId={false}
          showAdvisorStatus={props.showAdvisorStatus}
          linked={props.linked}
        />
      </Media.Body>
    </Media>
  )
}

ProfileMini.defaultProps = {
  linked: true,
  stretchedLink: false,
  showType: true,
  showAdvisorStatus: true,
}

ProfileMini.propTypes = {
  person: PropTypes.object.isRequired,
  linked: PropTypes.bool,
  stretchedLink: PropTypes.bool.isRequired,
  href: PropTypes.string,
  showType: PropTypes.bool,
  showAdvisorStatus: PropTypes.bool,
}

export default ProfileMini
