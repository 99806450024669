export default [
  {
    type: 'crowdfunding',
    title: 'Identifying Resilience',
    url:
      'https://james-cook-university.pozible.com/project/identifying-resilience',
    image:
      'https://cdn.pozible.com/projects/63517534-d9e7-4ed2-a60b-6daf86c2c6b3/hero-df7d0420-cabe-11e9-b01c-efa9a7fadfb8@300x300.jpg',
    researcher: {
      link_id: 'tasmin.rymer',
      is_student: false,
    },
    audience: ['Crowdfunding', 'Research Students', 'Volunteers'],
  },
  {
    type: 'crowdfunding',
    title: 'Resource Recovery Project: Converting plastic into biochar',
    url: 'https://research.jcu.edu.au/portfolio/mohan.jacob/',
    image:
      'https://cdn.pozible.com/projects/614d3106-d9ae-45a9-b260-23bc8733ac5c/hero-67f0cf80-da73-11e9-afb3-99795a426108@300x300.jpg',
    researcher: {
      link_id: 'mohan.jacob',
      is_student: false,
    },
    audience: [
      'Crowdfunding',
      'Industry Partners',
      'Research Students',
      'Volunteers',
    ],
  },
  {
    type: 'hdr',
    title: 'Plasma Enhanced Chemical Vapor Deposition',
    url: 'https://research.jcu.edu.au/portfolio/mohan.jacob/',
    image:
      'https://image.made-in-china.com/202f0j00sSWtkVNrgzuG/PE-CVD-Plasma-Enhanced-Chemical-Vapor-Deposition-Furnace.jpg',
    researcher: {
      link_id: 'mohan.jacob',
      is_student: false,
    },
    audience: ['Research Students'],
  },
]
