import React from 'react'
import PropTypes from 'prop-types'
import { Container, Row, Col, Modal } from 'react-bootstrap'
import { mdiMicrophoneVariant } from '@mdi/js'
import classnames from 'classnames'

import ProfileSectionHeading from './ProfileSectionHeading'
import PersonCards from './PersonCards'
import ContactCard from './ContactCard'
import ProfileMini from './ProfileMini'
import { arrayPopulated } from '../utils'

import config from '../config'

import './MediaEnquiriesModal.scss'

export default function MediaEnquiriesModal(props) {
  const { person, className, ...rest } = props

  return (
    <Modal
      className={classnames('modal-media-enquiries', className)}
      {...rest}
      aria-labelledby="modal-media-enquiries-title"
    >
      <Modal.Header className="pb-0 border-0" closeButton>
        <Modal.Title className="flex-fill" id="modal-media-enquiries-title">
          <ProfileSectionHeading
            heading="Want to inverview a JCU expert?"
            icon={mdiMicrophoneVariant}
            className="mb-0"
          />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Row xs={1} lg={2} className="media-enquiries-choices">
            <Col>
              <div className="h4 text-compress">
                JCU Media &amp; Communications
              </div>
              <p>
                JCU has a wide range of experts available for interview. We can
                help you track down an expert for your next story. Please
                contact us on the details below.
              </p>
              <div>
                <ContactCard.Email email="media@jcu.edu.au" />
                <ContactCard.Phone>
                  <a href={config.urls.mediaContact}>
                    Media Liaison phone numbers
                  </a>
                </ContactCard.Phone>
                <ContactCard.Url href={config.urls.media}>
                  JCU Media &amp; Communications
                </ContactCard.Url>
                <ContactCard.Location>
                  James Cook University (JCU)
                  <br />
                  Queensland, Australia
                </ContactCard.Location>
              </div>
            </Col>
            <Col>
              <div>
                <div className="h5">This researcher directly</div>
                <div className="position-relative mb-2">
                  <ProfileMini
                    person={person}
                    linked={false}
                    showAdvisorStatus={false}
                  />
                </div>
                <ContactCard.Email email={person.email} />
                <ContactCard.Phone phoneNumbers={person.telephone} />
                {person.campus && (
                  <ContactCard.Location>
                    JCU {person.campus} campus
                  </ContactCard.Location>
                )}
              </div>
              {arrayPopulated(person.advisors) && (
                <div>
                  <div className="h5">This researcher’s Advisors</div>
                  <PersonCards
                    people={person.advisors}
                    showType={false}
                    sm={null}
                    lg={null}
                  />
                </div>
              )}
            </Col>
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  )
}

MediaEnquiriesModal.propTypes = {
  person: PropTypes.object.isRequired,
}
