import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { useHash } from 'react-use'
import { Link } from 'react-router-dom'
import { mdiBank, mdiSchool } from '@mdi/js'

import {
  Collage,
  Feature,
  DisciplineCards,
  CallToAction,
  FeaturedCategories,
  ProfileFeatured,
} from './components'
import { getLocalResearcher, getRandomItems, parseHash } from './utils'

import researchers from './data/researchers'

import './Home.scss'
import ThreeMinuteThesisImg from './img/3mt.jpg'

export default function Home() {
  const sectionMargin = 'mb-3 mb-lg-4'

  const [hash] = useHash()
  const featuredPeople = researchers.filter(person => !!person.feature)
  const featureIndex = parseHash(hash).feature
  const featuredPerson = !featureIndex
    ? getRandomItems(featuredPeople, 1)[0]
    : featuredPeople[featureIndex]

  return (
    <>
      <Feature
        title="Meet JCU’s world-class researchers"
        searchPlaceholder="Find by research, topic, name…"
        backgroundImage={featuredPerson.feature.img}
        backgroundPosition={featuredPerson.feature.position}
        backgroundColor={featuredPerson.feature.backgroundColor}
        backgroundConstrain
        actionVerb="Explore"
        count={1970}
        countNoun="experts"
        identityText={
          <Feature.PersonIdentityText
            person={getLocalResearcher(featuredPerson.link_id)}
            byline={featuredPerson.feature.byline}
          />
        }
        identityPosition={featuredPerson.feature.identityPosition}
        aboutText={
          <>
            The JCU Research Portfolio dynamically profiles research, academics
            and postgraduate research students at James Cook University.{' '}
            <Link className="ml-2" to="/about">
              Learn&nbsp;more&hellip;
              <span className="sr-only"> about the JCU Research Portfolio</span>
            </Link>
          </>
        }
      />
      <Container fluid="xl" className={sectionMargin}>
        <Row className="d-md-none mb-2">
          <Col className="text-center">
            <h2 className="h5 text-uppercase">Featured Researchers</h2>
          </Col>
        </Row>

        <Row xs={1} lg={3} noGutters>
          {researchers.slice(0, 3).map(person => (
            <ProfileFeatured key={person.link_id} person={person} />
          ))}
        </Row>
      </Container>

      <Container fluid="lg" className={`collage-wrapper ${sectionMargin}`}>
        <Row className="justify-content-center">
          <Col lg={11}>
            <h2 className="h5 text-center text-uppercase">Latest updates</h2>
            <Collage people={researchers.slice(3)} />
          </Col>
        </Row>
      </Container>

      <Container fluid="lg" className={sectionMargin}>
        <DisciplineCards title="JCU researchers by expertise" />
      </Container>

      <FeaturedCategories
        title="JCU Academics"
        icon={mdiBank}
        categories={{
          '/portfolio/academy': 'By academic group',
          '/portfolio/centres': 'By Research Centre and Institute',
          '/find-an-advisor': 'Find an Advisor',
        }}
        breakpoint="lg"
      />
      <FeaturedCategories
        title="JCU Research Students"
        icon={mdiSchool}
        categories={{
          '/projects/': 'By research project',
          '/portfolio/': 'Find someone with specific skills',
        }}
        breakpoint="lg"
      />

      <CallToAction
        title="Start your research journey at JCU"
        action="Become a JCU researcher"
        actionLink="#"
        backgroundImage={ThreeMinuteThesisImg}
        backgroundPosition="center 20%"
      />
    </>
  )
}
