import React, { useRef, useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import {
  Row,
  Col,
  Button,
  ButtonGroup,
  ButtonToolbar,
  Dropdown,
  Form,
  Badge,
  CloseButton,
} from 'react-bootstrap'
import {
  useTable,
  useSortBy,
  usePagination,
  useGlobalFilter,
  useFilters,
  useAsyncDebounce,
} from 'react-table'
import Icon from '@mdi/react'
import {
  mdiChevronRight,
  mdiChevronLeft,
  mdiSort,
  mdiFilterMenu,
  mdiFilterMenuOutline,
  mdiCheck,
} from '@mdi/js'
import classnames from 'classnames'

import Spinner from './Spinner'
import { pluralise } from '../utils'

import './DataExplorer.scss'

export default function DataExplorer(props) {
  const [isLoading, setIsLoading] = useState(false)
  const [sortOrder, setSortOrder] = useState(props.sort[0].id)
  const sortOrderIndex = props.sort.findIndex(
    order => order.id === sortOrder || order.title === sortOrder
  )
  const [orderedData, setOrderedData] = useState(null)

  const defaultView = Object.entries(props.views).find(
    ([, view]) => view.default
  )
  const [viewId, setViewId] = useState(
    // Get the ID of the default view or just use the first view if none set
    defaultView ? defaultView[0] : Object.keys(props.views)[0]
  )
  const View = props.views[viewId].view

  const data = useMemo(() => orderedData || props.data, [
    orderedData,
    props.data,
  ])
  const columns = useMemo(() => props.columns, [props.columns])
  const {
    prepareRow,
    rows,
    preFilteredRows,
    state,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    previousPage,
    nextPage,
    gotoPage,
    setPageSize,
    toggleSortBy,
    setFilter,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      manualSortBy: !!orderedData,
      initialState: {
        pageSize: props.defaultPageSize,
        sortBy: useMemo(() => props.sort[0].columns, [props.sort]),
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  )

  const isFiltering = !!state.globalFilter || !!state.filters.length
  const filterMenuRef = useRef(null)
  const filterFieldRef = useRef(null)
  const [filterFieldValue, setFilterFieldValue] = useState(null)
  const onFilterChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined)
  }, 200)

  const items = useMemo(
    () => value => pluralise(value, props.type, props.typePlural),
    [props.type, props.typePlural]
  )

  return (
    <div
      className={classnames(
        'data-explorer',
        `data-explorer-view-${viewId} ${props.id}`,
        props.className
      )}
      id={props.id}
    >
      <Row className="justify-content-between mb-2 mb-md-3">
        <Col xs="auto" className="align-self-center">
          {isLoading ? (
            <Spinner />
          ) : (
            <div className="data-explorer-counts">
              {/* preFilteredRows equals rows when no filter is applied */}
              {rows.length} {isFiltering && `of ${preFilteredRows.length}`}{' '}
              {items(preFilteredRows.length)}
            </div>
          )}
        </Col>
        {isFiltering && (
          <Col className="d-none d-md-block flex-shrink-1">
            {!!state.globalFilter && (
              <Badge variant="primary" className="mr-1 mb-1 px-2">
                “{state.globalFilter}”{' '}
                <CloseButton
                  className="float-none ml-1 text-white align-middle"
                  title="Clear filter"
                  label="Remove"
                  onClick={() => {
                    setFilterFieldValue(null)
                    setGlobalFilter(null)
                  }}
                />
              </Badge>
            )}
            {state.filters.map(filter => (
              <Badge
                key={filter.id}
                variant="primary"
                className="mr-1 mb-1 px-2"
              >
                {props.filters.find(f => f.id === filter.id).title}{' '}
                <CloseButton
                  className="float-none ml-1 text-white align-middle"
                  title="Clear filter"
                  label="Remove"
                  onClick={() => {
                    setFilter(filter.id, null)
                  }}
                />
              </Badge>
            ))}
          </Col>
        )}
        <Col
          xs="auto"
          className="d-flex justify-content-end align-items-baseline"
        >
          <ButtonToolbar className="text-dark" aria-label="Data controls">
            <ButtonGroup className="mr-1 mr-sm-2 pr-sm-2 border-right">
              <Form inline>
                <Form.Label
                  className="mr-1 mr-sm-2 mb-0 small"
                  htmlFor={`${props.id}-pageSize`}
                >
                  Show
                </Form.Label>
                <Form.Control
                  as="select"
                  id={`${props.id}-pageSize`}
                  custom
                  className="w-auto"
                  defaultValue={props.defaultPageSize}
                  onChange={e => {
                    setPageSize(Number(e.target.value) || rows.length)
                  }}
                >
                  {props.pageSizes.map(size => (
                    <option key={size} value={size}>
                      {size}
                    </option>
                  ))}
                </Form.Control>
              </Form>
            </ButtonGroup>
            <ButtonGroup>
              {props.sort && props.sort.length > 1 && (
                <Dropdown className="mr-1" alignRight>
                  <Dropdown.Toggle
                    variant="outline-secondary"
                    className="dropdown-toggle-implicit border-input"
                    title="Sort by"
                  >
                    <Icon path={mdiSort} size="1.5em" />
                    <span className="d-none d-md-inline">
                      {' '}
                      {props.sort[sortOrderIndex].title}
                    </span>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {props.sort.map((order, index) => {
                      return (
                        <Dropdown.Item
                          key={order.id || order.title}
                          onClick={e => {
                            setSortOrder(order.id || order.title)

                            if (order.customAction) {
                              order.customAction({
                                setOrderedData,
                                setIsLoading,
                              })
                              setFilterFieldValue(null)
                            } else {
                              // Disable any manually ordered data
                              if (!!orderedData) {
                                setOrderedData(null)
                                setFilterFieldValue(null)
                              }

                              // First, set a new sort order by isMulti (third arg) set as false
                              toggleSortBy(
                                order.columns[0].id,
                                order.columns[0].desc || false,
                                false
                              )
                              // Then, call toggleSortBy multiple times with isMulti set as true
                              order.columns
                                .slice(1)
                                .forEach(c =>
                                  toggleSortBy(c.id, c.desc || false, true)
                                )
                            }
                          }}
                        >
                          {index === sortOrderIndex && (
                            <>
                              <Icon
                                className="text-primary"
                                path={mdiCheck}
                                size="1em"
                                title="Active"
                              />{' '}
                            </>
                          )}
                          {order.title}
                        </Dropdown.Item>
                      )
                    })}
                  </Dropdown.Menu>
                </Dropdown>
              )}
              <Dropdown
                alignRight
                ref={filterMenuRef}
                onToggle={isOpen =>
                  isOpen &&
                  setTimeout(
                    () =>
                      filterFieldRef.current && filterFieldRef.current.focus(),
                    0
                  )
                }
              >
                <Dropdown.Toggle
                  variant={
                    isFiltering ? 'outline-primary' : 'outline-secondary'
                  }
                  className="dropdown-toggle-implicit border-input"
                  title={`Filter ${props.typePlural}`}
                >
                  <Icon
                    path={isFiltering ? mdiFilterMenu : mdiFilterMenuOutline}
                    size="1.5em"
                  />
                </Dropdown.Toggle>
                <Dropdown.Menu className="p-2">
                  <input
                    ref={filterFieldRef}
                    className={classnames(
                      'data-explorer-filter',
                      !!filterFieldValue && 'data-explorer-filter-active'
                    )}
                    autoFocus
                    type="search"
                    placeholder="Enter search terms"
                    value={filterFieldValue || ''}
                    onChange={e => {
                      setFilterFieldValue(e.target.value)
                      onFilterChange(e.target.value)
                    }}
                    onBlur={
                      !props.filters.length
                        ? e => {
                            // Trigger action from react-overlays/useRootClose
                            filterMenuRef.current.dispatchEvent(
                              new KeyboardEvent('keyup', {
                                keyCode: 27,
                                bubbles: true,
                              })
                            )
                          }
                        : null
                    }
                  />
                  {!!props.filters.length && (
                    <div className="mt-2">
                      {props.filters.map(filter => (
                        <Form.Group
                          key={filter.id}
                          controlId={`${props.id}-${filter.id}`}
                        >
                          {filter.type === 'boolean' && (
                            <Form.Check
                              custom
                              inline
                              label={filter.title}
                              checked={
                                state.filters.findIndex(
                                  f => f.id === filter.id
                                ) !== -1
                              }
                              onChange={e =>
                                setFilter(filter.id, e.target.checked)
                              }
                            />
                          )}
                        </Form.Group>
                      ))}
                    </div>
                  )}
                </Dropdown.Menu>
              </Dropdown>
            </ButtonGroup>
            {Object.keys(props.views).length > 1 && (
              <ButtonGroup className="ml-1 ml-md-2 pl-1 border-left">
                {Object.entries(props.views).map(([key, view]) => (
                  <Button
                    key={key}
                    variant="link"
                    className={classnames(
                      'px-0 px-sm-1',
                      key === viewId ? 'text-dark' : 'text-secondary'
                    )}
                    onClick={() => setViewId(key)}
                  >
                    <Icon path={view.icon} size="1.5em" title={view.title} />
                  </Button>
                ))}
              </ButtonGroup>
            )}
          </ButtonToolbar>
        </Col>
      </Row>

      <View tableState={state}>
        {page.map(row => {
          prepareRow(row)
          return props.views[viewId].item({ row, tableState: state, sortOrder })
        })}
      </View>

      {pageCount > 1 && (
        <Row className="data-explorer-pagination justify-content-end">
          <Col xs="auto">
            <ButtonToolbar
              className="align-items-center text-dark"
              aria-label="Pagination controls"
            >
              <span className="mr-2">
                Page{' '}
                <Form.Control
                  as="select"
                  id={`${props.id}-page`}
                  custom
                  className="w-auto mx-1"
                  value={state.pageIndex}
                  onChange={e => {
                    const page = Number(e.target.value)
                    gotoPage(page)
                    // Scroll up on final page as it's almost certainly shorter
                    if (page === pageCount - 1) {
                      window.location.hash = `#${props.id}`
                    }
                  }}
                >
                  {Array(pageCount)
                    .fill(null)
                    .map((x, i) => (
                      <option key={i} value={i}>
                        {i + 1}
                      </option>
                    ))}
                </Form.Control>{' '}
                of {pageCount}
              </span>
              <ButtonGroup>
                <Button
                  variant="link"
                  className="px-1 text-dark"
                  title="Previous page"
                  onClick={() => {
                    window.location.hash = `#${props.id}`
                    previousPage()
                  }}
                  disabled={!canPreviousPage}
                >
                  <Icon path={mdiChevronLeft} size="1.5em" />
                </Button>
                <Button
                  variant="link"
                  className="px-1 text-dark"
                  title="Next page"
                  onClick={() => {
                    window.location.hash = `#${props.id}`
                    nextPage()
                  }}
                  disabled={!canNextPage}
                >
                  <Icon path={mdiChevronRight} size="1.5em" />
                </Button>
              </ButtonGroup>
            </ButtonToolbar>
          </Col>
        </Row>
      )}
    </div>
  )
}

DataExplorer.defaultProps = {
  type: 'items',
  pageSizes: [5, 10, 20, 'all'],
  defaultPageSize: 10,
  manualSortBy: false,
  sort: [],
  filters: [],
}

DataExplorer.propTypes = {
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  data: PropTypes.array.isRequired,
  manualSortBy: PropTypes.bool.isRequired,
  columns: PropTypes.array.isRequired,
  type: PropTypes.string.isRequired,
  pageSizes: PropTypes.array.isRequired,
  defaultPageSize: PropTypes.number.isRequired,
  sort: PropTypes.array.isRequired,
  filters: PropTypes.array.isRequired,
  // views[].item MUST be a callable (or component) that returns a component with a `key` prop set
  views: PropTypes.object.isRequired,
}
