import React from 'react'
import Icon from '@mdi/react'
import {
  mdiFileDocument,
  mdiFilePdf,
  mdiFileWord,
  mdiFilePowerpoint,
  mdiFileExcel,
} from '@mdi/js'
import AdobeAcrobatReader from 'simple-icons/icons/adobeacrobatreader'
import MicrosoftWord from 'simple-icons/icons/microsoftword'
import MicrosoftExcel from 'simple-icons/icons/microsoftexcel'
import MicrosoftPowerPoint from 'simple-icons/icons/microsoftpowerpoint'

export default function FileIcon(props) {
  const { filename, ...rest } = props
  const ext = filename
    .substr((~-filename.lastIndexOf('.') >>> 0) + 2)
    .toLowerCase()
  let icon, color, title

  switch (ext) {
    case 'pdf':
      icon = mdiFilePdf
      color = AdobeAcrobatReader.hex
      title = 'PDF file icon'
      break
    case 'doc':
    case 'docx':
      icon = mdiFileWord
      color = MicrosoftWord.hex
      title = 'Microsoft Word file icon'
      break
    case 'xls':
    case 'xlsx':
      icon = mdiFileExcel
      color = MicrosoftExcel.hex
      title = 'Microsoft Excel file icon'
      break
    case 'ppt':
    case 'pptx':
      icon = mdiFilePowerpoint
      color = MicrosoftPowerPoint.hex
      title = 'Microsoft PowerPoint file icon'
      break
    default:
      icon = mdiFileDocument
      title = 'File icon'
  }
  return <Icon path={icon} color={`#${color}`} title={title} {...rest} />
}
